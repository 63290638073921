export default {
    "next": "Next",
    "login": "Login",
    "login_tips": "We hope you could enjoy a romantic journey here",
    "set_password": "Set password",
    "enter_password": "Please enter your password",
    "verify_code": "Verification code",
    "enter_code": "Please enter 6 email digital verification code",
    "let_start": "Let's Get Started",
    "let_start_tips": "We hope you could enjoy a romantic journey here",
    "sign_facebook": "Sign in with FaceBook",
    "sign_google": "Sign in with Google",
    "sign_email": "Sign in with Email",
    "language_title": "Set reading language preferences",
    "current_lang": "current language",
    "en": "EN",
    "indonesian": "Indonesian",
    "personal_data": "Personal data",
    "save": "Save",
    "nickname": "Nickname",
    "sign_out": "Sign Out",
    "loading": "loading",
    "success": "Successful",
    "reading_record": "Reading record",
    "empty": "Empty",
    "last_read": "Last read",
    "chapter": "chapter",
    "remove": "Remove",
    "search": "Search",
    "search_empty": "No “{{var}}” related novels can be found",
    "login_tips_2": "Log in access more features.",
    "register_or_login": "Register/Login",
    "more": "More",
    "reads": "Reads",
    "read": "Read",
    "last_chapter": "Last Chapter",
    "all_chapter": "All Chapters",
    "contents": "Contents",
    "all": "All",
    "completed": "Completed",
    "ongoing": "Ongoing",
    "most_popular": "Most Popular",
    "new_arrival": "New Arrival",
    "invite_friends": "Share this invitation code with your friends. Once they activate it on this page, you both get {{coins}} gems. Each invitation code can be used up to 50 times.",
    "invite_friends_nums": "<span class=\"blue\" >I invited {{n}} friends and made</span> <span class=\"items-center coin\"><img\n  src=\"/images/dialyTask/coin.png\" alt=\"\"> * {{m}}</span>",
    "guide_title": "StoryGo Author Project",
    "guide_content": "Welcome, authors. If you want to join StoryGo and showcase your beautiful writing and engaging stories on our platform, please send us the following information via email. We will contact you after reviewing your application and authorize you to upload your work.\nHow to address you:\nPreferred pen-name:\nPreferred language:\nDo you have previous writing experience on other platforms?\nPrevious platform:\nLinks to your previously published works (if any):\nWhich genres do you specialize in?\nWhat will be the frequency and word count of your story releases on StoryGo?\nYour preferred contact email:\nPlease send the above information to storygo2023@gmail.com. We will reach out to you after the review process is completed.\n\n",
    "active_code": "Activate the invite code",
    "active_tips": "Get an invitation code from your friends, and upon successful activation, you will receive {{coins}} gems. Tip: You can't activate your own invitation code; you must obtain one from others!  ",
    "login_now": "Login now",
    "task_1_title": "Welcome Gift",
    "task_1_desc": "A welcome gift for newcomers, which has automatically deposited into your wallet",
    "view_detail": "View details",
    "claim": "Claim",
    "invite_friend": "Invite Friends",
    "my_invite_code": "My invite-code",
    "copy": "Copy",
    "task_2_title": "Every day Check-in",
    "task_2_desc": "Check in every day, and get the bonus.",
    "task_3_title": "Read for over {{min}} minutes",
    "task_3_desc": "Read for a total of {{min}} minutes today to earn rewards.",
    "task_page_label": "Rewards",
    "label_wallet_purchase_coins": "Purchase coins",
    "go": "Go",
    "done": "Done",
    "copy_text": "My StoryGo invite code is [ {{code}} ],activate it and earn 50 Coins:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "The invite-code has been copied.",
    "active_success": "Activation successful. The 50 coin reward has been sent to your wallet.",
    "error_code_limit": "The activation code has reached its usage limit.",
    "error_had_activated": "This account has already been activated with a code",
    "error_invalid_code": "Invalid activation code",
    "download_tips": "Boundless Stories in APP",
    "download": "Download",
    "label_book_praise": "Rating",
    "label_book_prologue": "Prologue",
    "login_success": "Login succeeded",
    "msg_reset_password_repeat": "Please repeat the new password",
    "msg_reset_password_email": "Please enter your email address to reset your password",
    "label_reset_password": "Reset password",
    "label_send_email": "Send email",
    "msg_login_email_code_sent": "The verification code has been successfully sent to your email",
    "msg_feedback_error": "Send failed, please try again later",
    "save_userinfo": "Successfully saved",
    "label_load_image_fail": "Image loading failed",
    "paid_chapters": "Paid Chapters",
    "unlock_price": "Unlock chapter requires {{coin}}",
    "download_claim_coin": "Download the app to claim {{coin}} and unlock more exciting chapters.",
    "label_book_chapters": "{{num}}  Chapters",
    "label_cancel": "Cancel",
    "msg_common_state_empty": "Empty here",
    "clear_reading_record": "Are you sure you want to clear the reading record?",
    "clear_successful": "Record cleared successfully.",
    "label_search_trending": "Trending searches",
    "task_login_tips": "Login to get Coins Bonus",
    "label_new_arrival": "Hot New Releases",
    "had_activated": "This account has already been activated with a code",
    "activate": "Activate",
    "task_5_title": "Share to Facebook",
    "task_5_desc": "Share any book to Facebook platform",
    "bigwheel_redeem_label": "recharge",
    "coin_tips_1": "Insufficient coins, please recharge.",
    "coin_tips_2": "Account: {{n}} coins (needs {{m}} coins)",
    "bigwheel_rule_1": "1.Participate in the Lucky Wheel draw with a guaranteed 100% chance of winning prizes.",
    "bigwheel_rule_2": "2.A single wheel draw costs 10 gold coins, a ten-draw costs 100 gold coins, and a fifty-draw costs 500 gold coins. The more consecutive draws, the higher the chance of winning valuable prizes!",
    "bigwheel_rule_3": "3.Prizes obtained during the game are automatically distributed. Gold coins are directly credited to your gold coin wallet.",
    "bigwheel_rule_4": "4.This game is not affiliated with Apple Inc. All rights to the final interpretation belong to StoryGo.",
    "bigwheel_balance_label": "Balance",
    "no_data_1": "No data",
    "msg_room_coin_notice": "Congrats! [{{who}}] win {{coin}} coins!",
    "top_up": "Top-up",
    "sign": "Sign up",
    "sign_desc": "A welcome gift for newcomers, which has automatically deposited into your wallet",
    "first_recharge": "Limited time offer",
    "first_recharge_desc": "Finish Limited time offer to get bonus",
    "unlock_chapter": "Unlock {{n}} chapter",
    "unlock_chapter_desc": "Unlock a premium chapter to receive a reward.",
    "comment_book": "Send a comment of a book",
    "comment_book_desc": "Write a review for your favorite book and stand a chance to win rewards!",
    "my_coins": "My Coins",
    "recharge_title": "Recommended gift pack",
    "bonus": "Bonus",
    "buy_coins": "Buy Coins",
    "my_balance": "My balance",
    "apple_pay": "Apple Pay",
    "other_pay": "Top Choice",
    "google_pay": "Google Pay",
    "pay_now": "Pay Now",
    "label_balance": "coins",
    "select_payment": "Please select a payment method",
    "select_package": "Please select a recharge package",
    "wallet_title": "Wallet",
    "coins_balance": "Balance",
    "new_comer": "New Comer",
    "extra_coins": "Extra {{n}} coins",
    "daily_task_label": "Daily Task",
    "label_pay_success": "Payment successful!",
    "app_update_title": "Please update to the latest version",
    "restore_label": "Restore",
    "history_label": "History",
    "newcomer_pack": "Limited time offer",
    "recommend_pack": "Recommended package",
    "collection_month": "Total/Month",
    "now_get_icons": "Get {{n}} at once",
    "monthly_get_icons": "Get {{n}} Gems/Month",
    "recharge_feedback_msg": "Having trouble recharging?",
    "feedback_label": "Feedback",
    "coin_label": "Coins",
    "gem_label": "Gems",
    "restore_tips": "No restorable subscriptions",
    "recharge_label": "Top up",
    "sign_days": "Checked-in {{n}} day(s)",
    "vip_daily_task": "Month-package bonus",
    "other": "Other",
    "limited_recharge_pack": "Limited time offer",
    "expire_tips": "Expire on {{time}}",
    "hot_pack": "Popular package",
    "continuous_checkin_day": "Checked-in {{n}} day(s)",
    "svip_login_reward": "$9.9 premium bonus",
    "vip_login_reward": "$19.9 premium bonus",
    "dailysign_popup_btn": "Get more from Rewards Center",
    "checked_in": "Checked",
    "limit_time_recharge_title": "Limited time offer",
    "recharge_pack_tips": "Finish Limited time offer to get bonus",
    "balance_nsufficient_tips": "Your balance is insufficient,please recharge",
    "had_coins_tips": "You have: {{n}} Coins | {{m}} Gems",
    "dailysign_popup_title": "Congrats",
    "pay_method_label": "Payment Method",
    "at_once": "At once",
    "daily_login": "Daily login",
    "card_coin_suffix": "Total/Month",
    "card_gems_suffix": "Month",
    "vips_rule_title": "About Month package",
    "vips_rule_1": "1.Successfully purchase $9.9-month-package, you will immediately receive 1,000 gold coins, and you can receive 20 gems every day for 30 consecutive days (600 gems in total); Successfully purchase $19.9-month-package, you will immediately receive 2,000 gold coins, and you can receive 40 gems every day (600 gems in total) for 30 consecutive days.",
    "vips_rule_2": "2.If you buy different month packages, the gem rewards you get every day will be superimposed; if you buy same month packages, the gem rewards you get every day will not be superimposed, but will only extend the duration of the corresponding month card.",
    "vips_rule_3": "3.The gems obtained daily are valid for 30 days, please pay attention.",
    "vips_rule_4": "",
    "vips_rule_5": "1. Month package will be valid within 24 hours of purchase and you can enjoy the corresponding benefits.",
    "check_in": "Check in",
    "day_index": "No.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Gems </span> are about to expire",
    "balance_label": "Balance",
    "wallet_rule_title": "About Gems",
    "wallet_rule_1": "1.Gems can be used to unlock chapters. When unlocking chapters, coins and gems have the same value, 1 coin =1 gem.",
    "wallet_rule_2": "2. Gems will expire. Please note that gems received from top-up and month-package are valid for 30 days and gems claimed by daily tasks are valid for 7 days.",
    "wallet_rule_3": "3.When you unlock chapters, gems are used first.",
    "read_task_title": "Reading task",
    "share_task_title": "Sharing tasks",
    "comment_task_title": "Comment task",
    "vip_task_title": "$9.99 premium bonus",
    "svip_task_title": "$19.99 premium bonus",
    "vip_page_title": "Month package",
    "pay_bonus_label": "Bonus",
    "daily_gems": "Daily {{n}} Gems",
    "history_page_title": "History",
    "recharge_title_1": "Top up",
    "gems_will_expire_btn": "Details",
    "gems_history_tips": "Sort by expiration date",
    "unlock_coins": "Need: {{n}} Coins or Gems",
    "history_recharge_title": "Recharge",
    "history_game_title": "Lucky Draw",
    "history_recharge_given_title": "Recharge bonus",
    "extar_gems_btn": "Extra Gems",
    "watch_ad_task_title": "Watch videos",
    "watch_ad_task_desc": "Receive reward for each video watched ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Reward successfully claimed.",
    "task_finish_title": "The task has been completed.",
    "draw_width_ad_toast": "Congratulations!You've got {{n}} and {{m}} additional.",
    "draw_toast": "Congratulations!You've got {{n}}.",
    "draw_dialog_btn1": "Just claim {{n}}",
    "draw_dialog_btn2": "Claim {{n}} and {{m}} additional.",
    "history_ads_given_title": "Watch videos",
    "ad_extra_rewards_title": "Bonus for post-task ads.",
    "label_editor_picks": "Editor‘s Picks",
    "label_genre": "Genre",
    "label_main_tab_home": "Discover",
    "label_reader_choice": "Top-Rated",
    "invite_bonuses": "Invite a new user and get up to {{n}} bonuses",
    "invite_friend_btn": "Invite your friend",
    "how_get_bonuses": "How to get more bonuses",
    "invite_tips_1": "Your friend has unlocked and read 5 chapters",
    "invite_tips_2": "When friends recharge, you get 10% back in gems, up to 150 gems per friend.",
    "invite_tips_3": "Every time your friend completes an action, you'll receive an immediate reward.",
    "how_invite_friend": "How to invite friend",
    "invite_tips_5": "Click the button below to generate your unique invitation link and share it with friends.",
    "invite_tips_4": "Your friend downloads StoryGo via the link you shared.",
    "reward_history_title": "Reward history",
    "reward_history_tips_1": "You have totally got {{n}} gems fromthe invitation activity.",
    "reward_history_tips_2": "You have successfully invited {{n}} friends and {{m}} gems.",
    "reward_history_tips_3": "You have earned {{n}} gems from the task of your invited friends unlocking 5 chapters",
    "reward_history_tips_4": "You have received {{n}} gems as a reward from your friends' recharge",
    "daily_invite_friend_title": "Invite friends",
    "daily_invite_friend_desc": "Invite your friends to join StoryGo and earn big rewards.",
    "recharge_panel_ad_tips": "Recharge your StoryGo package for abundant book coins and gems. Easily unlock premium chapters and enjoy ad-free reading for 7 to 30 days upon purchase, with instant activation.",
    "invite_more_friend": "Invite more friends and get more Gems!",
    "history_invite_unlock_title": "Invited user unlocked 5 chapter",
    "history_invite_recharge_title": "The invited user made a recharge",
    "days": "Days",
    "continue_read_app": "Continue Reading in the App",
    "download_app_tips": "For more exciting content, please download StoryGo",
    "invite_tips_6": "Invite a new reader to claim 15 gems. You can earn up to 300 gems as a reward.",
    "history_invite_success_title": "Successfully invited a user",
    "evaluation_task_title": "Rate StoryGo",
    "evaluation_task_desc": "3 Steps to get the rewards",
    "invite_page_title": "Invite new users to use it together and receive gem rewards!",
    "invite_page_title_2": "For every new user you invite, you will get",
    "invite_page_title_3": "For new user payments, you can also receive {{n}} gem reward. The more you invite, the more you earn.",
    "my_invite_record": "My invitation record",
    "successfully_invited": "Successfully invited",
    "earnings_gem": "My earnings (Gems)",
    "how_invite_title_1": "Invite friends",
    "how_invite_title_2": "Friend completes registration",
    "how_invite_title_3": "Rewards arrive",
    "invite_h5_title": "Invite you to use StoryGo together",
    "got_gem_label": "Newbie benefits",
    "accept_invitation": "Accept invitation",
    "join_people": "People have participated in the event",
    "recommended_content": "Recommended content",
    "to_app_tip": "More exciting content on StoryGo",
    "turn_on_notify_title": "Open notification",
    "turn_on_notify_desc": "Open message notifications to receive rewards",
    "label_help_center": "Help Center"
}