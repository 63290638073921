export default {
    "next": "Próximo",
    "login": "Login",
    "login_tips": "Esperamos que você possa desfrutar de uma jornada romântica aqui",
    "set_password": "Definir senha",
    "enter_password": "Por favor, insira sua senha",
    "verify_code": "Código de verificação",
    "enter_code": "Por favor, insira o código de verificação digital de 6 dígitos do email",
    "let_start": "Vamos começar",
    "let_start_tips": "Esperamos que você possa desfrutar de uma jornada romântica aqui",
    "sign_facebook": "Entrar com o Facebook",
    "sign_google": "Entrar com o Google",
    "sign_email": "Entrar com o Email",
    "language_title": "Definir preferências de idioma de leitura",
    "current_lang": "idioma atual",
    "en": "EN",
    "indonesian": "Indonésio",
    "personal_data": "Dados pessoais",
    "save": "Salvar",
    "nickname": "Apelido",
    "sign_out": "Sair",
    "loading": "carregando",
    "success": "Bem sucedido",
    "reading_record": "Registro de leitura",
    "empty": "Vazio",
    "last_read": "Última leitura",
    "chapter": "capítulo",
    "remove": "Remover",
    "search": "Pesquisa",
    "search_empty": "Nenhum romance relacionado a \"{{var}}\" pode ser encontrado",
    "login_tips_2": "Faça login para acessar mais recursos.",
    "register_or_login": "Registrar/Login",
    "more": "Mais",
    "reads": "Leituras",
    "read": "Ler",
    "last_chapter": "Último capítulo",
    "all_chapter": "Todos os Capítulos",
    "contents": "Conteúdos",
    "all": "Todos",
    "completed": "Concluído",
    "ongoing": "Em andamento",
    "most_popular": "Mais popular",
    "new_arrival": "Novidade",
    "invite_friends": "Compartilhe este código de convite com seus amigos. Uma vez que o ativem nesta página, ambos receberão {{coins}} gemas. Cada código de convite pode ser usado até 50 vezes.",
    "invite_friends_nums": "<span class=\"blue\">Convidei {{n}} amigos e ganhei </span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>\n",
    "guide_title": "Projeto Autor StoryGo",
    "guide_content": 0,
    "active_code": "Ative o código de convite",
    "active_tips": "Obtenha um código de convite de seus amigos e, após a ativação bem-sucedida, você receberá {{coins}} gemas. Dica: Você não pode ativar seu próprio código de convite; você deve obter um de outras pessoas!",
    "login_now": "Faça login agora",
    "task_1_title": "Presente de boas-vindas",
    "task_1_desc": "Um presente de boas-vindas para os recém-chegados, que foi automaticamente depositado em sua carteira",
    "view_detail": "Ver detalhes",
    "claim": "Reivindicar",
    "invite_friend": "Convidar amigos",
    "my_invite_code": "Meu código de convite",
    "copy": "Copiar",
    "task_2_title": "Verificação diária",
    "task_2_desc": "Faça o check-in todos os dias e ganhe o bônus.",
    "task_3_title": "Leia por mais de {{min}} minutos",
    "task_3_desc": "Leia por um total de {{min}} minutos hoje para ganhar recompensas.",
    "task_page_label": "Recompensas",
    "label_wallet_purchase_coins": "Comprar moedas",
    "go": "Ir",
    "done": "Feito",
    "copy_text": "Meu código de convite StoryGo é [ {{code}} ], ative-o e ganhe 50 Moedas:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "O código de convite foi copiado.",
    "active_success": "Ativação bem-sucedida. A recompensa de 50 moedas foi enviada para a sua carteira.",
    "error_code_limit": "O código de ativação atingiu seu limite de uso.",
    "error_had_activated": "Esta conta já foi ativada com um código",
    "error_invalid_code": "Código de ativação inválido",
    "download_tips": "Histórias Ilimitadas no APP",
    "download": "Baixar",
    "label_book_praise": "Avaliação",
    "label_book_prologue": "Prólogo",
    "login_success": "Login bem-sucedido",
    "msg_reset_password_repeat": "Por favor, repita a nova senha",
    "msg_reset_password_email": "Por favor, insira seu endereço de e-mail para redefinir sua senha",
    "label_reset_password": "Redefinir senha",
    "label_send_email": "Enviar e-mail",
    "msg_login_email_code_sent": "O código de verificação foi enviado com sucesso para o seu e-mail",
    "msg_feedback_error": "Falha no envio, por favor tente novamente mais tarde",
    "save_userinfo": "Salvo com sucesso",
    "label_load_image_fail": "Falha ao carregar imagem",
    "paid_chapters": "Capítulos Pagos",
    "unlock_price": "Desbloquear capítulo requer {{coin}}",
    "download_claim_coin": "Baixe o aplicativo para reivindicar {{coin}} e desbloquear capítulos mais emocionantes.",
    "label_book_chapters": "{{num}}  Capítulos",
    "label_cancel": "Cancelar",
    "msg_common_state_empty": "Vazio aqui",
    "clear_reading_record": "Tem certeza de que deseja limpar o registro de leitura?",
    "clear_successful": "Registro apagado com sucesso.",
    "label_search_trending": "Pesquisas em alta",
    "task_login_tips": "Faça login para obter um bônus de moedas",
    "label_new_arrival": "Lançamentos Quentes",
    "had_activated": "Esta conta já foi ativada com um código",
    "activate": "Ativar",
    "task_5_title": "Compartilhar no Facebook",
    "task_5_desc": "Compartilhar qualquer livro na plataforma do Facebook",
    "bigwheel_redeem_label": "Recarregar",
    "coin_tips_1": "Moedas insuficientes, por favor recarregar.",
    "coin_tips_2": "Conta: {{n}} moedas (necessita de {{m}} moedas)",
    "bigwheel_rule_1": "1. Participe do sorteio da Roda da Sorte com uma garantia de 100% de chance de ganhar prêmios.",
    "bigwheel_rule_2": "2. Um único giro da roda custa 10 moedas de ouro, dez giros custam 100 moedas de ouro, e cinquenta giros custam 500 moedas de ouro. Quanto mais giros consecutivos, maior a chance de ganhar prêmios valiosos!",
    "bigwheel_rule_3": "3. Os prêmios obtidos durante o jogo são distribuídos automaticamente. As moedas de ouro são creditadas diretamente na sua carteira de moedas de ouro.",
    "bigwheel_rule_4": "4. Este jogo não está afiliado à Apple Inc. Todos os direitos de interpretação final pertencem à StoryGo.",
    "bigwheel_balance_label": "Saldo",
    "no_data_1": "Sem dados",
    "msg_room_coin_notice": "Parabéns! [{{who}}] ganha {{coin}} moedas!",
    "top_up": "Recarregar",
    "sign": "Inscrever-se",
    "sign_desc": "Um presente de boas-vindas para os recém-chegados, que foi automaticamente depositado em sua carteira",
    "first_recharge": "Oferta por tempo limitado",
    "first_recharge_desc": "Termine a Oferta por tempo limitado para obter um bônus",
    "unlock_chapter": "Desbloquear {{n}} capítulo",
    "unlock_chapter_desc": "Desbloqueie um capítulo premium para receber uma recompensa.",
    "comment_book": "Enviar um comentário de um livro",
    "comment_book_desc": "Escreva uma crítica para o seu livro favorito e tenha a chance de ganhar recompensas!",
    "my_coins": "Minhas moedas",
    "recharge_title": "Pacote de presente recomendado",
    "bonus": "Bônus",
    "buy_coins": "Comprar moedas",
    "my_balance": "Meu saldo",
    "apple_pay": "Apple Pay",
    "other_pay": "Opção Principal",
    "google_pay": "Google Pay",
    "pay_now": "Pagar agora",
    "label_balance": "Moedas",
    "select_payment": "Por favor, selecione um método de pagamento",
    "select_package": "Por favor, selecione um pacote de recarga",
    "wallet_title": "Carteira",
    "coins_balance": "Saldo",
    "new_comer": "Novato",
    "extra_coins": "Extra {{n}} moedas",
    "daily_task_label": "Tarefa Diária",
    "label_pay_success": "Pagamento bem-sucedido!",
    "app_update_title": "Por favor, atualize para a versão mais recente.",
    "restore_label": "Restaurar",
    "history_label": "Histórico",
    "newcomer_pack": "Oferta por tempo limitado",
    "recommend_pack": "Pacote recomendado",
    "collection_month": "Total/Mês",
    "now_get_icons": "Obter {{n}}  de uma vez",
    "monthly_get_icons": "Obter {{n}} Gemas/Mês",
    "recharge_feedback_msg": "Está com dificuldades para recarregar?",
    "feedback_label": "Comentários",
    "coin_label": "Moedas",
    "gem_label": "Gemas",
    "restore_tips": "Sem assinaturas restauráveis",
    "recharge_label": "Recarregar",
    "sign_days": "Check-in {{n}} dia(s)",
    "vip_daily_task": "Bônus do pacote mensal",
    "other": "Outro",
    "limited_recharge_pack": "Oferta por tempo limitado",
    "expire_tips": "Expira em {{time}}",
    "hot_pack": "Pacote popular",
    "continuous_checkin_day": "Check-in {{n}} dia(s)",
    "svip_login_reward": "Bônus premium de $9.9",
    "vip_login_reward": "Bônus premium de $19.9",
    "dailysign_popup_btn": "Obtenha mais do Centro de Recompensas",
    "checked_in": "Verificado",
    "limit_time_recharge_title": "Oferta por tempo limitado",
    "recharge_pack_tips": "Termine a Oferta por tempo limitado para obter um bônus",
    "balance_nsufficient_tips": "Seu saldo é insuficiente, por favor recarregue",
    "had_coins_tips": "Você tem: {{n}} Moedas | {{m}} Gemas",
    "dailysign_popup_title": "Parabéns",
    "pay_method_label": "Método de Pagamento",
    "at_once": "De uma vez",
    "daily_login": "Login diário",
    "card_coin_suffix": "Total/Mês",
    "card_gems_suffix": "Mês",
    "vips_rule_title": "Sobre o pacote mensal:",
    "vips_rule_1": "1. Ao comprar com sucesso o pacote mensal de $9.9, você receberá imediatamente 1.000 moedas de ouro, e poderá receber 20 gemas por dia durante 30 dias consecutivos (600 gemas no total); Ao comprar com sucesso o pacote mensal de $19.9, você receberá imediatamente 2.000 moedas de ouro, e poderá receber 40 gemas por dia (600 gemas no total) durante 30 dias consecutivos.",
    "vips_rule_2": "2. Se você comprar pacotes mensais diferentes, as recompensas de gemas que você receberá todos os dias serão sobrepostas; se você comprar os mesmos pacotes mensais, as recompensas de gemas que você receberá todos os dias não serão sobrepostas, mas apenas estenderão a duração do cartão mensal correspondente.",
    "vips_rule_3": "3. As gemas obtidas diariamente são válidas por 30 dias, por favor, preste atenção.",
    "vips_rule_4": "",
    "vips_rule_5": "1. O pacote mensal será válido dentro de 24 horas após a compra e você poderá desfrutar dos benefícios correspondentes.",
    "check_in": "Check-in",
    "day_index": "No.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Gemas </span> estão prestes a expirar",
    "balance_label": "Saldo",
    "wallet_rule_title": "Sobre as Gemas",
    "wallet_rule_1": "1. As gemas podem ser usadas para desbloquear capítulos. Ao desbloquear capítulos, moedas e gemas têm o mesmo valor, 1 moeda = 1 gema.",
    "wallet_rule_2": "2. As gemas expirarão. Por favor, note que as gemas recebidas por recarga e pacote mensal são válidas por 30 dias e as gemas reivindicadas por tarefas diárias são válidas por 7 dias.",
    "wallet_rule_3": "3. Ao desbloquear capítulos, as gemas são usadas primeiro.",
    "read_task_title": "Tarefa de leitura",
    "share_task_title": "Tarefas de compartilhamento",
    "comment_task_title": "Tarefa de comentário",
    "vip_task_title": "Bônus premium de $9.99",
    "svip_task_title": "Bônus premium de $19.99",
    "vip_page_title": "Pacote mensal",
    "pay_bonus_label": "Bônus",
    "daily_gems": "Diariamente {{n}} Gemas",
    "history_page_title": "Histórico",
    "recharge_title_1": "Recarregar",
    "gems_will_expire_btn": "Detalhes",
    "gems_history_tips": "Ordenar por data de validade",
    "unlock_coins": "Precisa de: {{n}} Moedas ou Gemas",
    "history_recharge_title": "Recarregar",
    "history_game_title": "Sorteio da Sorte",
    "history_recharge_given_title": "Bônus de recarga",
    "extar_gems_btn": "Gemas Extras",
    "watch_ad_task_title": "Assistir a vídeos",
    "watch_ad_task_desc": "Receber recompensa por cada vídeo assistido ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Recompensa reclamada com sucesso.",
    "task_finish_title": "A tarefa foi concluída.",
    "draw_width_ad_toast": "Parabéns! Você recebeu  {{n}} e  {{m}} adicionais.",
    "draw_toast": "Parabéns! Você obteve  {{n}}.",
    "draw_dialog_btn1": "Apenas reivindique  {{n}}.",
    "draw_dialog_btn2": "Reivindique  {{n}} e  {{m}} adicionais.",
    "history_ads_given_title": "Assistir a vídeos",
    "ad_extra_rewards_title": "Bônus após completar tarefas.",
    "label_editor_picks": "Escolhas do Editor",
    "label_genre": "Gênero",
    "label_main_tab_home": "Descobrir",
    "label_reader_choice": "Melhor Avaliado",
    "invite_bonuses": "Convide um novo usuário e ganhe até {{n}} bônus.",
    "invite_friend_btn": "Convide seu amigo",
    "how_get_bonuses": "Como obter mais bônus",
    "invite_tips_1": "Seu amigo desbloqueou e leu 5 capítulos",
    "invite_tips_2": "Quando seus amigos recarregam, você recebe 10% de volta em gemas, até 150 gemas por amigo.",
    "invite_tips_3": "Sempre que seu amigo completar uma ação, você receberá uma recompensa imediata.",
    "how_invite_friend": "Como convidar um amigo",
    "invite_tips_5": "Clique no botão abaixo para gerar seu link de convite exclusivo e compartilhá-lo com amigos",
    "invite_tips_4": "Seu amigo baixou o StoryGo através do link que você compartilhou",
    "reward_history_title": "Histórico de recompensas",
    "reward_history_tips_1": "Você obteve um total de {{n}} gemas da atividade de convite.",
    "reward_history_tips_2": "Você convidou com sucesso {{n}} amigos e {{m}} gemas.",
    "reward_history_tips_3": "Você ganhou {{n}} gemas da tarefa de desbloqueio de 5 capítulos realizada pelos seus amigos convidados.",
    "reward_history_tips_4": "Você recebeu {{n}} gemas como recompensa do recarregamento de seus amigos",
    "daily_invite_friend_title": "Convidar amigos",
    "daily_invite_friend_desc": "Convide seus amigos para se juntarem ao StoryGo e ganhe grandes recompensas.",
    "recharge_panel_ad_tips": "Recarregue o seu pacote StoryGo para obter moedas de livro e gemas abundantes. Desbloqueie facilmente capítulos premium e aproveite a leitura sem anúncios por 7 a 30 dias após a compra, com ativação instantânea.",
    "invite_more_friend": "Convide mais amigos e ganhe mais Gemas!",
    "history_invite_unlock_title": "O usuário convidado desbloqueou 5 capítulos",
    "history_invite_recharge_title": "O usuário convidado fez uma recarga",
    "days": "Dias",
    "continue_read_app": "Continuar a Ler na Aplicação",
    "download_app_tips": "Para mais conteúdo emocionante, por favor, baixe o StoryGo",
    "invite_tips_6": "Convide um novo leitor para reivindicar 15 gemas. Você pode ganhar até 300 gemas como recompensa.",
    "history_invite_success_title": "Usuário convidado com sucesso",
    "evaluation_task_title": "Avalie o StoryGo",
    "evaluation_task_desc": "3 Passos para obter as recompensas",
    "invite_page_title": "Convide novos usuários para usar juntos e receber recompensas de gemas!",
    "invite_page_title_2": "Para cada novo usuário que você convidar, você receberá.",
    "invite_page_title_3": "Para os pagamentos de novos usuários, você também pode receber uma recompensa em gemas de {{n}}. Quanto mais convidar, mais ganha.",
    "my_invite_record": "Meu Registro de Convites",
    "successfully_invited": "Convite Bem-sucedido",
    "earnings_gem": "Meus Ganhos (Gemas)",
    "how_invite_title_1": "Convidar Amigos",
    "how_invite_title_2": "O amigo completa o registro",
    "how_invite_title_3": "As recompensas chegam",
    "invite_h5_title": "Convidamos você a usar o StoryGo juntos",
    "got_gem_label": "Benefícios para novatos",
    "accept_invitation": "Aceitar o convite",
    "join_people": "As pessoas participaram do evento",
    "recommended_content": "Conteúdo recomendado",
    "to_app_tip": "Mais conteúdo emocionante no StoryGo",
    "turn_on_notify_title": "Abrir notificação",
    "turn_on_notify_desc": "Abra as notificações de mensagem para receber recompensas",
    "label_help_center": "Centro de Ajuda"
}