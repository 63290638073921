export default {
    "next": "Tiếp theo",
    "login": "Đăng nhập",
    "login_tips": "Chúng tôi hy vọng bạn có thể tận hưởng một hành trình lãng mạn ở đây",
    "set_password": "Đặt mật khẩu",
    "enter_password": "Vui lòng nhập mật khẩu của bạn",
    "verify_code": "Mã xác minh",
    "enter_code": "Vui lòng nhập mã xác minh số gồm 6 chữ số từ email",
    "let_start": "Bắt đầu nào",
    "let_start_tips": "Chúng tôi hy vọng bạn có thể tận hưởng một hành trình lãng mạn ở đây",
    "sign_facebook": "Đăng nhập với FaceBook",
    "sign_google": "Đăng nhập với Google",
    "sign_email": "Đăng nhập với Email",
    "language_title": "Đặt ưu tiên ngôn ngữ đọc",
    "current_lang": "Ngôn ngữ hiện tại",
    "en": "EN",
    "indonesian": "Indonesian",
    "personal_data": "Dữ liệu cá nhân",
    "save": "Lưu",
    "nickname": "Biệt danh",
    "sign_out": "Đăng xuất",
    "loading": "Đang tải",
    "success": "Thành công",
    "reading_record": "Hồ sơ đọc",
    "empty": "Trống",
    "last_read": "Đọc lần cuối",
    "chapter": "Chương",
    "remove": "Xóa",
    "search": "Tìm kiếm",
    "search_empty": "Không tìm thấy tiểu thuyết liên quan đến \"{{var}}\"",
    "login_tips_2": "Đăng nhập để truy cập thêm tính năng.",
    "register_or_login": "Đăng ký/Đăng nhập",
    "more": "Thêm",
    "reads": "Đọc",
    "read": "Đọc",
    "last_chapter": "Chương cuối",
    "all_chapter": "Tất cả chương",
    "contents": "Nội dung",
    "all": "Tất cả",
    "completed": "Hoàn thành",
    "ongoing": "Đang tiếp diễn",
    "most_popular": "Phổ biến nhất",
    "new_arrival": "Mới về",
    "invite_friends": "Chia sẻ mã mời này với bạn bè của bạn. Khi họ kích hoạt nó trên trang này, bạn và họ đều nhận được {{coins}} viên ngọc. Mỗi mã mời có thể được sử dụng tối đa 50 lần.",
    "invite_friends_nums": "<span class=\"blue\">Tôi đã mời {{n}} bạn và kiếm được</span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\">  *  {{m}}</span>",
    "guide_title": "Dự án Tác giả StoryGo",
    "guide_content": 0,
    "active_code": "Kích hoạt mã mời",
    "active_tips": "Nhận mã mời từ bạn bè của bạn, và sau khi kích hoạt thành công, bạn sẽ nhận được {{coins}} viên ngọc. Mẹo: Bạn không thể kích hoạt mã mời của mình; bạn phải nhận một mã từ người khác!",
    "login_now": "Đăng nhập ngay",
    "task_1_title": "Quà tặng chào mừng",
    "task_1_desc": "Quà tặng chào mừng cho người mới, đã tự động được gửi vào ví của bạn",
    "view_detail": "Xem chi tiết",
    "claim": "Yêu cầu",
    "invite_friend": "Mời bạn bè",
    "my_invite_code": "Mã mời của tôi",
    "copy": "Sao chép",
    "task_2_title": "Tiêu đề công việc 2: Đăng nhập mỗi ngày",
    "task_2_desc": "Mô tả công việc 2: \"Đăng nhập mỗi ngày và nhận phần thưởng.\"",
    "task_3_title": "Tiêu đề công việc 3: Đọc hơn {{min}} phút",
    "task_3_desc": "Mô tả công việc 3: Đọc tổng cộng {{min}} phút hôm nay để nhận phần thưởng.",
    "task_page_label": "Nhãn trang công việc: Phần thưởng",
    "label_wallet_purchase_coins": "Mua tiền xu",
    "go": "Đi",
    "done": "Hoàn thành",
    "copy_text": "Sao chép văn bản: \"Mã mời StoryGo của tôi là [ {{code}} ], kích hoạt nó và kiếm 50 đồng:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "Sao chép thành công: Mã mời đã được sao chép.",
    "active_success": "Kích hoạt thành công. Phần thưởng 50 đồng đã được gửi đến ví của bạn.",
    "error_code_limit": "Lỗi giới hạn mã kích hoạt, mã kích hoạt đã đạt đến giới hạn sử dụng.",
    "error_had_activated": "Lỗi đã kích hoạt, tài khoản này đã được kích hoạt bằng một mã",
    "error_invalid_code": "Lỗi mã kích hoạt không hợp lệ",
    "download_tips": "Mẹo tải xuống, Các câu chuyện vô tận trong APP",
    "download": "Tải xuống",
    "label_book_praise": "Đánh giá",
    "label_book_prologue": "Lời mở đầu",
    "login_success": "Đăng nhập thành công",
    "msg_reset_password_repeat": "Vui lòng nhập lại mật khẩu mới",
    "msg_reset_password_email": "Vui lòng nhập địa chỉ email của bạn để đặt lại mật khẩu",
    "label_reset_password": "Đặt lại mật khẩu",
    "label_send_email": "Gửi email",
    "msg_login_email_code_sent": "Mã xác minh đã được gửi thành công đến email của bạn",
    "msg_feedback_error": "Gửi thất bại, vui lòng thử lại sau",
    "save_userinfo": "Lưu thành công",
    "label_load_image_fail": "Tải ảnh thất bại",
    "paid_chapters": "Chương Trả Phí",
    "unlock_price": "Mở chương cần {{coin}}",
    "download_claim_coin": "Tải ứng dụng để nhận {{coin}} và mở khóa thêm nhiều chương hấp dẫn.",
    "label_book_chapters": "Chương{{num}} ",
    "label_cancel": "Hủy",
    "msg_common_state_empty": "Trống rỗng ở đây",
    "clear_reading_record": "Bạn chắc chắn muốn xóa bản ghi đọc không?",
    "clear_successful": "Ghi chép đã được xóa thành công.",
    "label_search_trending": "Tìm kiếm xu hướng",
    "task_login_tips": "Đăng nhập để nhận tiền thưởng xu",
    "label_new_arrival": "Sản Phẩm Mới Hấp Dẫn",
    "had_activated": "Lỗi đã kích hoạt, tài khoản này đã được kích hoạt bằng một mã",
    "activate": "Kích hoạt",
    "task_5_title": "Chia sẻ lên Facebook",
    "task_5_desc": "Chia sẻ bất kỳ sách nào lên nền tảng Facebook",
    "bigwheel_redeem_label": "Nạp tiền",
    "coin_tips_1": "Khoảng không đủ, vui lòng nạp tiền.",
    "coin_tips_2": "Tài khoản: {{n}} xu (cần {{m}} xu)",
    "bigwheel_rule_1": "1. Tham gia quay Xổ số với đảm bảo 100% có cơ hội nhận giải.",
    "bigwheel_rule_2": "2. Một lượt quay có giá 10 xu và mười lượt có giá 100 xu, năm mươi lượt có giá 500 xu. Càng nhiều lượt quay liên tiếp, càng cao khả năng nhận giải thưởng giá trị!",
    "bigwheel_rule_3": "3. Giải thưởng nhận được trong trò chơi sẽ tự động phân phối. Xu vàng được ghi trực tiếp vào ví xu vàng của bạn.",
    "bigwheel_rule_4": "4. Trò chơi này không liên quan đến Apple Inc. Mọi quyền lợi cho việc giải thích cuối cùng thuộc về StoryGo.",
    "bigwheel_balance_label": "Số dư",
    "no_data_1": "Không có dữ liệu",
    "msg_room_coin_notice": "Chúc mừng! [{{who}}] chiến thắng {{coin}} xu!",
    "top_up": "Nạp tiền",
    "sign": "Đăng ký",
    "sign_desc": "Quà tặng chào mừng cho người mới, đã tự động được gửi vào ví của bạn",
    "first_recharge": "Ưu đãi thời gian có hạn",
    "first_recharge_desc": "Hoàn thành Ưu đãi thời gian có hạn để nhận thưởng",
    "unlock_chapter": "Mở khóa {{n}} chương",
    "unlock_chapter_desc": "Mở khóa một chương cao cấp để nhận phần thưởng.",
    "comment_book": "Gửi bình luận về một cuốn sách",
    "comment_book_desc": "Viết đánh giá cho cuốn sách yêu thích của bạn và có cơ hội nhận được phần thưởng!",
    "my_coins": "Xu của tôi",
    "recharge_title": "Bộ quà tặng được đề xuất",
    "bonus": "Thưởng",
    "buy_coins": "Mua Xu",
    "my_balance": "Số dư của tôi",
    "apple_pay": "Apple Pay",
    "other_pay": "Lựa Chọn Tốt",
    "google_pay": "Google Pay",
    "pay_now": "Thanh toán Ngay",
    "label_balance": "tiền xu",
    "select_payment": "Vui lòng chọn phương thức thanh toán",
    "select_package": "Vui lòng chọn gói nạp tiền",
    "wallet_title": "Ví",
    "coins_balance": "Số dư",
    "new_comer": "Người mới",
    "extra_coins": "Extra {{n}} xu",
    "daily_task_label": "Nhiệm vụ Hàng Ngày",
    "label_pay_success": "Thanh toán thành công!",
    "app_update_title": "Vui lòng cập nhật lên phiên bản mới nhất.",
    "restore_label": "Khôi phục",
    "history_label": "Lịch sử",
    "newcomer_pack": "Ưu đãi thời gian có hạn",
    "recommend_pack": "Gói được đề xuất",
    "collection_month": "Tổng/Cuối tháng",
    "now_get_icons": "Nhận {{n}}  ngay lập tức",
    "monthly_get_icons": "Nhận {{n}} Ngọc/Bước",
    "recharge_feedback_msg": "Mengalami kesulitan dalam mengisi ulang?",
    "feedback_label": "Umpan balik",
    "coin_label": "Koin",
    "gem_label": "Permata",
    "restore_tips": "Tidak ada langganan yang dapat dipulihkan",
    "recharge_label": "Top up",
    "sign_days": "Check-in {{n}} hari",
    "vip_daily_task": "Bonus paket bulanan",
    "other": "Khác",
    "limited_recharge_pack": "Ưu đãi thời gian có hạn",
    "expire_tips": "Hết hạn vào {{time}}",
    "hot_pack": "Gói phổ biến",
    "continuous_checkin_day": "Check-in {{n}} hari",
    "svip_login_reward": "Tiền thưởng cao cấp $9.9",
    "vip_login_reward": "Tiền thưởng cao cấp $19.9",
    "dailysign_popup_btn": "Nhận nhiều hơn từ Trung tâm Phần thưởng",
    "checked_in": "Kiểm tra",
    "limit_time_recharge_title": "Ưu đãi thời gian có hạn",
    "recharge_pack_tips": "Hoàn thành Ưu đãi thời gian có hạn để nhận thưởng",
    "balance_nsufficient_tips": "Saldo Anda tidak mencukupi, silakan isi ulang",
    "had_coins_tips": "Anda memiliki: {{n}} Koin | {{m}} Permata",
    "dailysign_popup_title": "Xin chúc mừng",
    "pay_method_label": "Phương thức thanh toán",
    "at_once": "Sekali",
    "daily_login": "Đăng nhập hàng ngày",
    "card_coin_suffix": "Tổng/Cuối tháng",
    "card_gems_suffix": "Bulan",
    "vips_rule_title": "Về gói hàng tháng:",
    "vips_rule_1": "1. Mua thành công gói tháng $9.9, bạn sẽ ngay lập tức nhận được 1.000 xu và bạn có thể nhận được 20 viên kim cương mỗi ngày trong 30 ngày liên tiếp (tổng cộng 600 viên kim cương); Mua thành công gói tháng $19.9, bạn sẽ ngay lập tức nhận được 2.000 xu và bạn có thể nhận được 40 viên kim cương mỗi ngày (tổng cộng 600 viên kim cương) trong 30 ngày liên tiếp.",
    "vips_rule_2": "2. Nếu bạn mua các gói tháng khác nhau, phần thưởng kim cương bạn nhận mỗi ngày sẽ được chồng lên nhau; nếu bạn mua các gói tháng giống nhau, phần thưởng kim cương bạn nhận mỗi ngày sẽ không được chồng lên nhau, nhưng chỉ kéo dài thời gian của thẻ tháng tương ứng.",
    "vips_rule_3": "3. Các viên kim cương nhận được hàng ngày có hiệu lực trong 30 ngày, vui lòng chú ý.",
    "vips_rule_4": "",
    "vips_rule_5": "1. Gói tháng sẽ có hiệu lực trong vòng 24 giờ kể từ khi mua và bạn có thể tận hưởng các lợi ích tương ứng.",
    "check_in": "Đăng ký",
    "day_index": "Số.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} viên kim cương </span> sắp hết hạn",
    "balance_label": "Số dư",
    "wallet_rule_title": "Về Viên kim cương",
    "wallet_rule_1": "1. Viên kim cương có thể được sử dụng để mở khóa các chương. Khi mở khóa các chương, xu và viên kim cương có cùng giá trị, 1 xu = 1 viên kim cương.",
    "wallet_rule_2": "2. Viên kim cương sẽ hết hạn. Xin lưu ý rằng các viên kim cương nhận được từ nạp tiền và gói tháng có hiệu lực trong 30 ngày và viên kim cương được yêu cầu từ các nhiệm vụ hàng ngày có hiệu lực trong 7 ngày.",
    "wallet_rule_3": "3. Khi bạn mở khóa các chương, viên kim cương sẽ được sử dụng trước.",
    "read_task_title": "Nhiệm vụ đọc",
    "share_task_title": "Nhiệm vụ chia sẻ",
    "comment_task_title": "Nhiệm vụ nhận xét",
    "vip_task_title": "Tiền thưởng cao cấp $9.99",
    "svip_task_title": "Tiền thưởng cao cấp $19.99",
    "vip_page_title": "Gói tháng",
    "pay_bonus_label": "Thưởng",
    "daily_gems": "Hàng ngày {{n}} viên kim cương",
    "history_page_title": "Lịch sử",
    "recharge_title_1": "Nạp tiền",
    "gems_will_expire_btn": "Chi tiết",
    "gems_history_tips": "Sắp xếp theo ngày hết hạn",
    "unlock_coins": "Cần: {{n}} Xu hoặc Ngọc",
    "history_recharge_title": "Nạp tiền",
    "history_game_title": "Rút Thăm May Mắn",
    "history_recharge_given_title": "Thưởng Nạp Tiền",
    "extar_gems_btn": "Ngọc Bổ Sung",
    "watch_ad_task_title": "Xem video",
    "watch_ad_task_desc": "Nhận phần thưởng cho mỗi video được xem ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Phần thưởng được yêu cầu thành công.",
    "task_finish_title": "Nhiệm vụ đã hoàn thành.",
    "draw_width_ad_toast": "Chúc mừng! Bạn đã nhận được  {{n}} và thêm  {{m}}.",
    "draw_toast": "Chúc mừng! Bạn đã nhận được  {{n}}.",
    "draw_dialog_btn1": "Chỉ yêu cầu  {{n}}.",
    "draw_dialog_btn2": "Yêu cầu  {{n}} và  {{m}} bổ sung.",
    "history_ads_given_title": "Xem video",
    "ad_extra_rewards_title": "Thưởng sau khi hoàn thành nhiệm vụ.",
    "label_editor_picks": "Lựa chọn của Biên tập viên",
    "label_genre": "Thể loại",
    "label_main_tab_home": "Khám phá",
    "label_reader_choice": "Được Đánh Giá Cao",
    "invite_bonuses": "Mời người dùng mới và nhận đến {{n}} phần thưởng.",
    "invite_friend_btn": "Mời bạn của bạn",
    "how_get_bonuses": "Cách nhận được nhiều phần thưởng hơn",
    "invite_tips_1": "Bạn của bạn đã mở khóa và đọc 5 chương",
    "invite_tips_2": " Khi bạn bè nạp tiền, bạn nhận được 10% trở lại dưới dạng ngọc, lên đến 150 viên mỗi bạn.",
    "invite_tips_3": "Mỗi khi bạn hoàn thành một hành động, bạn sẽ nhận được phần thưởng ngay lập tức.",
    "how_invite_friend": "Cách mời bạn bè",
    "invite_tips_5": "Nhấp vào nút bên dưới để tạo liên kết mời độc quyền của bạn và chia sẻ với bạn bè",
    "invite_tips_4": "Bạn tải StoryGo qua liên kết bạn chia sẻ",
    "reward_history_title": "Lịch sử phần thưởng",
    "reward_history_tips_1": "Bạn đã nhận được tổng cộng {{n}} viên ngọc từ hoạt động mời.",
    "reward_history_tips_2": "Bạn đã mời thành công {{n}} bạn và {{m}} viên ngọc.",
    "reward_history_tips_3": "Bạn đã kiếm được {{n}} viên ngọc từ nhiệm vụ mà bạn bè mời của bạn mở khóa 5 chương.",
    "reward_history_tips_4": "Bạn đã nhận được {{n}} viên ngọc như một phần thưởng từ việc nạp tiền của bạn bè",
    "daily_invite_friend_title": "Mời bạn bè",
    "daily_invite_friend_desc": "Mời bạn bè của bạn tham gia StoryGo và nhận phần thưởng lớn.",
    "recharge_panel_ad_tips": "Nạp gói StoryGo của bạn để nhận được nhiều xu sách và ngọc quý. Dễ dàng mở khóa các chương cao cấp và tận hưởng việc đọc không có quảng cáo trong 7 đến 30 ngày sau khi mua, với kích hoạt ngay lập tức.",
    "invite_more_friend": "Mời thêm bạn bè và nhận thêm Ngọc!",
    "history_invite_unlock_title": "Người dùng mời đã mở khóa 5 chương",
    "history_invite_recharge_title": "Người dùng mời đã thực hiện nạp tiền",
    "days": "Ngày",
    "continue_read_app": "Tiếp tục Đọc trong Ứng dụng",
    "download_app_tips": "Để có thêm nội dung hấp dẫn, vui lòng tải xuống StoryGo",
    "invite_tips_6": "Mời một độc giả mới để nhận 15 viên ngọc. Bạn có thể kiếm được tối đa 300 viên ngọc như là phần thưởng.",
    "history_invite_success_title": "Mời người dùng thành công",
    "evaluation_task_title": "Đánh giá StoryGo",
    "evaluation_task_desc": "3 Bước để nhận phần thưởng",
    "invite_page_title": "Mời người dùng mới sử dụng cùng nhau và nhận phần thưởng viên ngọc!",
    "invite_page_title_2": "Đối với mỗi người dùng mới bạn mời, bạn sẽ nhận được.",
    "invite_page_title_3": "Đối với việc thanh toán của người dùng mới, bạn cũng có thể nhận được phần thưởng {{n}} viên ngọc. Bạn mời nhiều hơn, bạn nhận được nhiều hơn.",
    "my_invite_record": "Lịch Sử Mời Mọc Của Tôi",
    "successfully_invited": "Mời thành công",
    "earnings_gem": "Thu Nhập Của Tôi (Ngọc)",
    "how_invite_title_1": "Mời Bạn Bè",
    "how_invite_title_2": "Bạn hoàn thành đăng ký",
    "how_invite_title_3": "Phần thưởng đến",
    "invite_h5_title": "Mời bạn sử dụng StoryGo cùng nhau",
    "got_gem_label": "Lợi ích cho người mới",
    "accept_invitation": "Chấp nhận lời mời",
    "join_people": "Mọi người đã tham gia sự kiện",
    "recommended_content": "Nội dung được đề xuất",
    "to_app_tip": "Nhiều nội dung hấp dẫn hơn trên StoryGo",
    "turn_on_notify_title": "Mở thông báo",
    "turn_on_notify_desc": "Mở thông báo tin nhắn để nhận phần thưởng",
    "label_help_center": "Trung tâm Trợ giúp"
}