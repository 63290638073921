// ***********************!!!micang version!!! modified by <wangpeng>***********************************************
// Parsed By TarsParser(2.4.14), Generated By tools(20200627)
// TarsParser Maintained By <TARS> and tools Maintained By <superzheng>
// Generated from "novel_base.tars" by Structure Mode
// **********************************************************************


import Taf from '../taf/index';

var novel = novel || {};
novel.RspCode = {
    kMRC_OK: 0,
    kMRC_FAILED: 1,
    kMRC_PARAM_ERR: 2,
    kMRC_RETRY_LATER: 3,
    kMRC_TOKEN_ILLEGAL: 4,
    kMRC_SIGN_ILLEGAL: 5,
    kMRC_PRIVACY_ILLEGAL: 6,
    KMRC_DUPLICATE_KEY_ERR: 7,
    kMRC_DECRYPT_ILLEGAL: 8,
    kMRC_IDCARD_NEED: 9,
    kMRC_CONTENT_NOT_AVAILABLE: 10,
    kMRC_OPERATION_DENY: 11,
    kMRC_CONTENT_BLOCK: 12,
    kMRC_PHONE_UNSUPPORT: 1000,
    kMRC_SMS_ERR_TOO_FREQ: 1001,
    kMRC_SMS_ERR: 1002,
    kMRC_LOGIN_NOT_ALLOWED: 1003,
    kMRC_NEED_PHONE_BINDING: 1004,
    kMRC_ACCOUNT_RELEASED: 1005,
    kMRC_HAS_BINDED_SNS: 1006,
    kMRC_HAS_BE_BINDED: 1007,
    kMRC_ACCOUNT_OR_PASSWORD_ERR: 1010,
    kMRC_INVITE_CODE_ILLEGAL: 1011,
    kMRC_INVITE_CODE_LIMIT: 1012,
    kMRC_INVITE_CODE_USED: 1013,
    kMRC_MAX_ACCOUNT_WITH_ONE_DEVICE: 1014,
    kMRC_CHAPTER_LOCKED: 2001,
    kMRC_CHAPTER_UNPUBLISHED: 2002,
    kMRC_COIN_NOT_ENOUGH: 9000,
    kMRC_ORDER_EXISTS: 9001,
    kMRC_COMMENT_EXIST: 7010,
    kMRC_STOCK_SHORTAGE: 8000,
    kMRC_ORDER_ACCEPT: 8001,
    kMRC_ORDER_FAIL: 8002,
    kMRC_GOODS_UNAVAILABLE: 8003,
    kMRC_SKU_ORDERED: 8004,
    kMRC_SPU_MODIFY_UNSUPPORT: 8110,
    kMRC_BAG_UNAVAILABLE: 8200,
    kMRC_ORDER_LIMIT: 8201,
    kMRC_GOODS_OWNED: 8202,
    kMRC_REWARD_ALREADY_DREW: 4000,
    kMRC_TASK_NOT_FINISH: 4001,
    kMRC_TASK_NOT_COMPENSATE: 4002,
    kMRC_TASK_EXPIRED: 4003,
    kMRC_NOT_BETTING_STAGE: 300100,
    kMRC_GAME_DISABLE: 300101
};

novel.ESnsType = {
    QQ: 0,
    WECHAT: 1,
    WEIBO: 2,
    PHONE: 3,
    APPLE: 4,
    QQWEB: 5,
    WECHATWEB: 6,
    GOOGLE: 7,
    FACEBOOK: 8,
    IDCARD: 9,
    EMAIL: 10,
    DEVICEID: 11
};

novel.ETokenType = {
    APP: 0,
    WEB: 1
};

novel.EGender = {
    FEMALE: 0,
    MALE: 1,
    UNKNOWN: -1
};

novel.EAccountType = {
    NORMAL: 0,
    OFFICIAL: 1
};

novel.EAccountState = {
    NORMAL: 0,
    FORBIDDEN: 1,
    FORBIDDEN4EVER: 2,
    RELEASED: 3
};

novel.EContentType = {
    IMAGE_JPEG: 0,
    IMAGE_PNG: 1,
    FILE_ZIP: 2,
    JSON: 3,
    BINARY: 4,
    TTF: 5,
    SVG: 6,
    IMAGE_WEBP: 7,
    AUDIO_MP3: 8,
    WEBP: 9,
    IMAGE_GIF: 10,
    VIDEO_MP4: 11
};

novel.ECommentType = {
    BOOK: 1,
    PARAGRAPH: 2,
    STORY: 3,
    STORY_CHAPTER: 4
};

novel.ELikeType = {
    BOOK: 1,
    BOOK_COMMENT: 2,
    PARAGRAPH_COMMENT: 3,
    STORY: 4,
    STORY_CHAPTER: 5
};

novel.ESortType = {
    LATEST: 0,
    HOTEST: 1,
    EAREST: 2,
    HIGHEST: 3
};

novel.EBookSortType = {
    LATEST_UPDATE: 0,
    HIGHEST: 1,
    NEW_PUBLISH: 2,
    MOST_POPULAR: 3,
    QUALITY_SCORE: 4,
    RANDOM_0_READS: 5,
    LISTENS: 6,
    NEW_QUALITY: 7,
    NEW_QUALITY_REVERSE: 8
};

novel.ESubType = {
    USER: 1,
    BOOK: 2
};

novel.EFeedbackState = {
    PROCESSING: 1,
    PROCESSED: 2,
    NOT_PROCESSED: 3,
    REPLIED: 4
};

novel.EFeedbackType = {
    APP: 0,
    BOOK_CONTENT: 1,
    COMMENT: 2,
    USER: 3,
    PAY: 4,
    APP_AUTO: 99
};

novel.ETagType = {
    USER_TAG: 1
};

novel.EZRoleMaterialType = {
    MATERIAL: 1,
    RECIPE: 2
};

novel.EInteractiveType = {
    CLICK: 0,
    SHARE: 1,
    MAKE: 2
};

novel.EUserStateBit = {
};

novel.ECoinType = {
    DIAMOND: 1,
    CREDIT: 2,
    GEM: 9,
    AIP: 10
};

novel.EPayChannel = {
    SYSTEM: 0,
    WECHAT: 1,
    ALIPAY: 2,
    QQ: 3,
    APPLE_IAP: 4,
    GOOGLE_PAY: 5,
    PAYERMAX: 6
};

novel.EBillStatus = {
    SUCCESS: 0,
    FAILED: 1,
    WAITING_PAY: 2,
    USER_CANCEL: 3,
    TIMEOUT_CANCEL: 4
};

novel.EBizChannel = {
    TASK: 1,
    TASK_AD_BONUS: 1001,
    DRAW: 2,
    MALL: 3,
    SYSTEM: 4,
    AI: 5,
    BETTING_GAME: 8,
    WECHAT: 100,
    QQ: 101,
    ALIPAY: 102,
    APPLE: 103,
    GOOGLE: 104,
    PAYERMAX: 105,
    BAG_GIFT: 200,
    TASK_COMPENSATION: 300,
    OPER_GIVE: 400
};

novel.EForbiddenType = {
    FORBIDDEN_LOGIN: 1,
    FORBIDDEN_ROOM_LIVE: 2,
    FORBIDDEN_ROOM_WORDS: 3,
    FORBIDDEN_WORDS_BY_ROOM_HOST: 4,
    FORBIDDEN_IM: 5
};

novel.EBannerShowAt = {
    INDEX_PAGE: 1,
    LIBRARY: 2,
    SPLASH: 3,
    TASK_INDEX: 4,
    CATEGORY_TAB: 5,
    RANK: 6,
    TOPIC: 7,
    LIBRARY2: 8
};

novel.ELanguage = {
    PTH: 1,
    ENG: 2,
    YUEYU: 3
};

novel.EEndType = {
    UNKNOWN: 0,
    IOS: 1,
    ANDROID: 2
};

novel.EPrivacyType = {
    TAKE_PHOTO: 0
};

novel.EPrivacyState = {
    OPEN: 0,
    UNVISIABLE: 1,
    FRIENDS_ONLY: 2
};

novel.EBanType = {
    BLACK_LIST: 0,
    BLOCK: 1
};

novel.ETopicState = {
    ON: 1,
    OFF: 0
};

novel.ETopicCategoryState = {
    ON: 1,
    OFF: 0
};

novel.EBookState = {
    ON: 1,
    OFF: 0
};

novel.EBookCompleteState = {
    COMPLETED: 1,
    UPDATING: 2
};

novel.EMemberType = {
    REGULAR: 1,
    MONTH: 2,
    SEASON: 3,
    YEAR: 4
};

novel.EUserTaskState = {
    ENABLE: 1,
    DISABLE: 2
};

novel.EUserTaskPeriodType = {
    ONCE: 0,
    CONTINUOUS_DAYS: 1,
    DAY: 2,
    WEEK: 3,
    MONTH: 4
};

novel.EUserTaskDrawState = {
    TASK_NOT_FINISH: 0,
    DREW: 1,
    FINISH_UNDRAW: 2,
    STEP_UNDRAW: 3,
    STEP_DREW: 4
};

novel.EUserTaskType = {
    LOGIN: 1,
    CONTINUOUS_LOGIN_7DAYS: 2,
    READING: 3,
    ACTIVE_INVITE_CODE: 4,
    INVITE_CODE_BE_ACTIVATED: 5,
    RECHARGE: 6,
    CONSUME: 7,
    SHARE: 8,
    COMMENT: 9,
    VIP_LOGIN: 10,
    SVIP_LOGIN: 11,
    WATCH_AD: 12,
    INVITE_USER_BUY_CHAPTER: 13,
    INVITE_USER_RECHARGE: 14,
    INVITE_USER_SUCCESS: 15,
    TURN_ON_PUSH: 16,
    AI_CREATOR_LOGIN: 17
};

novel.EOfficialMessageType = {
    OPERATOR: 1,
    LOGIN_TASK_FINISH: 2,
    INVITE_CODE_ACTIVATED: 3,
    INVITE_USER_REMIND: 4
};

novel.EBookCategoryType = {
    CATEGORY: 0,
    TAG: 1
};

novel.ERechargePackageType = {
    COIN: 0,
    REG_3DAYS_GIFT: 1,
    VIP: 2,
    AIP: 3
};

novel.EVipType = {
    VIP: 0,
    SVIP: 1
};

novel.EVoiceType = {
    FEMALE: 0,
    MALE: 1
};

novel.EBookProgressType = {
    TEXT: 0,
    AUDIO: 1
};

novel.EHonorTagType = {
    TOP_READ_10: 1,
    TOP_READ_50: 2,
    HIGH_SCORE_10: 3,
    HIGH_SCORE_50: 4
};

novel.ECommentState = {
    OK: 0,
    DELETED: 1
};

novel.ECommentContent = {
    TXT: 1,
    IMG: 2
};

novel.EBannerContentType = {
    TEXT: 0,
    BOOK: 1
};

novel.RechargeProductType = {
    DIAMOND: 0,
    MEMBER: 1
};

novel.ERedirectType = {
    REDIRECT: 0,
    NOT_REDIRECT: 1
};

novel.EMessageState = {
    DISABLE: 0,
    ENABLE: 1
};

novel.EMessageType = {
    OFFICIAL: 1,
    PERSNOAL: 2,
    IM: 3
};

novel.EPersonalMessageType = {
    COMMENT: 1,
    LIKE: 2
};

novel.ECommentMessageSubType = {
    BOOK: 100,
    PARAGRAPH: 101
};

novel.ELikeMessageSubType = {
    BOOK_COMMENT: 200,
    PARAGRAPH_COMMENT: 201
};

novel.EGoodsCategory = {
    VIP: 1,
    BOOK_CHAPTER: 2,
    DIAMOND: 3,
    CREDIT: 4,
    GEM: 5,
    NO_AD: 6,
    AIP: 7
};

novel.EGoodsSkuState = {
    INIT: 0,
    ON: 1,
    OFF: 2
};

novel.EOrderState = {
    INIT: 0,
    PAY_FAIL: 11,
    PAY_EXCEPTION: 12,
    DELIVER_FAIL: 21,
    DELIVER_EXCEPTION: 22,
    COMPLETE: 1,
    SHUTDOWN: 2
};

novel.EGoodsSpuState = {
    ENABLE: 1,
    DISABLE: 0
};

novel.EAccountsType = {
    IN: 0,
    CASHOUT: 1
};

novel.EOrderType = {
    CHAPTER: 1,
    DIAMOND: 2,
    GEM: 3,
    VIP: 4,
    NO_AD: 5,
    AIP: 6
};

novel.ECashoutState = {
    APPLY: 1,
    PROCESSING: 2,
    COMPLETE: 3,
    SHUTDOWN: 4,
    NONE: 0,
    DISABLE: 10
};

novel.ECashoutFlowType = {
    SUBMIT_APPLY: 1,
    EDIT_APPLY: 2,
    CASHOUT_SUCCESS: 3,
    APPLY_EXPIRED: 4
};

novel.ECashoutWay = {
    ALIPAY: 1,
    WECHAT: 2
};

novel.EBagOrderState = {
    SUCCESS: 1,
    FAIL: 2,
    EXCEPTION: 3,
    SHUTDOWN: 4
};

novel.ESkuGetWay = {
    PURCHASE: 0,
    SYS_GIVE: 1
};

novel.ESkuPeriod = {
    DAY: 1,
    WEEK: 2
};

novel.ESkuShelf = {
    FREE_ROOM_SCENE: 1,
    FREE_IM_SCENE: 2,
    ROOM_GIFT: 3
};

novel.EBookInteractiveDataType = {
    READ: 1,
    LISTEN: 2
};

novel.EBookSpecialSubjectType = {
    BOOK: 1,
    BOOK_CATEGORY: 2
};

novel.ESpecialSubjectStyle = {
    SINGLE_LINE: 0,
    MULTI_LINE: 1,
    ANIMATED: 2,
    DOUBLE_LINES: 3
};

novel.EBooksRankType = {
    MOST_READ: 1,
    HIGH_SCORE: 2,
    NEW_BOOK: 3,
    COMPLETED: 4,
    UPDATING: 5,
    MOST_READ_WEEKLY: 6,
    MOST_BUY_CHAPTER: 7,
    NEW_HOT: 8
};

novel.EBookQualityScoreType = {
    SUM: 0,
    R1: 1,
    R2: 2,
    R3: 3,
    R4: 4,
    R1R2: 5
};

novel.EAppSectionShowAt = {
    INDEX: 1,
    TAB: 2
};

novel.EAppSectionType = {
    SUBJECT: 1,
    RANK: 2,
    CATEGORIES: 3,
    TAGS: 4,
    BOOK_LIST: 5,
    CATEGORY_SUBJECT: 6
};

novel.EAppTabType = {
    CATEGORY: 0,
    CUSTOM: 1
};

novel.EStoryTagType = {
    GENRE: 1,
    PLOT: 2,
    EMOTION: 3,
    SPACE_TIME: 4,
    LOCATION: 5
};

novel.EStoryProperty = {
    NAME: 1,
    SYNOPSIS: 2
};

novel.EGenerateStoryWordsFunction = {
    REFINE: 1,
    REWRITE: 2,
    EXPAND: 3,
    SUMMARIZE: 4
};

novel.EConnectEvent = {
    CONNECT_LOGIN: 1,
    DISCONNECT: 2,
    DISCONNECT_IN_ROOM: 3
};

novel.ETaskDisplayList = {
    ANDROID_DAILY_TASK_LIST: 1,
    IOS_DAILY_TASK_LIST: 2
};

novel.EGameStatus = {
    ENABLE: 1,
    DISABLE: 0
};

novel.EGameRunStatus = {
    BETTING: 2,
    WAITING_DRAWING: 3,
    SUCCESS: 1,
    FAIL: 0
};

novel.EBettingFlowType = {
    HIT: 1,
    BET: 2
};

novel.EBettingGameId = {
    GREEDY_CAT: 1,
    PRIZE_WHEEL: 2
};

novel.EActivityType = {
    INVITE_USER: 1
};

novel.EGrowableRankPeriodType = {
    CUSTOM: 0,
    FOREVER: 1,
    DAY: 2,
    WEEK: 3
};

novel.EGrowableRankType = {
    GC_BET_GOLD: 1,
    INVITE_USERS: 2
};

novel.CacheSync = function() {
    this.command = 0;
    this.ns = "";
    this.name = "";
    this.ks = new Taf.Vector(new Taf.STRING());
};
novel.CacheSync.prototype._clone = function () { return new novel.CacheSync(); };
novel.CacheSync.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CacheSync.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CacheSync.prototype.readFrom = function (is) {
    this.command = is.readInt32(0, false, this.command);
    this.ns = is.readString(1, false, this.ns);
    this.name = is.readString(2, false, this.name);
    this.ks = is.readVector(3, false, this.ks);
};
novel.CacheSync.prototype.writeTo = function (os) {
    os.writeInt32(0, this.command);
    os.writeString(1, this.ns);
    os.writeString(2, this.name);
    os.writeVector(3, this.ks);
};

novel.AliOSSToken = function() {
    this.presignUrl = "";
    this.accessUrl = "";
    this.fileKey = "";
};
novel.AliOSSToken.prototype._clone = function () { return new novel.AliOSSToken(); };
novel.AliOSSToken.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AliOSSToken.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AliOSSToken.prototype.readFrom = function (is) {
    this.presignUrl = is.readString(0, false, this.presignUrl);
    this.accessUrl = is.readString(1, false, this.accessUrl);
    this.fileKey = is.readString(2, false, this.fileKey);
};
novel.AliOSSToken.prototype.writeTo = function (os) {
    os.writeString(0, this.presignUrl);
    os.writeString(1, this.accessUrl);
    os.writeString(2, this.fileKey);
};

novel.Tag = function() {
    this.tagId = 0;
    this.tagName = "";
    this.tagType = 0;
    this.display = 0;
    this.idx = 0;
    this.parentId = 0;
};
novel.Tag.prototype._clone = function () { return new novel.Tag(); };
novel.Tag.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Tag.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Tag.prototype.readFrom = function (is) {
    this.tagId = is.readInt32(0, false, this.tagId);
    this.tagName = is.readString(1, false, this.tagName);
    this.tagType = is.readInt32(2, false, this.tagType);
    this.display = is.readInt32(3, false, this.display);
    this.idx = is.readInt32(4, false, this.idx);
    this.parentId = is.readInt32(5, false, this.parentId);
};
novel.Tag.prototype.writeTo = function (os) {
    os.writeInt32(0, this.tagId);
    os.writeString(1, this.tagName);
    os.writeInt32(2, this.tagType);
    os.writeInt32(3, this.display);
    os.writeInt32(4, this.idx);
    os.writeInt32(5, this.parentId);
};

novel.MemberInfo = function() {
    this.type = 0;
    this.expireAt = 0;
    this.getWay = 0;
    this.ttl = 0;
    this.svip = 0;
    this.coinExpireAt = 0;
};
novel.MemberInfo.prototype._clone = function () { return new novel.MemberInfo(); };
novel.MemberInfo.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MemberInfo.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MemberInfo.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.expireAt = is.readInt64(1, false, this.expireAt);
    this.getWay = is.readInt32(2, false, this.getWay);
    this.ttl = is.readInt64(3, false, this.ttl);
    this.svip = is.readInt32(4, false, this.svip);
    this.coinExpireAt = is.readInt64(5, false, this.coinExpireAt);
};
novel.MemberInfo.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeInt64(1, this.expireAt);
    os.writeInt32(2, this.getWay);
    os.writeInt64(3, this.ttl);
    os.writeInt32(4, this.svip);
    os.writeInt64(5, this.coinExpireAt);
};

novel.UserBase = function() {
    this.uid = 0;
    this.nickName = "";
    this.zroleId = "";
    this.avatarUrl = "";
    this.gender = 0;
    this.accountType = 0;
    this.countryCode = "";
    this.lcid = 0;
    this.accountState = 0;
    this.privacySetting = 0;
    this.regTime = 0;
    this.inviteCode = "";
    this.snsType = 0;
    this.vipExpireAt = 0;
    this.svipExpireAt = 0;
    this.inviteUid = 0;
};
novel.UserBase.prototype._clone = function () { return new novel.UserBase(); };
novel.UserBase.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserBase.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserBase.prototype.readFrom = function (is) {
    this.uid = is.readInt64(0, false, this.uid);
    this.nickName = is.readString(1, false, this.nickName);
    this.zroleId = is.readString(2, false, this.zroleId);
    this.avatarUrl = is.readString(3, false, this.avatarUrl);
    this.gender = is.readInt32(4, false, this.gender);
    this.accountType = is.readInt32(5, false, this.accountType);
    this.countryCode = is.readString(6, false, this.countryCode);
    this.lcid = is.readInt32(7, false, this.lcid);
    this.accountState = is.readInt32(8, false, this.accountState);
    this.privacySetting = is.readInt64(9, false, this.privacySetting);
    this.regTime = is.readInt64(10, false, this.regTime);
    this.inviteCode = is.readString(11, false, this.inviteCode);
    this.snsType = is.readInt32(12, false, this.snsType);
    this.vipExpireAt = is.readInt64(13, false, this.vipExpireAt);
    this.svipExpireAt = is.readInt64(14, false, this.svipExpireAt);
    this.inviteUid = is.readInt64(15, false, this.inviteUid);
};
novel.UserBase.prototype.writeTo = function (os) {
    os.writeInt64(0, this.uid);
    os.writeString(1, this.nickName);
    os.writeString(2, this.zroleId);
    os.writeString(3, this.avatarUrl);
    os.writeInt32(4, this.gender);
    os.writeInt32(5, this.accountType);
    os.writeString(6, this.countryCode);
    os.writeInt32(7, this.lcid);
    os.writeInt32(8, this.accountState);
    os.writeInt64(9, this.privacySetting);
    os.writeInt64(10, this.regTime);
    os.writeString(11, this.inviteCode);
    os.writeInt32(12, this.snsType);
    os.writeInt64(13, this.vipExpireAt);
    os.writeInt64(14, this.svipExpireAt);
    os.writeInt64(15, this.inviteUid);
};

novel.UserInfo = function() {
    this.baseInfo = new novel.UserBase();
    this.birthday = "";
    this.bio = "";
    this.state = 0;
    this.points = 0;
    this.district = "";
    this.userTag = "";
    this.email = "";
};
novel.UserInfo.prototype._clone = function () { return new novel.UserInfo(); };
novel.UserInfo.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserInfo.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserInfo.prototype.readFrom = function (is) {
    this.baseInfo = is.readStruct(0, false, this.baseInfo);
    this.birthday = is.readString(1, false, this.birthday);
    this.bio = is.readString(2, false, this.bio);
    this.state = is.readInt32(3, false, this.state);
    this.points = is.readInt32(4, false, this.points);
    this.district = is.readString(5, false, this.district);
    this.userTag = is.readString(6, false, this.userTag);
    this.email = is.readString(7, false, this.email);
};
novel.UserInfo.prototype.writeTo = function (os) {
    os.writeStruct(0, this.baseInfo);
    os.writeString(1, this.birthday);
    os.writeString(2, this.bio);
    os.writeInt32(3, this.state);
    os.writeInt32(4, this.points);
    os.writeString(5, this.district);
    os.writeString(6, this.userTag);
    os.writeString(7, this.email);
};

novel.PageReq = function() {
    this.offset = 0;
    this.size = 0;
};
novel.PageReq.prototype._clone = function () { return new novel.PageReq(); };
novel.PageReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PageReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PageReq.prototype.readFrom = function (is) {
    this.offset = is.readInt32(0, false, this.offset);
    this.size = is.readInt32(1, false, this.size);
};
novel.PageReq.prototype.writeTo = function (os) {
    os.writeInt32(0, this.offset);
    os.writeInt32(1, this.size);
};

novel.NoAdGoodsResource = function() {
    this.durationDay = 0;
};
novel.NoAdGoodsResource.prototype._clone = function () { return new novel.NoAdGoodsResource(); };
novel.NoAdGoodsResource.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.NoAdGoodsResource.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.NoAdGoodsResource.prototype.readFrom = function (is) {
    this.durationDay = is.readInt32(0, false, this.durationDay);
};
novel.NoAdGoodsResource.prototype.writeTo = function (os) {
    os.writeInt32(0, this.durationDay);
};

novel.GoodsSpu = function() {
    this.id = 0;
    this.name = "";
    this.describe = "";
    this.resources = "";
    this.category = 0;
    this.subCategory = 0;
    this.state = 0;
    this.imgUrl = "";
    this.useCnt = 0;
    this.ownNum = 0;
    this.comboArr = new Taf.Vector(new Taf.INT32());
    this.bookId = 0;
    this.chapterId = 0;
};
novel.GoodsSpu.prototype._clone = function () { return new novel.GoodsSpu(); };
novel.GoodsSpu.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GoodsSpu.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GoodsSpu.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.name = is.readString(1, false, this.name);
    this.describe = is.readString(2, false, this.describe);
    this.resources = is.readString(3, false, this.resources);
    this.category = is.readInt32(4, false, this.category);
    this.subCategory = is.readInt32(5, false, this.subCategory);
    this.state = is.readInt32(6, false, this.state);
    this.imgUrl = is.readString(7, false, this.imgUrl);
    this.useCnt = is.readInt64(8, false, this.useCnt);
    this.ownNum = is.readInt32(9, false, this.ownNum);
    this.comboArr = is.readVector(10, false, this.comboArr);
    this.bookId = is.readInt64(11, false, this.bookId);
    this.chapterId = is.readInt64(12, false, this.chapterId);
};
novel.GoodsSpu.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.name);
    os.writeString(2, this.describe);
    os.writeString(3, this.resources);
    os.writeInt32(4, this.category);
    os.writeInt32(5, this.subCategory);
    os.writeInt32(6, this.state);
    os.writeString(7, this.imgUrl);
    os.writeInt64(8, this.useCnt);
    os.writeInt32(9, this.ownNum);
    os.writeVector(10, this.comboArr);
    os.writeInt64(11, this.bookId);
    os.writeInt64(12, this.chapterId);
};

novel.GoodsSku = function() {
    this.id = 0;
    this.goods = new novel.GoodsSpu();
    this.price = 0;
    this.currency = 0;
    this.stock = 0;
    this.endTime = 0;
    this.state = 0;
    this.name = "";
    this.createTime = 0;
    this.getWay = 0;
    this.vipDiscount = 0;
};
novel.GoodsSku.prototype._clone = function () { return new novel.GoodsSku(); };
novel.GoodsSku.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GoodsSku.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GoodsSku.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.goods = is.readStruct(1, false, this.goods);
    this.price = is.readInt32(2, false, this.price);
    this.currency = is.readInt32(3, false, this.currency);
    this.stock = is.readInt32(4, false, this.stock);
    this.endTime = is.readInt64(5, false, this.endTime);
    this.state = is.readInt32(6, false, this.state);
    this.name = is.readString(7, false, this.name);
    this.createTime = is.readInt64(8, false, this.createTime);
    this.getWay = is.readInt32(9, false, this.getWay);
    this.vipDiscount = is.readFloat(10, false, this.vipDiscount);
};
novel.GoodsSku.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeStruct(1, this.goods);
    os.writeInt32(2, this.price);
    os.writeInt32(3, this.currency);
    os.writeInt32(4, this.stock);
    os.writeInt64(5, this.endTime);
    os.writeInt32(6, this.state);
    os.writeString(7, this.name);
    os.writeInt64(8, this.createTime);
    os.writeInt32(9, this.getWay);
    os.writeFloat(10, this.vipDiscount);
};

novel.MemberProduct = function() {
    this.pid = "";
    this.type = 0;
    this.days = 0;
    this.name = "";
    this.priceFee = 0;
    this.originalPrice = 0;
    this.applePid = "";
    this.googlePid = "";
};
novel.MemberProduct.prototype._clone = function () { return new novel.MemberProduct(); };
novel.MemberProduct.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MemberProduct.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MemberProduct.prototype.readFrom = function (is) {
    this.pid = is.readString(0, false, this.pid);
    this.type = is.readInt32(1, false, this.type);
    this.days = is.readInt32(2, false, this.days);
    this.name = is.readString(3, false, this.name);
    this.priceFee = is.readInt32(4, false, this.priceFee);
    this.originalPrice = is.readInt32(5, false, this.originalPrice);
    this.applePid = is.readString(6, false, this.applePid);
    this.googlePid = is.readString(7, false, this.googlePid);
};
novel.MemberProduct.prototype.writeTo = function (os) {
    os.writeString(0, this.pid);
    os.writeInt32(1, this.type);
    os.writeInt32(2, this.days);
    os.writeString(3, this.name);
    os.writeInt32(4, this.priceFee);
    os.writeInt32(5, this.originalPrice);
    os.writeString(6, this.applePid);
    os.writeString(7, this.googlePid);
};

novel.BookCategory = function() {
    this.id = 0;
    this.name = "";
    this.cover = "";
    this.lcid = 0;
    this.type = 0;
};
novel.BookCategory.prototype._clone = function () { return new novel.BookCategory(); };
novel.BookCategory.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookCategory.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookCategory.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.name = is.readString(1, false, this.name);
    this.cover = is.readString(2, false, this.cover);
    this.lcid = is.readInt32(3, false, this.lcid);
    this.type = is.readInt32(4, false, this.type);
};
novel.BookCategory.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.name);
    os.writeString(2, this.cover);
    os.writeInt32(3, this.lcid);
    os.writeInt32(4, this.type);
};

novel.ChapterAudio = function() {
    this.bookId = 0;
    this.chapterId = 0;
    this.voice = 0;
    this.millis = 0;
    this.size = 0;
    this.owned = 0;
};
novel.ChapterAudio.prototype._clone = function () { return new novel.ChapterAudio(); };
novel.ChapterAudio.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ChapterAudio.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ChapterAudio.prototype.readFrom = function (is) {
    this.bookId = is.readInt64(0, false, this.bookId);
    this.chapterId = is.readInt64(1, false, this.chapterId);
    this.voice = is.readInt32(2, false, this.voice);
    this.millis = is.readInt64(3, false, this.millis);
    this.size = is.readInt64(4, false, this.size);
    this.owned = is.readInt32(5, false, this.owned);
};
novel.ChapterAudio.prototype.writeTo = function (os) {
    os.writeInt64(0, this.bookId);
    os.writeInt64(1, this.chapterId);
    os.writeInt32(2, this.voice);
    os.writeInt64(3, this.millis);
    os.writeInt64(4, this.size);
    os.writeInt32(5, this.owned);
};

novel.Chapter = function() {
    this.id = 0;
    this.name = "";
    this.idx = 0;
    this.lineCount = 0;
    this.updateTime = 0;
    this.sku = new novel.GoodsSku();
    this.publishState = 0;
    this.publishTime = 0;
    this.audios = new Taf.Vector(new novel.ChapterAudio());
};
novel.Chapter.prototype._clone = function () { return new novel.Chapter(); };
novel.Chapter.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Chapter.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Chapter.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.name = is.readString(1, false, this.name);
    this.idx = is.readInt32(2, false, this.idx);
    this.lineCount = is.readInt32(3, false, this.lineCount);
    this.updateTime = is.readInt64(4, false, this.updateTime);
    this.sku = is.readStruct(5, false, this.sku);
    this.publishState = is.readInt32(6, false, this.publishState);
    this.publishTime = is.readInt64(7, false, this.publishTime);
    this.audios = is.readVector(8, false, this.audios);
};
novel.Chapter.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.name);
    os.writeInt32(2, this.idx);
    os.writeInt32(3, this.lineCount);
    os.writeInt64(4, this.updateTime);
    os.writeStruct(5, this.sku);
    os.writeInt32(6, this.publishState);
    os.writeInt64(7, this.publishTime);
    os.writeVector(8, this.audios);
};

novel.ReadProgress = function() {
    this.chapterId = 0;
    this.chapter = new novel.Chapter();
    this.updateTime = 0;
    this.paragraph = 0;
    this.offset = 0;
    this.type = 0;
    this.audioOffset = 0;
    this.voice = 0;
};
novel.ReadProgress.prototype._clone = function () { return new novel.ReadProgress(); };
novel.ReadProgress.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReadProgress.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReadProgress.prototype.readFrom = function (is) {
    this.chapterId = is.readInt64(0, false, this.chapterId);
    this.chapter = is.readStruct(1, false, this.chapter);
    this.updateTime = is.readInt64(2, false, this.updateTime);
    this.paragraph = is.readInt32(3, false, this.paragraph);
    this.offset = is.readInt32(4, false, this.offset);
    this.type = is.readInt32(5, false, this.type);
    this.audioOffset = is.readInt64(6, false, this.audioOffset);
    this.voice = is.readInt32(7, false, this.voice);
};
novel.ReadProgress.prototype.writeTo = function (os) {
    os.writeInt64(0, this.chapterId);
    os.writeStruct(1, this.chapter);
    os.writeInt64(2, this.updateTime);
    os.writeInt32(3, this.paragraph);
    os.writeInt32(4, this.offset);
    os.writeInt32(5, this.type);
    os.writeInt64(6, this.audioOffset);
    os.writeInt32(7, this.voice);
};

novel.BookInteractiveData = function() {
    this.reads = 0;
    this.commentCnt = 0;
    this.star = 0;
    this.parentCommentCnt = 0;
};
novel.BookInteractiveData.prototype._clone = function () { return new novel.BookInteractiveData(); };
novel.BookInteractiveData.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookInteractiveData.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookInteractiveData.prototype.readFrom = function (is) {
    this.reads = is.readInt32(0, false, this.reads);
    this.commentCnt = is.readInt32(1, false, this.commentCnt);
    this.star = is.readInt32(2, false, this.star);
    this.parentCommentCnt = is.readInt32(3, false, this.parentCommentCnt);
};
novel.BookInteractiveData.prototype.writeTo = function (os) {
    os.writeInt32(0, this.reads);
    os.writeInt32(1, this.commentCnt);
    os.writeInt32(2, this.star);
    os.writeInt32(3, this.parentCommentCnt);
};

novel.HonorTag = function() {
    this.type = 0;
    this.title = "";
    this.icon = "";
    this.textColor = "";
    this.backColor = "";
};
novel.HonorTag.prototype._clone = function () { return new novel.HonorTag(); };
novel.HonorTag.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HonorTag.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HonorTag.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.title = is.readString(1, false, this.title);
    this.icon = is.readString(2, false, this.icon);
    this.textColor = is.readString(3, false, this.textColor);
    this.backColor = is.readString(4, false, this.backColor);
};
novel.HonorTag.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.title);
    os.writeString(2, this.icon);
    os.writeString(3, this.textColor);
    os.writeString(4, this.backColor);
};

novel.SpecialSubjectDiscount = function() {
    this.discount = 0;
    this.expireAt = 0;
};
novel.SpecialSubjectDiscount.prototype._clone = function () { return new novel.SpecialSubjectDiscount(); };
novel.SpecialSubjectDiscount.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SpecialSubjectDiscount.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SpecialSubjectDiscount.prototype.readFrom = function (is) {
    this.discount = is.readFloat(0, false, this.discount);
    this.expireAt = is.readInt64(1, false, this.expireAt);
};
novel.SpecialSubjectDiscount.prototype.writeTo = function (os) {
    os.writeFloat(0, this.discount);
    os.writeInt64(1, this.expireAt);
};

novel.Book = function() {
    this.bookId = 0;
    this.name = "";
    this.description = "";
    this.author = "";
    this.cover = "";
    this.score = 0;
    this.categories = new Taf.Vector(new novel.BookCategory());
    this.progress = new novel.ReadProgress();
    this.idata = new novel.BookInteractiveData();
    this.lastUpdateChapter = new novel.Chapter();
    this.subscribed = true;
    this.inShelf = true;
    this.chapterCount = 0;
    this.firstChapterId = 0;
    this.completeState = 0;
    this.firstChapter = new novel.Chapter();
    this.tags = new Taf.Vector(new novel.BookCategory());
    this.honorTag = new novel.HonorTag();
    this.wordCount = 0;
    this.lcid = 0;
    this.secondCover = "";
    this.subjectDiscount = new novel.SpecialSubjectDiscount();
    this.withAudio = 0;
    this.audioProgress = new novel.ReadProgress();
};
novel.Book.prototype._clone = function () { return new novel.Book(); };
novel.Book.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Book.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Book.prototype.readFrom = function (is) {
    this.bookId = is.readInt64(0, false, this.bookId);
    this.name = is.readString(1, false, this.name);
    this.description = is.readString(2, false, this.description);
    this.author = is.readString(3, false, this.author);
    this.cover = is.readString(4, false, this.cover);
    this.score = is.readInt32(5, false, this.score);
    this.categories = is.readVector(6, false, this.categories);
    this.progress = is.readStruct(7, false, this.progress);
    this.idata = is.readStruct(8, false, this.idata);
    this.lastUpdateChapter = is.readStruct(9, false, this.lastUpdateChapter);
    this.subscribed = is.readBoolean(10, false, this.subscribed);
    this.inShelf = is.readBoolean(11, false, this.inShelf);
    this.chapterCount = is.readInt32(12, false, this.chapterCount);
    this.firstChapterId = is.readInt64(13, false, this.firstChapterId);
    this.completeState = is.readInt32(14, false, this.completeState);
    this.firstChapter = is.readStruct(15, false, this.firstChapter);
    this.tags = is.readVector(16, false, this.tags);
    this.honorTag = is.readStruct(17, false, this.honorTag);
    this.wordCount = is.readInt32(18, false, this.wordCount);
    this.lcid = is.readInt32(19, false, this.lcid);
    this.secondCover = is.readString(20, false, this.secondCover);
    this.subjectDiscount = is.readStruct(21, false, this.subjectDiscount);
    this.withAudio = is.readInt32(22, false, this.withAudio);
    this.audioProgress = is.readStruct(23, false, this.audioProgress);
};
novel.Book.prototype.writeTo = function (os) {
    os.writeInt64(0, this.bookId);
    os.writeString(1, this.name);
    os.writeString(2, this.description);
    os.writeString(3, this.author);
    os.writeString(4, this.cover);
    os.writeInt32(5, this.score);
    os.writeVector(6, this.categories);
    os.writeStruct(7, this.progress);
    os.writeStruct(8, this.idata);
    os.writeStruct(9, this.lastUpdateChapter);
    os.writeBoolean(10, this.subscribed);
    os.writeBoolean(11, this.inShelf);
    os.writeInt32(12, this.chapterCount);
    os.writeInt64(13, this.firstChapterId);
    os.writeInt32(14, this.completeState);
    os.writeStruct(15, this.firstChapter);
    os.writeVector(16, this.tags);
    os.writeStruct(17, this.honorTag);
    os.writeInt32(18, this.wordCount);
    os.writeInt32(19, this.lcid);
    os.writeString(20, this.secondCover);
    os.writeStruct(21, this.subjectDiscount);
    os.writeInt32(22, this.withAudio);
    os.writeStruct(23, this.audioProgress);
};

novel.ChapterList = function() {
    this.chapters = new Taf.Vector(new novel.Chapter());
    this.hasMore = true;
    this.total = 0;
};
novel.ChapterList.prototype._clone = function () { return new novel.ChapterList(); };
novel.ChapterList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ChapterList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ChapterList.prototype.readFrom = function (is) {
    this.chapters = is.readVector(0, false, this.chapters);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.total = is.readInt32(2, false, this.total);
};
novel.ChapterList.prototype.writeTo = function (os) {
    os.writeVector(0, this.chapters);
    os.writeBoolean(1, this.hasMore);
    os.writeInt32(2, this.total);
};

novel.ChapterLine = function() {
    this.pid = 0;
    this.content = "";
    this.deleted = 0;
};
novel.ChapterLine.prototype._clone = function () { return new novel.ChapterLine(); };
novel.ChapterLine.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ChapterLine.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ChapterLine.prototype.readFrom = function (is) {
    this.pid = is.readInt32(0, false, this.pid);
    this.content = is.readString(1, false, this.content);
    this.deleted = is.readInt32(2, false, this.deleted);
};
novel.ChapterLine.prototype.writeTo = function (os) {
    os.writeInt32(0, this.pid);
    os.writeString(1, this.content);
    os.writeInt32(2, this.deleted);
};

novel.ChapterLines = function() {
    this.hasMore = true;
    this.lines = new Taf.Vector(new novel.ChapterLine());
    this.retCode = 0;
};
novel.ChapterLines.prototype._clone = function () { return new novel.ChapterLines(); };
novel.ChapterLines.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ChapterLines.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ChapterLines.prototype.readFrom = function (is) {
    this.hasMore = is.readBoolean(0, false, this.hasMore);
    this.lines = is.readVector(1, false, this.lines);
    this.retCode = is.readInt32(2, false, this.retCode);
};
novel.ChapterLines.prototype.writeTo = function (os) {
    os.writeBoolean(0, this.hasMore);
    os.writeVector(1, this.lines);
    os.writeInt32(2, this.retCode);
};

novel.UserId = function() {
    this.uid = 0;
    this.guid = "";
    this.token = "";
    this.userAgent = "";
    this.tokenType = 0;
    this.testMode = true;
    this.countryCode = "";
    this.lcid = 0;
};
novel.UserId.prototype._clone = function () { return new novel.UserId(); };
novel.UserId.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserId.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserId.prototype.readFrom = function (is) {
    this.uid = is.readInt64(0, false, this.uid);
    this.guid = is.readString(1, false, this.guid);
    this.token = is.readString(2, false, this.token);
    this.userAgent = is.readString(3, false, this.userAgent);
    this.tokenType = is.readInt32(4, false, this.tokenType);
    this.testMode = is.readBoolean(5, false, this.testMode);
    this.countryCode = is.readString(6, false, this.countryCode);
    this.lcid = is.readInt32(7, false, this.lcid);
};
novel.UserId.prototype.writeTo = function (os) {
    os.writeInt64(0, this.uid);
    os.writeString(1, this.guid);
    os.writeString(2, this.token);
    os.writeString(3, this.userAgent);
    os.writeInt32(4, this.tokenType);
    os.writeBoolean(5, this.testMode);
    os.writeString(6, this.countryCode);
    os.writeInt32(7, this.lcid);
};

novel.CommonReq = function() {
    this.tId = new novel.UserId();
};
novel.CommonReq.prototype._clone = function () { return new novel.CommonReq(); };
novel.CommonReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CommonReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CommonReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.CommonReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.GetSmsCodeReq = function() {
    this.tId = new novel.UserId();
    this.nationalCode = "";
    this.phoneNum = "";
    this.sign = "";
    this.useBindPhone = false;
    this.cipher = "";
};
novel.GetSmsCodeReq.prototype._clone = function () { return new novel.GetSmsCodeReq(); };
novel.GetSmsCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetSmsCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetSmsCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.nationalCode = is.readString(1, false, this.nationalCode);
    this.phoneNum = is.readString(2, false, this.phoneNum);
    this.sign = is.readString(3, false, this.sign);
    this.useBindPhone = is.readBoolean(4, false, this.useBindPhone);
    this.cipher = is.readString(5, false, this.cipher);
};
novel.GetSmsCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.nationalCode);
    os.writeString(2, this.phoneNum);
    os.writeString(3, this.sign);
    os.writeBoolean(4, this.useBindPhone);
    os.writeString(5, this.cipher);
};

novel.LoginViaSmsCodeReq = function() {
    this.tId = new novel.UserId();
    this.nationalCode = "";
    this.phoneNum = "";
    this.code = "";
    this.sign = "";
    this.cipher = "";
    this.inviteCode = "";
};
novel.LoginViaSmsCodeReq.prototype._clone = function () { return new novel.LoginViaSmsCodeReq(); };
novel.LoginViaSmsCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LoginViaSmsCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LoginViaSmsCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.nationalCode = is.readString(1, false, this.nationalCode);
    this.phoneNum = is.readString(2, false, this.phoneNum);
    this.code = is.readString(3, false, this.code);
    this.sign = is.readString(4, false, this.sign);
    this.cipher = is.readString(5, false, this.cipher);
    this.inviteCode = is.readString(6, false, this.inviteCode);
};
novel.LoginViaSmsCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.nationalCode);
    os.writeString(2, this.phoneNum);
    os.writeString(3, this.code);
    os.writeString(4, this.sign);
    os.writeString(5, this.cipher);
    os.writeString(6, this.inviteCode);
};

novel.LoginViaMobileCodeReq = function() {
    this.tId = new novel.UserId();
    this.token = "";
    this.verifyId = "";
    this.sign = "";
    this.cipher = "";
};
novel.LoginViaMobileCodeReq.prototype._clone = function () { return new novel.LoginViaMobileCodeReq(); };
novel.LoginViaMobileCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LoginViaMobileCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LoginViaMobileCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.token = is.readString(1, false, this.token);
    this.verifyId = is.readString(2, false, this.verifyId);
    this.sign = is.readString(3, false, this.sign);
    this.cipher = is.readString(4, false, this.cipher);
};
novel.LoginViaMobileCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.token);
    os.writeString(2, this.verifyId);
    os.writeString(3, this.sign);
    os.writeString(4, this.cipher);
};

novel.LoginRsp = function() {
    this.token = "";
    this.userInfo = new novel.UserInfo();
    this.isNewUser = true;
    this.hasBindPhone = true;
};
novel.LoginRsp.prototype._clone = function () { return new novel.LoginRsp(); };
novel.LoginRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LoginRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LoginRsp.prototype.readFrom = function (is) {
    this.token = is.readString(0, false, this.token);
    this.userInfo = is.readStruct(1, false, this.userInfo);
    this.isNewUser = is.readBoolean(2, false, this.isNewUser);
    this.hasBindPhone = is.readBoolean(3, false, this.hasBindPhone);
};
novel.LoginRsp.prototype.writeTo = function (os) {
    os.writeString(0, this.token);
    os.writeStruct(1, this.userInfo);
    os.writeBoolean(2, this.isNewUser);
    os.writeBoolean(3, this.hasBindPhone);
};

novel.LoginViaSNSReq = function() {
    this.tId = new novel.UserId();
    this.snsType = 0;
    this.token = "";
    this.sign = "";
    this.cipher = "";
    this.inviteCode = "";
    this.snsName = "";
};
novel.LoginViaSNSReq.prototype._clone = function () { return new novel.LoginViaSNSReq(); };
novel.LoginViaSNSReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LoginViaSNSReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LoginViaSNSReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.snsType = is.readInt32(1, false, this.snsType);
    this.token = is.readString(2, false, this.token);
    this.sign = is.readString(3, false, this.sign);
    this.cipher = is.readString(4, false, this.cipher);
    this.inviteCode = is.readString(5, false, this.inviteCode);
    this.snsName = is.readString(6, false, this.snsName);
};
novel.LoginViaSNSReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.snsType);
    os.writeString(2, this.token);
    os.writeString(3, this.sign);
    os.writeString(4, this.cipher);
    os.writeString(5, this.inviteCode);
    os.writeString(6, this.snsName);
};

novel.LoginViaEmailPasswordReq = function() {
    this.tId = new novel.UserId();
    this.email = "";
    this.password = "";
    this.code = "";
    this.cipher = "";
    this.inviteCode = "";
};
novel.LoginViaEmailPasswordReq.prototype._clone = function () { return new novel.LoginViaEmailPasswordReq(); };
novel.LoginViaEmailPasswordReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LoginViaEmailPasswordReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LoginViaEmailPasswordReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.email = is.readString(1, false, this.email);
    this.password = is.readString(2, false, this.password);
    this.code = is.readString(3, false, this.code);
    this.cipher = is.readString(4, false, this.cipher);
    this.inviteCode = is.readString(5, false, this.inviteCode);
};
novel.LoginViaEmailPasswordReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.email);
    os.writeString(2, this.password);
    os.writeString(3, this.code);
    os.writeString(4, this.cipher);
    os.writeString(5, this.inviteCode);
};

novel.ValidEmailCodeReq = function() {
    this.tId = new novel.UserId();
    this.email = "";
    this.code = "";
    this.cipher = "";
};
novel.ValidEmailCodeReq.prototype._clone = function () { return new novel.ValidEmailCodeReq(); };
novel.ValidEmailCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ValidEmailCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ValidEmailCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.email = is.readString(1, false, this.email);
    this.code = is.readString(2, false, this.code);
    this.cipher = is.readString(3, false, this.cipher);
};
novel.ValidEmailCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.email);
    os.writeString(2, this.code);
    os.writeString(3, this.cipher);
};

novel.QueryEmailAccountExistReq = function() {
    this.tId = new novel.UserId();
    this.email = "";
    this.cipher = "";
};
novel.QueryEmailAccountExistReq.prototype._clone = function () { return new novel.QueryEmailAccountExistReq(); };
novel.QueryEmailAccountExistReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryEmailAccountExistReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryEmailAccountExistReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.email = is.readString(1, false, this.email);
    this.cipher = is.readString(2, false, this.cipher);
};
novel.QueryEmailAccountExistReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.email);
    os.writeString(2, this.cipher);
};

novel.SendEmailCodeReq = function() {
    this.tId = new novel.UserId();
    this.email = "";
    this.cipher = "";
};
novel.SendEmailCodeReq.prototype._clone = function () { return new novel.SendEmailCodeReq(); };
novel.SendEmailCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SendEmailCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SendEmailCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.email = is.readString(1, false, this.email);
    this.cipher = is.readString(2, false, this.cipher);
};
novel.SendEmailCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.email);
    os.writeString(2, this.cipher);
};

novel.RefreshTokenReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
};
novel.RefreshTokenReq.prototype._clone = function () { return new novel.RefreshTokenReq(); };
novel.RefreshTokenReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RefreshTokenReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RefreshTokenReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
};
novel.RefreshTokenReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
};

novel.RefreshTokenRsp = function() {
    this.token = "";
    this.userInfo = new novel.UserInfo();
};
novel.RefreshTokenRsp.prototype._clone = function () { return new novel.RefreshTokenRsp(); };
novel.RefreshTokenRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RefreshTokenRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RefreshTokenRsp.prototype.readFrom = function (is) {
    this.token = is.readString(0, false, this.token);
    this.userInfo = is.readStruct(1, false, this.userInfo);
};
novel.RefreshTokenRsp.prototype.writeTo = function (os) {
    os.writeString(0, this.token);
    os.writeStruct(1, this.userInfo);
};

novel.Binding3rdAccountReq = function() {
    this.tId = new novel.UserId();
    this.snsType = 0;
    this.nationalCode = "";
    this.phoneNum = "";
    this.code = "";
    this.cipher = "";
    this.token = "";
    this.name = "";
    this.password = "";
    this.email = "";
};
novel.Binding3rdAccountReq.prototype._clone = function () { return new novel.Binding3rdAccountReq(); };
novel.Binding3rdAccountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Binding3rdAccountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Binding3rdAccountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.snsType = is.readInt32(1, false, this.snsType);
    this.nationalCode = is.readString(2, false, this.nationalCode);
    this.phoneNum = is.readString(3, false, this.phoneNum);
    this.code = is.readString(4, false, this.code);
    this.cipher = is.readString(5, false, this.cipher);
    this.token = is.readString(6, false, this.token);
    this.name = is.readString(7, false, this.name);
    this.password = is.readString(8, false, this.password);
    this.email = is.readString(9, false, this.email);
};
novel.Binding3rdAccountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.snsType);
    os.writeString(2, this.nationalCode);
    os.writeString(3, this.phoneNum);
    os.writeString(4, this.code);
    os.writeString(5, this.cipher);
    os.writeString(6, this.token);
    os.writeString(7, this.name);
    os.writeString(8, this.password);
    os.writeString(9, this.email);
};

novel.Binding3rdAccountRsp = function() {
    this.rspCode = 0;
    this.base = new novel.UserBase();
};
novel.Binding3rdAccountRsp.prototype._clone = function () { return new novel.Binding3rdAccountRsp(); };
novel.Binding3rdAccountRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Binding3rdAccountRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Binding3rdAccountRsp.prototype.readFrom = function (is) {
    this.rspCode = is.readInt32(0, false, this.rspCode);
    this.base = is.readStruct(1, false, this.base);
};
novel.Binding3rdAccountRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.rspCode);
    os.writeStruct(1, this.base);
};

novel.Unbinding3rdAccountReq = function() {
    this.tId = new novel.UserId();
    this.snsType = 0;
    this.nationalCode = "";
    this.phoneNum = "";
    this.code = "";
    this.cipher = "";
    this.opendId = "";
};
novel.Unbinding3rdAccountReq.prototype._clone = function () { return new novel.Unbinding3rdAccountReq(); };
novel.Unbinding3rdAccountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Unbinding3rdAccountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Unbinding3rdAccountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.snsType = is.readInt32(1, false, this.snsType);
    this.nationalCode = is.readString(2, false, this.nationalCode);
    this.phoneNum = is.readString(3, false, this.phoneNum);
    this.code = is.readString(4, false, this.code);
    this.cipher = is.readString(5, false, this.cipher);
    this.opendId = is.readString(6, false, this.opendId);
};
novel.Unbinding3rdAccountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.snsType);
    os.writeString(2, this.nationalCode);
    os.writeString(3, this.phoneNum);
    os.writeString(4, this.code);
    os.writeString(5, this.cipher);
    os.writeString(6, this.opendId);
};

novel.VerifyPhoneReq = function() {
    this.tId = new novel.UserId();
    this.nationalCode = "";
    this.phoneNum = "";
    this.cipher = "";
};
novel.VerifyPhoneReq.prototype._clone = function () { return new novel.VerifyPhoneReq(); };
novel.VerifyPhoneReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.VerifyPhoneReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.VerifyPhoneReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.nationalCode = is.readString(1, false, this.nationalCode);
    this.phoneNum = is.readString(2, false, this.phoneNum);
    this.cipher = is.readString(3, false, this.cipher);
};
novel.VerifyPhoneReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.nationalCode);
    os.writeString(2, this.phoneNum);
    os.writeString(3, this.cipher);
};

novel.Query3rdAccountReq = function() {
    this.tId = new novel.UserId();
    this.snsType = 0;
};
novel.Query3rdAccountReq.prototype._clone = function () { return new novel.Query3rdAccountReq(); };
novel.Query3rdAccountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Query3rdAccountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Query3rdAccountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.snsType = is.readInt32(1, false, this.snsType);
};
novel.Query3rdAccountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.snsType);
};

novel.ListBinding3rdAccountReq = function() {
    this.tId = new novel.UserId();
    this.types = new Taf.Vector(new Taf.INT32());
};
novel.ListBinding3rdAccountReq.prototype._clone = function () { return new novel.ListBinding3rdAccountReq(); };
novel.ListBinding3rdAccountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBinding3rdAccountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBinding3rdAccountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.types = is.readVector(1, false, this.types);
};
novel.ListBinding3rdAccountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.types);
};

novel.BindingAccount = function() {
    this.type = 0;
    this.bindingValue = "";
    this.bindingName = "";
};
novel.BindingAccount.prototype._clone = function () { return new novel.BindingAccount(); };
novel.BindingAccount.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BindingAccount.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BindingAccount.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.bindingValue = is.readString(1, false, this.bindingValue);
    this.bindingName = is.readString(2, false, this.bindingName);
};
novel.BindingAccount.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.bindingValue);
    os.writeString(2, this.bindingName);
};

novel.BindingAccountList = function() {
    this.bindings = new Taf.Vector(new novel.BindingAccount());
};
novel.BindingAccountList.prototype._clone = function () { return new novel.BindingAccountList(); };
novel.BindingAccountList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BindingAccountList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BindingAccountList.prototype.readFrom = function (is) {
    this.bindings = is.readVector(0, false, this.bindings);
};
novel.BindingAccountList.prototype.writeTo = function (os) {
    os.writeVector(0, this.bindings);
};

novel.Query3rdAccountRsp = function() {
    this.account = "";
};
novel.Query3rdAccountRsp.prototype._clone = function () { return new novel.Query3rdAccountRsp(); };
novel.Query3rdAccountRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Query3rdAccountRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Query3rdAccountRsp.prototype.readFrom = function (is) {
    this.account = is.readString(0, false, this.account);
};
novel.Query3rdAccountRsp.prototype.writeTo = function (os) {
    os.writeString(0, this.account);
};

novel.ReleaseAccountReq = function() {
    this.tId = new novel.UserId();
    this.code = "";
    this.sign = "";
};
novel.ReleaseAccountReq.prototype._clone = function () { return new novel.ReleaseAccountReq(); };
novel.ReleaseAccountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReleaseAccountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReleaseAccountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.code = is.readString(1, false, this.code);
    this.sign = is.readString(2, false, this.sign);
};
novel.ReleaseAccountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.code);
    os.writeString(2, this.sign);
};

novel.UpdateUserInfoReq = function() {
    this.tId = new novel.UserId();
    this.nickName = "";
    this.avatarUrl = "";
    this.gender = 0;
    this.birthday = "";
    this.bio = "";
    this.zroleId = "";
    this.district = "";
    this.userTags = new Taf.Vector(new Taf.INT32());
    this.province = "";
    this.email = "";
};
novel.UpdateUserInfoReq.prototype._clone = function () { return new novel.UpdateUserInfoReq(); };
novel.UpdateUserInfoReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UpdateUserInfoReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UpdateUserInfoReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.nickName = is.readString(1, false, this.nickName);
    this.avatarUrl = is.readString(2, false, this.avatarUrl);
    this.gender = is.readInt32(3, false, this.gender);
    this.birthday = is.readString(4, false, this.birthday);
    this.bio = is.readString(5, false, this.bio);
    this.zroleId = is.readString(6, false, this.zroleId);
    this.district = is.readString(7, false, this.district);
    this.userTags = is.readVector(8, false, this.userTags);
    this.province = is.readString(9, false, this.province);
    this.email = is.readString(10, false, this.email);
};
novel.UpdateUserInfoReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.nickName);
    os.writeString(2, this.avatarUrl);
    os.writeInt32(3, this.gender);
    os.writeString(4, this.birthday);
    os.writeString(5, this.bio);
    os.writeString(6, this.zroleId);
    os.writeString(7, this.district);
    os.writeVector(8, this.userTags);
    os.writeString(9, this.province);
    os.writeString(10, this.email);
};

novel.OSSTokenRsp = function() {
    this.tokens = new Taf.Vector(new novel.AliOSSToken());
};
novel.OSSTokenRsp.prototype._clone = function () { return new novel.OSSTokenRsp(); };
novel.OSSTokenRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OSSTokenRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OSSTokenRsp.prototype.readFrom = function (is) {
    this.tokens = is.readVector(0, false, this.tokens);
};
novel.OSSTokenRsp.prototype.writeTo = function (os) {
    os.writeVector(0, this.tokens);
};

novel.UploadTokenReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.contentTypes = new Taf.Vector(new Taf.INT32());
};
novel.UploadTokenReq.prototype._clone = function () { return new novel.UploadTokenReq(); };
novel.UploadTokenReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UploadTokenReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UploadTokenReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.contentTypes = is.readVector(2, false, this.contentTypes);
};
novel.UploadTokenReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeVector(2, this.contentTypes);
};

novel.UserBaseList = function() {
    this.bases = new Taf.Vector(new novel.UserBase());
    this.hasMore = true;
};
novel.UserBaseList.prototype._clone = function () { return new novel.UserBaseList(); };
novel.UserBaseList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserBaseList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserBaseList.prototype.readFrom = function (is) {
    this.bases = is.readVector(0, false, this.bases);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserBaseList.prototype.writeTo = function (os) {
    os.writeVector(0, this.bases);
    os.writeBoolean(1, this.hasMore);
};

novel.UserInfoList = function() {
    this.infos = new Taf.Vector(new novel.UserInfo());
    this.hasMore = true;
};
novel.UserInfoList.prototype._clone = function () { return new novel.UserInfoList(); };
novel.UserInfoList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserInfoList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserInfoList.prototype.readFrom = function (is) {
    this.infos = is.readVector(0, false, this.infos);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserInfoList.prototype.writeTo = function (os) {
    os.writeVector(0, this.infos);
    os.writeBoolean(1, this.hasMore);
};

novel.GetUserInfoByIdReq = function() {
    this.tId = new novel.UserId();
    this.uid = 0;
};
novel.GetUserInfoByIdReq.prototype._clone = function () { return new novel.GetUserInfoByIdReq(); };
novel.GetUserInfoByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUserInfoByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUserInfoByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.uid = is.readInt64(1, false, this.uid);
};
novel.GetUserInfoByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.uid);
};

novel.GetUserForbiddenStateReq = function() {
    this.tId = new novel.UserId();
    this.type = 0;
    this.checkUid = 0;
};
novel.GetUserForbiddenStateReq.prototype._clone = function () { return new novel.GetUserForbiddenStateReq(); };
novel.GetUserForbiddenStateReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUserForbiddenStateReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUserForbiddenStateReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.type = is.readInt32(1, false, this.type);
    this.checkUid = is.readInt64(2, false, this.checkUid);
};
novel.GetUserForbiddenStateReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.type);
    os.writeInt64(2, this.checkUid);
};

novel.GetUserForbiddenStateRsp = function() {
    this.isForbidden = true;
    this.remainSec = 0;
};
novel.GetUserForbiddenStateRsp.prototype._clone = function () { return new novel.GetUserForbiddenStateRsp(); };
novel.GetUserForbiddenStateRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUserForbiddenStateRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUserForbiddenStateRsp.prototype.readFrom = function (is) {
    this.isForbidden = is.readBoolean(0, false, this.isForbidden);
    this.remainSec = is.readInt32(1, false, this.remainSec);
};
novel.GetUserForbiddenStateRsp.prototype.writeTo = function (os) {
    os.writeBoolean(0, this.isForbidden);
    os.writeInt32(1, this.remainSec);
};

novel.QueryUserBySnsOpenIdReq = function() {
    this.tId = new novel.UserId();
    this.snsType = 0;
    this.openids = new Taf.Vector(new Taf.STRING());
    this.noRelationOnly = 0;
};
novel.QueryUserBySnsOpenIdReq.prototype._clone = function () { return new novel.QueryUserBySnsOpenIdReq(); };
novel.QueryUserBySnsOpenIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryUserBySnsOpenIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryUserBySnsOpenIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.snsType = is.readInt32(1, false, this.snsType);
    this.openids = is.readVector(2, false, this.openids);
    this.noRelationOnly = is.readInt32(3, false, this.noRelationOnly);
};
novel.QueryUserBySnsOpenIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.snsType);
    os.writeVector(2, this.openids);
    os.writeInt32(3, this.noRelationOnly);
};

novel.QueryUserBySnsOpenIdRsp = function() {
    this.baseMap = new Taf.Map(new Taf.STRING(), new novel.UserBase());
};
novel.QueryUserBySnsOpenIdRsp.prototype._clone = function () { return new novel.QueryUserBySnsOpenIdRsp(); };
novel.QueryUserBySnsOpenIdRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryUserBySnsOpenIdRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryUserBySnsOpenIdRsp.prototype.readFrom = function (is) {
    this.baseMap = is.readMap(0, false, this.baseMap);
};
novel.QueryUserBySnsOpenIdRsp.prototype.writeTo = function (os) {
    os.writeMap(0, this.baseMap);
};

novel.UpdateUserPrivacySettingReq = function() {
    this.tId = new novel.UserId();
    this.privacyType = 0;
    this.privacyValue = 0;
};
novel.UpdateUserPrivacySettingReq.prototype._clone = function () { return new novel.UpdateUserPrivacySettingReq(); };
novel.UpdateUserPrivacySettingReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UpdateUserPrivacySettingReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UpdateUserPrivacySettingReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.privacyType = is.readInt32(1, false, this.privacyType);
    this.privacyValue = is.readInt32(2, false, this.privacyValue);
};
novel.UpdateUserPrivacySettingReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.privacyType);
    os.writeInt32(2, this.privacyValue);
};

novel.GetUserPrivacySettingReq = function() {
    this.tId = new novel.UserId();
    this.types = new Taf.Vector(new Taf.INT32());
    this.uid = 0;
};
novel.GetUserPrivacySettingReq.prototype._clone = function () { return new novel.GetUserPrivacySettingReq(); };
novel.GetUserPrivacySettingReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUserPrivacySettingReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUserPrivacySettingReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.types = is.readVector(1, false, this.types);
    this.uid = is.readInt64(2, false, this.uid);
};
novel.GetUserPrivacySettingReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.types);
    os.writeInt64(2, this.uid);
};

novel.UserPrivacySettings = function() {
    this.settings = new Taf.Map(new Taf.INT32(), new Taf.INT32());
};
novel.UserPrivacySettings.prototype._clone = function () { return new novel.UserPrivacySettings(); };
novel.UserPrivacySettings.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserPrivacySettings.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserPrivacySettings.prototype.readFrom = function (is) {
    this.settings = is.readMap(0, false, this.settings);
};
novel.UserPrivacySettings.prototype.writeTo = function (os) {
    os.writeMap(0, this.settings);
};

novel.SyncUserPhoneNumberReq = function() {
    this.tId = new novel.UserId();
    this.numbers = new Taf.Vector(new Taf.STRING());
};
novel.SyncUserPhoneNumberReq.prototype._clone = function () { return new novel.SyncUserPhoneNumberReq(); };
novel.SyncUserPhoneNumberReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SyncUserPhoneNumberReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SyncUserPhoneNumberReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.numbers = is.readVector(1, false, this.numbers);
};
novel.SyncUserPhoneNumberReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.numbers);
};

novel.ActiveInviteCodeReq = function() {
    this.tId = new novel.UserId();
    this.code = "";
};
novel.ActiveInviteCodeReq.prototype._clone = function () { return new novel.ActiveInviteCodeReq(); };
novel.ActiveInviteCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ActiveInviteCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ActiveInviteCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.code = is.readString(1, false, this.code);
};
novel.ActiveInviteCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.code);
};

novel.BindInviteCodeReq = function() {
    this.tId = new novel.UserId();
    this.code = "";
};
novel.BindInviteCodeReq.prototype._clone = function () { return new novel.BindInviteCodeReq(); };
novel.BindInviteCodeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BindInviteCodeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BindInviteCodeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.code = is.readString(1, false, this.code);
};
novel.BindInviteCodeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.code);
};

novel.SubComment = function() {
    this.id = 0;
    this.idstr = "";
    this.user = new novel.UserBase();
    this.toUser = new novel.UserBase();
    this.content = "";
    this.likeCnt = 0;
    this.createTime = 0;
    this.deleted = 0;
    this.parentId = 0;
    this.commentType = 0;
    this.liked = true;
    this.atUsers = new Taf.Vector(new novel.UserBase());
};
novel.SubComment.prototype._clone = function () { return new novel.SubComment(); };
novel.SubComment.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SubComment.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SubComment.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.idstr = is.readString(1, false, this.idstr);
    this.user = is.readStruct(2, false, this.user);
    this.toUser = is.readStruct(3, false, this.toUser);
    this.content = is.readString(4, false, this.content);
    this.likeCnt = is.readInt32(5, false, this.likeCnt);
    this.createTime = is.readInt64(6, false, this.createTime);
    this.deleted = is.readInt32(7, false, this.deleted);
    this.parentId = is.readInt64(8, false, this.parentId);
    this.commentType = is.readInt32(9, false, this.commentType);
    this.liked = is.readBoolean(10, false, this.liked);
    this.atUsers = is.readVector(11, false, this.atUsers);
};
novel.SubComment.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.idstr);
    os.writeStruct(2, this.user);
    os.writeStruct(3, this.toUser);
    os.writeString(4, this.content);
    os.writeInt32(5, this.likeCnt);
    os.writeInt64(6, this.createTime);
    os.writeInt32(7, this.deleted);
    os.writeInt64(8, this.parentId);
    os.writeInt32(9, this.commentType);
    os.writeBoolean(10, this.liked);
    os.writeVector(11, this.atUsers);
};

novel.Comment = function() {
    this.id = 0;
    this.idstr = "";
    this.user = new novel.UserBase();
    this.toUser = new novel.UserBase();
    this.content = "";
    this.likeCnt = 0;
    this.subCommentCnt = 0;
    this.subComments = new Taf.Vector(new novel.SubComment());
    this.createTime = 0;
    this.deleted = 0;
    this.parentId = 0;
    this.commentType = 0;
    this.liked = true;
    this.atUsers = new Taf.Vector(new novel.UserBase());
    this.toCmtId = 0;
    this.star = 0;
    this.bookId = 0;
    this.subjectId = 0;
    this.paragraph = 0;
};
novel.Comment.prototype._clone = function () { return new novel.Comment(); };
novel.Comment.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Comment.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Comment.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.idstr = is.readString(1, false, this.idstr);
    this.user = is.readStruct(2, false, this.user);
    this.toUser = is.readStruct(3, false, this.toUser);
    this.content = is.readString(4, false, this.content);
    this.likeCnt = is.readInt32(5, false, this.likeCnt);
    this.subCommentCnt = is.readInt32(6, false, this.subCommentCnt);
    this.subComments = is.readVector(7, false, this.subComments);
    this.createTime = is.readInt64(8, false, this.createTime);
    this.deleted = is.readInt32(9, false, this.deleted);
    this.parentId = is.readInt64(10, false, this.parentId);
    this.commentType = is.readInt32(11, false, this.commentType);
    this.liked = is.readBoolean(12, false, this.liked);
    this.atUsers = is.readVector(13, false, this.atUsers);
    this.toCmtId = is.readInt64(14, false, this.toCmtId);
    this.star = is.readInt32(15, false, this.star);
    this.bookId = is.readInt64(16, false, this.bookId);
    this.subjectId = is.readInt64(17, false, this.subjectId);
    this.paragraph = is.readInt32(18, false, this.paragraph);
};
novel.Comment.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.idstr);
    os.writeStruct(2, this.user);
    os.writeStruct(3, this.toUser);
    os.writeString(4, this.content);
    os.writeInt32(5, this.likeCnt);
    os.writeInt32(6, this.subCommentCnt);
    os.writeVector(7, this.subComments);
    os.writeInt64(8, this.createTime);
    os.writeInt32(9, this.deleted);
    os.writeInt64(10, this.parentId);
    os.writeInt32(11, this.commentType);
    os.writeBoolean(12, this.liked);
    os.writeVector(13, this.atUsers);
    os.writeInt64(14, this.toCmtId);
    os.writeInt32(15, this.star);
    os.writeInt64(16, this.bookId);
    os.writeInt64(17, this.subjectId);
    os.writeInt32(18, this.paragraph);
};

novel.CommentReq = function() {
    this.tId = new novel.UserId();
    this.subjectId = 0;
    this.parentId = 0;
    this.toUid = 0;
    this.content = "";
    this.commentType = 0;
    this.toCommentId = 0;
    this.contentType = 0;
    this.atUids = new Taf.Vector(new Taf.INT64());
    this.star = 0;
    this.bookId = 0;
    this.paragraph = 0;
};
novel.CommentReq.prototype._clone = function () { return new novel.CommentReq(); };
novel.CommentReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CommentReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CommentReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectId = is.readInt64(1, false, this.subjectId);
    this.parentId = is.readInt64(2, false, this.parentId);
    this.toUid = is.readInt64(3, false, this.toUid);
    this.content = is.readString(4, false, this.content);
    this.commentType = is.readInt32(5, false, this.commentType);
    this.toCommentId = is.readInt64(6, false, this.toCommentId);
    this.contentType = is.readInt32(7, false, this.contentType);
    this.atUids = is.readVector(8, false, this.atUids);
    this.star = is.readInt32(9, false, this.star);
    this.bookId = is.readInt64(10, false, this.bookId);
    this.paragraph = is.readInt32(11, false, this.paragraph);
};
novel.CommentReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.subjectId);
    os.writeInt64(2, this.parentId);
    os.writeInt64(3, this.toUid);
    os.writeString(4, this.content);
    os.writeInt32(5, this.commentType);
    os.writeInt64(6, this.toCommentId);
    os.writeInt32(7, this.contentType);
    os.writeVector(8, this.atUids);
    os.writeInt32(9, this.star);
    os.writeInt64(10, this.bookId);
    os.writeInt32(11, this.paragraph);
};

novel.EditCommentReq = function() {
    this.tId = new novel.UserId();
    this.commentId = 0;
    this.content = "";
    this.star = 0;
};
novel.EditCommentReq.prototype._clone = function () { return new novel.EditCommentReq(); };
novel.EditCommentReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.EditCommentReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.EditCommentReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.commentId = is.readInt64(1, false, this.commentId);
    this.content = is.readString(2, false, this.content);
    this.star = is.readInt32(3, false, this.star);
};
novel.EditCommentReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.commentId);
    os.writeString(2, this.content);
    os.writeInt32(3, this.star);
};

novel.CreateCommentRsp = function() {
    this.comment = new novel.Comment();
    this.subComment = new novel.SubComment();
};
novel.CreateCommentRsp.prototype._clone = function () { return new novel.CreateCommentRsp(); };
novel.CreateCommentRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CreateCommentRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CreateCommentRsp.prototype.readFrom = function (is) {
    this.comment = is.readStruct(0, false, this.comment);
    this.subComment = is.readStruct(1, false, this.subComment);
};
novel.CreateCommentRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.comment);
    os.writeStruct(1, this.subComment);
};

novel.CommentListReq = function() {
    this.tId = new novel.UserId();
    this.subjectId = 0;
    this.orderType = 0;
    this.offset = 0;
    this.size = 0;
    this.commentType = 0;
    this.withSubCmts = true;
    this.parentId = 0;
    this.bookId = 0;
    this.paragraph = 0;
};
novel.CommentListReq.prototype._clone = function () { return new novel.CommentListReq(); };
novel.CommentListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CommentListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CommentListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectId = is.readInt64(1, false, this.subjectId);
    this.orderType = is.readInt32(2, false, this.orderType);
    this.offset = is.readInt32(3, false, this.offset);
    this.size = is.readInt32(4, false, this.size);
    this.commentType = is.readInt32(5, false, this.commentType);
    this.withSubCmts = is.readBoolean(6, false, this.withSubCmts);
    this.parentId = is.readInt64(7, false, this.parentId);
    this.bookId = is.readInt64(8, false, this.bookId);
    this.paragraph = is.readInt32(9, false, this.paragraph);
};
novel.CommentListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.subjectId);
    os.writeInt32(2, this.orderType);
    os.writeInt32(3, this.offset);
    os.writeInt32(4, this.size);
    os.writeInt32(5, this.commentType);
    os.writeBoolean(6, this.withSubCmts);
    os.writeInt64(7, this.parentId);
    os.writeInt64(8, this.bookId);
    os.writeInt32(9, this.paragraph);
};

novel.ListUserCommentsReq = function() {
    this.tId = new novel.UserId();
    this.subjectId = 0;
    this.commentType = 0;
    this.offset = 0;
    this.size = 0;
};
novel.ListUserCommentsReq.prototype._clone = function () { return new novel.ListUserCommentsReq(); };
novel.ListUserCommentsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserCommentsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserCommentsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectId = is.readInt64(1, false, this.subjectId);
    this.commentType = is.readInt32(2, false, this.commentType);
    this.offset = is.readInt32(3, false, this.offset);
    this.size = is.readInt32(4, false, this.size);
};
novel.ListUserCommentsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.subjectId);
    os.writeInt32(2, this.commentType);
    os.writeInt32(3, this.offset);
    os.writeInt32(4, this.size);
};

novel.CommentListRsp = function() {
    this.data = new Taf.Vector(new novel.Comment());
    this.hasMore = true;
};
novel.CommentListRsp.prototype._clone = function () { return new novel.CommentListRsp(); };
novel.CommentListRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CommentListRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CommentListRsp.prototype.readFrom = function (is) {
    this.data = is.readVector(0, false, this.data);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.CommentListRsp.prototype.writeTo = function (os) {
    os.writeVector(0, this.data);
    os.writeBoolean(1, this.hasMore);
};

novel.SubCommentList = function() {
    this.data = new Taf.Vector(new novel.SubComment());
    this.hasMore = true;
};
novel.SubCommentList.prototype._clone = function () { return new novel.SubCommentList(); };
novel.SubCommentList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SubCommentList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SubCommentList.prototype.readFrom = function (is) {
    this.data = is.readVector(0, false, this.data);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.SubCommentList.prototype.writeTo = function (os) {
    os.writeVector(0, this.data);
    os.writeBoolean(1, this.hasMore);
};

novel.DeleteCommentReq = function() {
    this.tId = new novel.UserId();
    this.id = 0;
    this.subjectId = 0;
};
novel.DeleteCommentReq.prototype._clone = function () { return new novel.DeleteCommentReq(); };
novel.DeleteCommentReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DeleteCommentReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DeleteCommentReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.id = is.readInt64(1, false, this.id);
    this.subjectId = is.readInt64(2, false, this.subjectId);
};
novel.DeleteCommentReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.id);
    os.writeInt64(2, this.subjectId);
};

novel.GetCommentByIdReq = function() {
    this.tId = new novel.UserId();
    this.id = 0;
};
novel.GetCommentByIdReq.prototype._clone = function () { return new novel.GetCommentByIdReq(); };
novel.GetCommentByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetCommentByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetCommentByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.id = is.readInt64(1, false, this.id);
};
novel.GetCommentByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.id);
};

novel.LikeData = function() {
    this.subjectId = 0;
    this.objectId = 0;
    this.likeType = 0;
    this.user = new novel.UserBase();
};
novel.LikeData.prototype._clone = function () { return new novel.LikeData(); };
novel.LikeData.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LikeData.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LikeData.prototype.readFrom = function (is) {
    this.subjectId = is.readInt64(0, false, this.subjectId);
    this.objectId = is.readInt64(1, false, this.objectId);
    this.likeType = is.readInt32(2, false, this.likeType);
    this.user = is.readStruct(3, false, this.user);
};
novel.LikeData.prototype.writeTo = function (os) {
    os.writeInt64(0, this.subjectId);
    os.writeInt64(1, this.objectId);
    os.writeInt32(2, this.likeType);
    os.writeStruct(3, this.user);
};

novel.LikeDataList = function() {
    this.datas = new Taf.Vector(new novel.LikeData());
    this.hasMore = true;
};
novel.LikeDataList.prototype._clone = function () { return new novel.LikeDataList(); };
novel.LikeDataList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LikeDataList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LikeDataList.prototype.readFrom = function (is) {
    this.datas = is.readVector(0, false, this.datas);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.LikeDataList.prototype.writeTo = function (os) {
    os.writeVector(0, this.datas);
    os.writeBoolean(1, this.hasMore);
};

novel.LikeReq = function() {
    this.tId = new novel.UserId();
    this.subjectId = 0;
    this.subjectUid = 0;
    this.objectId = 0;
    this.like = true;
    this.likeType = 0;
};
novel.LikeReq.prototype._clone = function () { return new novel.LikeReq(); };
novel.LikeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LikeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LikeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectId = is.readInt64(1, false, this.subjectId);
    this.subjectUid = is.readInt64(2, false, this.subjectUid);
    this.objectId = is.readInt64(3, false, this.objectId);
    this.like = is.readBoolean(4, false, this.like);
    this.likeType = is.readInt32(5, false, this.likeType);
};
novel.LikeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.subjectId);
    os.writeInt64(2, this.subjectUid);
    os.writeInt64(3, this.objectId);
    os.writeBoolean(4, this.like);
    os.writeInt32(5, this.likeType);
};

novel.HasLikedReq = function() {
    this.tId = new novel.UserId();
    this.subjectIds = new Taf.Vector(new Taf.INT64());
    this.likeType = 0;
};
novel.HasLikedReq.prototype._clone = function () { return new novel.HasLikedReq(); };
novel.HasLikedReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HasLikedReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HasLikedReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectIds = is.readVector(1, false, this.subjectIds);
    this.likeType = is.readInt32(2, false, this.likeType);
};
novel.HasLikedReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.subjectIds);
    os.writeInt32(2, this.likeType);
};

novel.HasLikedRsp = function() {
    this.data = new Taf.Map(new Taf.INT64(), new Taf.BOOLEAN());
};
novel.HasLikedRsp.prototype._clone = function () { return new novel.HasLikedRsp(); };
novel.HasLikedRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HasLikedRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HasLikedRsp.prototype.readFrom = function (is) {
    this.data = is.readMap(0, false, this.data);
};
novel.HasLikedRsp.prototype.writeTo = function (os) {
    os.writeMap(0, this.data);
};

novel.SubjectLikeListReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.SubjectLikeListReq.prototype._clone = function () { return new novel.SubjectLikeListReq(); };
novel.SubjectLikeListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SubjectLikeListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SubjectLikeListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.SubjectLikeListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.GetLatestLikeDataReq = function() {
    this.tId = new novel.UserId();
    this.likeType = 0;
    this.subjectId = 0;
    this.offset = 0;
    this.size = 0;
};
novel.GetLatestLikeDataReq.prototype._clone = function () { return new novel.GetLatestLikeDataReq(); };
novel.GetLatestLikeDataReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetLatestLikeDataReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetLatestLikeDataReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.likeType = is.readInt32(1, false, this.likeType);
    this.subjectId = is.readInt64(2, false, this.subjectId);
    this.offset = is.readInt32(3, false, this.offset);
    this.size = is.readInt32(4, false, this.size);
};
novel.GetLatestLikeDataReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.likeType);
    os.writeInt64(2, this.subjectId);
    os.writeInt32(3, this.offset);
    os.writeInt32(4, this.size);
};

novel.ParagraphCommentsCount = function() {
    this.paragraphCommentsCount = new Taf.Map(new Taf.INT32(), new Taf.INT32());
};
novel.ParagraphCommentsCount.prototype._clone = function () { return new novel.ParagraphCommentsCount(); };
novel.ParagraphCommentsCount.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ParagraphCommentsCount.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ParagraphCommentsCount.prototype.readFrom = function (is) {
    this.paragraphCommentsCount = is.readMap(0, false, this.paragraphCommentsCount);
};
novel.ParagraphCommentsCount.prototype.writeTo = function (os) {
    os.writeMap(0, this.paragraphCommentsCount);
};

novel.CountParagraphCommentsReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.chapterId = 0;
    this.pids = new Taf.Vector(new Taf.INT32());
};
novel.CountParagraphCommentsReq.prototype._clone = function () { return new novel.CountParagraphCommentsReq(); };
novel.CountParagraphCommentsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CountParagraphCommentsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CountParagraphCommentsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.pids = is.readVector(3, false, this.pids);
};
novel.CountParagraphCommentsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt64(2, this.chapterId);
    os.writeVector(3, this.pids);
};

novel.SubscribeReq = function() {
    this.tId = new novel.UserId();
    this.subType = 0;
    this.subAction = true;
    this.tid = 0;
};
novel.SubscribeReq.prototype._clone = function () { return new novel.SubscribeReq(); };
novel.SubscribeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SubscribeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SubscribeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subType = is.readInt32(1, false, this.subType);
    this.subAction = is.readBoolean(2, false, this.subAction);
    this.tid = is.readInt64(3, false, this.tid);
};
novel.SubscribeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.subType);
    os.writeBoolean(2, this.subAction);
    os.writeInt64(3, this.tid);
};

novel.HasFollowReq = function() {
    this.tId = new novel.UserId();
    this.subType = 0;
    this.tids = new Taf.Vector(new Taf.INT64());
};
novel.HasFollowReq.prototype._clone = function () { return new novel.HasFollowReq(); };
novel.HasFollowReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HasFollowReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HasFollowReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subType = is.readInt32(1, false, this.subType);
    this.tids = is.readVector(2, false, this.tids);
};
novel.HasFollowReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.subType);
    os.writeVector(2, this.tids);
};

novel.HasFollowRsp = function() {
    this.data = new Taf.Map(new Taf.INT64(), new Taf.BOOLEAN());
};
novel.HasFollowRsp.prototype._clone = function () { return new novel.HasFollowRsp(); };
novel.HasFollowRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HasFollowRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HasFollowRsp.prototype.readFrom = function (is) {
    this.data = is.readMap(0, false, this.data);
};
novel.HasFollowRsp.prototype.writeTo = function (os) {
    os.writeMap(0, this.data);
};

novel.BannerReq = function() {
    this.tId = new novel.UserId();
    this.showAt = 1;
    this.categoryId = 0;
};
novel.BannerReq.prototype._clone = function () { return new novel.BannerReq(); };
novel.BannerReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BannerReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BannerReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.showAt = is.readInt32(1, false, this.showAt);
    this.categoryId = is.readInt64(2, false, this.categoryId);
};
novel.BannerReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.showAt);
    os.writeInt64(2, this.categoryId);
};

novel.Banner = function() {
    this.title = "";
    this.image = "";
    this.link = "";
    this.book = new novel.Book();
};
novel.Banner.prototype._clone = function () { return new novel.Banner(); };
novel.Banner.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Banner.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Banner.prototype.readFrom = function (is) {
    this.title = is.readString(0, false, this.title);
    this.image = is.readString(1, false, this.image);
    this.link = is.readString(2, false, this.link);
    this.book = is.readStruct(3, false, this.book);
};
novel.Banner.prototype.writeTo = function (os) {
    os.writeString(0, this.title);
    os.writeString(1, this.image);
    os.writeString(2, this.link);
    os.writeStruct(3, this.book);
};

novel.BannerRsp = function() {
    this.data = new Taf.Vector(new novel.Banner());
};
novel.BannerRsp.prototype._clone = function () { return new novel.BannerRsp(); };
novel.BannerRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BannerRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BannerRsp.prototype.readFrom = function (is) {
    this.data = is.readVector(0, false, this.data);
};
novel.BannerRsp.prototype.writeTo = function (os) {
    os.writeVector(0, this.data);
};

novel.GetLatestConfigReq = function() {
    this.tId = new novel.UserId();
    this.configId = 0;
    this.confKey = "";
};
novel.GetLatestConfigReq.prototype._clone = function () { return new novel.GetLatestConfigReq(); };
novel.GetLatestConfigReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetLatestConfigReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetLatestConfigReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.configId = is.readInt32(1, false, this.configId);
    this.confKey = is.readString(2, false, this.confKey);
};
novel.GetLatestConfigReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.configId);
    os.writeString(2, this.confKey);
};

novel.GetLatestConfigRsp = function() {
    this.lastestConfigId = 0;
    this.config = "";
};
novel.GetLatestConfigRsp.prototype._clone = function () { return new novel.GetLatestConfigRsp(); };
novel.GetLatestConfigRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetLatestConfigRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetLatestConfigRsp.prototype.readFrom = function (is) {
    this.lastestConfigId = is.readInt32(0, false, this.lastestConfigId);
    this.config = is.readString(1, false, this.config);
};
novel.GetLatestConfigRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.lastestConfigId);
    os.writeString(1, this.config);
};

novel.FeedbackReq = function() {
    this.tId = new novel.UserId();
    this.type = 0;
    this.subType = 0;
    this.content = "";
    this.imgs = new Taf.Vector(new Taf.STRING());
    this.logs = "";
    this.contract = "";
    this.sign = "";
    this.subjectId = 0;
    this.country = "";
    this.lcid = 0;
};
novel.FeedbackReq.prototype._clone = function () { return new novel.FeedbackReq(); };
novel.FeedbackReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.FeedbackReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.FeedbackReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.type = is.readInt32(1, false, this.type);
    this.subType = is.readInt32(2, false, this.subType);
    this.content = is.readString(3, false, this.content);
    this.imgs = is.readVector(4, false, this.imgs);
    this.logs = is.readString(5, false, this.logs);
    this.contract = is.readString(6, false, this.contract);
    this.sign = is.readString(7, false, this.sign);
    this.subjectId = is.readInt64(8, false, this.subjectId);
    this.country = is.readString(9, false, this.country);
    this.lcid = is.readInt32(10, false, this.lcid);
};
novel.FeedbackReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.type);
    os.writeInt32(2, this.subType);
    os.writeString(3, this.content);
    os.writeVector(4, this.imgs);
    os.writeString(5, this.logs);
    os.writeString(6, this.contract);
    os.writeString(7, this.sign);
    os.writeInt64(8, this.subjectId);
    os.writeString(9, this.country);
    os.writeInt32(10, this.lcid);
};

novel.BookContentFeedbackReq = function() {
    this.tId = new novel.UserId();
    this.subType = 0;
    this.content = "";
    this.imgs = new Taf.Vector(new Taf.STRING());
    this.logs = "";
    this.contract = "";
    this.sign = "";
    this.bookId = 0;
    this.chpaterId = 0;
    this.lines = new Taf.Vector(new Taf.INT64());
};
novel.BookContentFeedbackReq.prototype._clone = function () { return new novel.BookContentFeedbackReq(); };
novel.BookContentFeedbackReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookContentFeedbackReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookContentFeedbackReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subType = is.readInt32(1, false, this.subType);
    this.content = is.readString(2, false, this.content);
    this.imgs = is.readVector(3, false, this.imgs);
    this.logs = is.readString(4, false, this.logs);
    this.contract = is.readString(5, false, this.contract);
    this.sign = is.readString(6, false, this.sign);
    this.bookId = is.readInt64(7, false, this.bookId);
    this.chpaterId = is.readInt64(8, false, this.chpaterId);
    this.lines = is.readVector(9, false, this.lines);
};
novel.BookContentFeedbackReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.subType);
    os.writeString(2, this.content);
    os.writeVector(3, this.imgs);
    os.writeString(4, this.logs);
    os.writeString(5, this.contract);
    os.writeString(6, this.sign);
    os.writeInt64(7, this.bookId);
    os.writeInt64(8, this.chpaterId);
    os.writeVector(9, this.lines);
};

novel.TagListReq = function() {
    this.tId = new novel.UserId();
    this.tagType = 0;
    this.parentTagId = 0;
    this.innerModel = false;
};
novel.TagListReq.prototype._clone = function () { return new novel.TagListReq(); };
novel.TagListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.TagListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.TagListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.tagType = is.readInt32(1, false, this.tagType);
    this.parentTagId = is.readInt32(2, false, this.parentTagId);
    this.innerModel = is.readBoolean(3, false, this.innerModel);
};
novel.TagListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.tagType);
    os.writeInt32(2, this.parentTagId);
    os.writeBoolean(3, this.innerModel);
};

novel.TagListRsp = function() {
    this.tags = new Taf.Vector(new novel.Tag());
};
novel.TagListRsp.prototype._clone = function () { return new novel.TagListRsp(); };
novel.TagListRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.TagListRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.TagListRsp.prototype.readFrom = function (is) {
    this.tags = is.readVector(0, false, this.tags);
};
novel.TagListRsp.prototype.writeTo = function (os) {
    os.writeVector(0, this.tags);
};

novel.RegistryClientFCMTokenReq = function() {
    this.tId = new novel.UserId();
    this.oldToken = "";
    this.newToken = "";
};
novel.RegistryClientFCMTokenReq.prototype._clone = function () { return new novel.RegistryClientFCMTokenReq(); };
novel.RegistryClientFCMTokenReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RegistryClientFCMTokenReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RegistryClientFCMTokenReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.oldToken = is.readString(1, false, this.oldToken);
    this.newToken = is.readString(2, false, this.newToken);
};
novel.RegistryClientFCMTokenReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.oldToken);
    os.writeString(2, this.newToken);
};

novel.GetEndPatchReq = function() {
    this.tId = new novel.UserId();
};
novel.GetEndPatchReq.prototype._clone = function () { return new novel.GetEndPatchReq(); };
novel.GetEndPatchReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetEndPatchReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetEndPatchReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetEndPatchReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.EndPatch = function() {
    this.id = 0;
    this.md5 = "";
    this.url = "";
    this.versionNames = "";
    this.description = "";
    this.endType = 0;
    this.createTime = 0;
};
novel.EndPatch.prototype._clone = function () { return new novel.EndPatch(); };
novel.EndPatch.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.EndPatch.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.EndPatch.prototype.readFrom = function (is) {
    this.id = is.readInt32(0, false, this.id);
    this.md5 = is.readString(1, false, this.md5);
    this.url = is.readString(2, false, this.url);
    this.versionNames = is.readString(3, false, this.versionNames);
    this.description = is.readString(4, false, this.description);
    this.endType = is.readInt32(5, false, this.endType);
    this.createTime = is.readInt64(6, false, this.createTime);
};
novel.EndPatch.prototype.writeTo = function (os) {
    os.writeInt32(0, this.id);
    os.writeString(1, this.md5);
    os.writeString(2, this.url);
    os.writeString(3, this.versionNames);
    os.writeString(4, this.description);
    os.writeInt32(5, this.endType);
    os.writeInt64(6, this.createTime);
};

novel.AddBanListReq = function() {
    this.tId = new novel.UserId();
    this.tid = 0;
    this.type = 0;
};
novel.AddBanListReq.prototype._clone = function () { return new novel.AddBanListReq(); };
novel.AddBanListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AddBanListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AddBanListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.tid = is.readInt64(1, false, this.tid);
    this.type = is.readInt32(2, false, this.type);
};
novel.AddBanListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.tid);
    os.writeInt32(2, this.type);
};

novel.DelBanListReq = function() {
    this.tId = new novel.UserId();
    this.tid = 0;
    this.type = 0;
};
novel.DelBanListReq.prototype._clone = function () { return new novel.DelBanListReq(); };
novel.DelBanListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DelBanListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DelBanListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.tid = is.readInt64(1, false, this.tid);
    this.type = is.readInt32(2, false, this.type);
};
novel.DelBanListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.tid);
    os.writeInt32(2, this.type);
};

novel.BanInfo = function() {
    this.user = new novel.UserBase();
    this.createTime = 0;
    this.type = 0;
};
novel.BanInfo.prototype._clone = function () { return new novel.BanInfo(); };
novel.BanInfo.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BanInfo.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BanInfo.prototype.readFrom = function (is) {
    this.user = is.readStruct(0, false, this.user);
    this.createTime = is.readInt64(1, false, this.createTime);
    this.type = is.readInt32(2, false, this.type);
};
novel.BanInfo.prototype.writeTo = function (os) {
    os.writeStruct(0, this.user);
    os.writeInt64(1, this.createTime);
    os.writeInt32(2, this.type);
};

novel.BanList = function() {
    this.list = new Taf.Vector(new novel.BanInfo());
    this.hasMore = true;
};
novel.BanList.prototype._clone = function () { return new novel.BanList(); };
novel.BanList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BanList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BanList.prototype.readFrom = function (is) {
    this.list = is.readVector(0, false, this.list);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.BanList.prototype.writeTo = function (os) {
    os.writeVector(0, this.list);
    os.writeBoolean(1, this.hasMore);
};

novel.GetBanListReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.type = 0;
};
novel.GetBanListReq.prototype._clone = function () { return new novel.GetBanListReq(); };
novel.GetBanListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetBanListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetBanListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.type = is.readInt32(3, false, this.type);
};
novel.GetBanListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeInt32(3, this.type);
};

novel.HasBanReq = function() {
    this.tId = new novel.UserId();
    this.tids = new Taf.Vector(new Taf.INT64());
    this.type = 0;
};
novel.HasBanReq.prototype._clone = function () { return new novel.HasBanReq(); };
novel.HasBanReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HasBanReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HasBanReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.tids = is.readVector(1, false, this.tids);
    this.type = is.readInt32(2, false, this.type);
};
novel.HasBanReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.tids);
    os.writeInt32(2, this.type);
};

novel.HasBanRsp = function() {
    this.hasBan = new Taf.Map(new Taf.INT64(), new Taf.BOOLEAN());
};
novel.HasBanRsp.prototype._clone = function () { return new novel.HasBanRsp(); };
novel.HasBanRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.HasBanRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.HasBanRsp.prototype.readFrom = function (is) {
    this.hasBan = is.readMap(0, false, this.hasBan);
};
novel.HasBanRsp.prototype.writeTo = function (os) {
    os.writeMap(0, this.hasBan);
};

novel.VivoAttributionData = function() {
    this.cvType = "";
    this.extParam = "";
};
novel.VivoAttributionData.prototype._clone = function () { return new novel.VivoAttributionData(); };
novel.VivoAttributionData.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.VivoAttributionData.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.VivoAttributionData.prototype.readFrom = function (is) {
    this.cvType = is.readString(0, false, this.cvType);
    this.extParam = is.readString(1, false, this.extParam);
};
novel.VivoAttributionData.prototype.writeTo = function (os) {
    os.writeString(0, this.cvType);
    os.writeString(1, this.extParam);
};

novel.VivoAttributionUpladReq = function() {
    this.tId = new novel.UserId();
    this.pkgName = "";
    this.dataList = new Taf.Vector(new novel.VivoAttributionData());
    this.oaid = "";
};
novel.VivoAttributionUpladReq.prototype._clone = function () { return new novel.VivoAttributionUpladReq(); };
novel.VivoAttributionUpladReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.VivoAttributionUpladReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.VivoAttributionUpladReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.pkgName = is.readString(1, false, this.pkgName);
    this.dataList = is.readVector(2, false, this.dataList);
    this.oaid = is.readString(3, false, this.oaid);
};
novel.VivoAttributionUpladReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.pkgName);
    os.writeVector(2, this.dataList);
    os.writeString(3, this.oaid);
};

novel.AppsaDownloadAttributionUploadReq = function() {
    this.tId = new novel.UserId();
    this.token = "";
    this.osVersion = "";
    this.deviceModel = "";
    this.installTime = 0;
    this.deviceId = "";
};
novel.AppsaDownloadAttributionUploadReq.prototype._clone = function () { return new novel.AppsaDownloadAttributionUploadReq(); };
novel.AppsaDownloadAttributionUploadReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppsaDownloadAttributionUploadReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppsaDownloadAttributionUploadReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.token = is.readString(1, false, this.token);
    this.osVersion = is.readString(2, false, this.osVersion);
    this.deviceModel = is.readString(3, false, this.deviceModel);
    this.installTime = is.readInt64(4, false, this.installTime);
    this.deviceId = is.readString(5, false, this.deviceId);
};
novel.AppsaDownloadAttributionUploadReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.token);
    os.writeString(2, this.osVersion);
    os.writeString(3, this.deviceModel);
    os.writeInt64(4, this.installTime);
    os.writeString(5, this.deviceId);
};

novel.AppsaDownloadAttributionUploadRsp = function() {
    this.orgId = 0;
    this.campaignId = 0;
    this.adGroupId = 0;
    this.keywordId = 0;
    this.adId = 0;
    this.countryOrRegion = "";
};
novel.AppsaDownloadAttributionUploadRsp.prototype._clone = function () { return new novel.AppsaDownloadAttributionUploadRsp(); };
novel.AppsaDownloadAttributionUploadRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppsaDownloadAttributionUploadRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppsaDownloadAttributionUploadRsp.prototype.readFrom = function (is) {
    this.orgId = is.readInt64(0, false, this.orgId);
    this.campaignId = is.readInt64(1, false, this.campaignId);
    this.adGroupId = is.readInt64(2, false, this.adGroupId);
    this.keywordId = is.readInt64(3, false, this.keywordId);
    this.adId = is.readInt64(4, false, this.adId);
    this.countryOrRegion = is.readString(5, false, this.countryOrRegion);
};
novel.AppsaDownloadAttributionUploadRsp.prototype.writeTo = function (os) {
    os.writeInt64(0, this.orgId);
    os.writeInt64(1, this.campaignId);
    os.writeInt64(2, this.adGroupId);
    os.writeInt64(3, this.keywordId);
    os.writeInt64(4, this.adId);
    os.writeString(5, this.countryOrRegion);
};

novel.AppsaCustomAttributionUploadReq = function() {
    this.tId = new novel.UserId();
    this.eventName = "";
    this.eventValues = "";
    this.eventDate = "";
    this.eventTime = 0;
    this.deviceId = "";
};
novel.AppsaCustomAttributionUploadReq.prototype._clone = function () { return new novel.AppsaCustomAttributionUploadReq(); };
novel.AppsaCustomAttributionUploadReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppsaCustomAttributionUploadReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppsaCustomAttributionUploadReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.eventName = is.readString(1, false, this.eventName);
    this.eventValues = is.readString(2, false, this.eventValues);
    this.eventDate = is.readString(3, false, this.eventDate);
    this.eventTime = is.readInt64(4, false, this.eventTime);
    this.deviceId = is.readString(5, false, this.deviceId);
};
novel.AppsaCustomAttributionUploadReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.eventName);
    os.writeString(2, this.eventValues);
    os.writeString(3, this.eventDate);
    os.writeInt64(4, this.eventTime);
    os.writeString(5, this.deviceId);
};

novel.RechargePackage = function() {
    this.packageId = 0;
    this.currency = 0;
    this.coinAmount = 0;
    this.extCoinAmount = 0;
    this.desc = "";
    this.appleProdId = "";
    this.googleProdId = "";
    this.cornerWords = "";
    this.recommended = 0;
    this.payerMaxExtCoin = 0;
    this.supportCountryLevels = new Taf.Vector(new Taf.INT32());
    this.type = 0;
    this.extGemAmount = 0;
    this.gemDurationDays = 0;
    this.vipType = 0;
    this.days = 0;
    this.totalGem = 0;
    this.payermaxDiscount = "";
    this.payerMaxExtGem = 0;
    this.noAd = new novel.GoodsSpu();
    this.payermaxBlackCountries = new Taf.Vector(new Taf.STRING());
    this.aipAmount = 0;
    this.aipDurationDays = 0;
};
novel.RechargePackage.prototype._clone = function () { return new novel.RechargePackage(); };
novel.RechargePackage.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RechargePackage.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RechargePackage.prototype.readFrom = function (is) {
    this.packageId = is.readInt32(0, false, this.packageId);
    this.currency = is.readInt32(1, false, this.currency);
    this.coinAmount = is.readInt32(2, false, this.coinAmount);
    this.extCoinAmount = is.readInt32(3, false, this.extCoinAmount);
    this.desc = is.readString(4, false, this.desc);
    this.appleProdId = is.readString(5, false, this.appleProdId);
    this.googleProdId = is.readString(6, false, this.googleProdId);
    this.cornerWords = is.readString(7, false, this.cornerWords);
    this.recommended = is.readInt32(8, false, this.recommended);
    this.payerMaxExtCoin = is.readInt32(9, false, this.payerMaxExtCoin);
    this.supportCountryLevels = is.readVector(10, false, this.supportCountryLevels);
    this.type = is.readInt32(11, false, this.type);
    this.extGemAmount = is.readInt32(12, false, this.extGemAmount);
    this.gemDurationDays = is.readInt64(13, false, this.gemDurationDays);
    this.vipType = is.readInt32(14, false, this.vipType);
    this.days = is.readInt32(15, false, this.days);
    this.totalGem = is.readInt32(16, false, this.totalGem);
    this.payermaxDiscount = is.readString(17, false, this.payermaxDiscount);
    this.payerMaxExtGem = is.readInt32(18, false, this.payerMaxExtGem);
    this.noAd = is.readStruct(19, false, this.noAd);
    this.payermaxBlackCountries = is.readVector(20, false, this.payermaxBlackCountries);
    this.aipAmount = is.readInt32(21, false, this.aipAmount);
    this.aipDurationDays = is.readInt64(22, false, this.aipDurationDays);
};
novel.RechargePackage.prototype.writeTo = function (os) {
    os.writeInt32(0, this.packageId);
    os.writeInt32(1, this.currency);
    os.writeInt32(2, this.coinAmount);
    os.writeInt32(3, this.extCoinAmount);
    os.writeString(4, this.desc);
    os.writeString(5, this.appleProdId);
    os.writeString(6, this.googleProdId);
    os.writeString(7, this.cornerWords);
    os.writeInt32(8, this.recommended);
    os.writeInt32(9, this.payerMaxExtCoin);
    os.writeVector(10, this.supportCountryLevels);
    os.writeInt32(11, this.type);
    os.writeInt32(12, this.extGemAmount);
    os.writeInt64(13, this.gemDurationDays);
    os.writeInt32(14, this.vipType);
    os.writeInt32(15, this.days);
    os.writeInt32(16, this.totalGem);
    os.writeString(17, this.payermaxDiscount);
    os.writeInt32(18, this.payerMaxExtGem);
    os.writeStruct(19, this.noAd);
    os.writeVector(20, this.payermaxBlackCountries);
    os.writeInt32(21, this.aipAmount);
    os.writeInt64(22, this.aipDurationDays);
};

novel.RechargePackageRsp = function() {
    this.data = new Taf.Vector(new novel.RechargePackage());
};
novel.RechargePackageRsp.prototype._clone = function () { return new novel.RechargePackageRsp(); };
novel.RechargePackageRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RechargePackageRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RechargePackageRsp.prototype.readFrom = function (is) {
    this.data = is.readVector(0, false, this.data);
};
novel.RechargePackageRsp.prototype.writeTo = function (os) {
    os.writeVector(0, this.data);
};

novel.PayParameters = function() {
    this.payerMaxTargetOrg = "";
    this.app = 0;
};
novel.PayParameters.prototype._clone = function () { return new novel.PayParameters(); };
novel.PayParameters.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PayParameters.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PayParameters.prototype.readFrom = function (is) {
    this.payerMaxTargetOrg = is.readString(0, false, this.payerMaxTargetOrg);
    this.app = is.readInt32(1, false, this.app);
};
novel.PayParameters.prototype.writeTo = function (os) {
    os.writeString(0, this.payerMaxTargetOrg);
    os.writeInt32(1, this.app);
};

novel.GenPayOrderReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.payChannel = 0;
    this.packageId = 0;
    this.params = new novel.PayParameters();
};
novel.GenPayOrderReq.prototype._clone = function () { return new novel.GenPayOrderReq(); };
novel.GenPayOrderReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GenPayOrderReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GenPayOrderReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.payChannel = is.readInt32(2, false, this.payChannel);
    this.packageId = is.readInt32(3, false, this.packageId);
    this.params = is.readStruct(4, false, this.params);
};
novel.GenPayOrderReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeInt32(2, this.payChannel);
    os.writeInt32(3, this.packageId);
    os.writeStruct(4, this.params);
};

novel.GenVipOrderReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.payChannel = 0;
    this.packageId = 0;
    this.params = new novel.PayParameters();
};
novel.GenVipOrderReq.prototype._clone = function () { return new novel.GenVipOrderReq(); };
novel.GenVipOrderReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GenVipOrderReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GenVipOrderReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.payChannel = is.readInt32(2, false, this.payChannel);
    this.packageId = is.readInt32(3, false, this.packageId);
    this.params = is.readStruct(4, false, this.params);
};
novel.GenVipOrderReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeInt32(2, this.payChannel);
    os.writeInt32(3, this.packageId);
    os.writeStruct(4, this.params);
};

novel.OutPayParams = function() {
    this.nonce = "";
    this.timestamp = "";
    this.sign = "";
    this.alipayOrderInfo = "";
    this.payermaxRedirectUrl = "";
};
novel.OutPayParams.prototype._clone = function () { return new novel.OutPayParams(); };
novel.OutPayParams.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OutPayParams.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OutPayParams.prototype.readFrom = function (is) {
    this.nonce = is.readString(0, false, this.nonce);
    this.timestamp = is.readString(1, false, this.timestamp);
    this.sign = is.readString(2, false, this.sign);
    this.alipayOrderInfo = is.readString(3, false, this.alipayOrderInfo);
    this.payermaxRedirectUrl = is.readString(4, false, this.payermaxRedirectUrl);
};
novel.OutPayParams.prototype.writeTo = function (os) {
    os.writeString(0, this.nonce);
    os.writeString(1, this.timestamp);
    os.writeString(2, this.sign);
    os.writeString(3, this.alipayOrderInfo);
    os.writeString(4, this.payermaxRedirectUrl);
};

novel.PayOrder = function() {
    this.payChannel = 0;
    this.pkg = new novel.RechargePackage();
    this.mcOrderId = "";
    this.outOrderId = "";
    this.sdkParams = new novel.OutPayParams();
    this.status = 0;
};
novel.PayOrder.prototype._clone = function () { return new novel.PayOrder(); };
novel.PayOrder.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PayOrder.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PayOrder.prototype.readFrom = function (is) {
    this.payChannel = is.readInt32(0, false, this.payChannel);
    this.pkg = is.readStruct(1, false, this.pkg);
    this.mcOrderId = is.readString(2, false, this.mcOrderId);
    this.outOrderId = is.readString(3, false, this.outOrderId);
    this.sdkParams = is.readStruct(4, false, this.sdkParams);
    this.status = is.readInt32(5, false, this.status);
};
novel.PayOrder.prototype.writeTo = function (os) {
    os.writeInt32(0, this.payChannel);
    os.writeStruct(1, this.pkg);
    os.writeString(2, this.mcOrderId);
    os.writeString(3, this.outOrderId);
    os.writeStruct(4, this.sdkParams);
    os.writeInt32(5, this.status);
};

novel.MemberOrder = function() {
    this.payChannel = 0;
    this.pkg = new novel.MemberProduct();
    this.mcOrderId = "";
    this.outOrderId = "";
    this.sdkParams = new novel.OutPayParams();
    this.status = 0;
    this.prePayId = "";
    this.contractId = "";
};
novel.MemberOrder.prototype._clone = function () { return new novel.MemberOrder(); };
novel.MemberOrder.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MemberOrder.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MemberOrder.prototype.readFrom = function (is) {
    this.payChannel = is.readInt32(0, false, this.payChannel);
    this.pkg = is.readStruct(1, false, this.pkg);
    this.mcOrderId = is.readString(2, false, this.mcOrderId);
    this.outOrderId = is.readString(3, false, this.outOrderId);
    this.sdkParams = is.readStruct(4, false, this.sdkParams);
    this.status = is.readInt32(5, false, this.status);
    this.prePayId = is.readString(6, false, this.prePayId);
    this.contractId = is.readString(7, false, this.contractId);
};
novel.MemberOrder.prototype.writeTo = function (os) {
    os.writeInt32(0, this.payChannel);
    os.writeStruct(1, this.pkg);
    os.writeString(2, this.mcOrderId);
    os.writeString(3, this.outOrderId);
    os.writeStruct(4, this.sdkParams);
    os.writeInt32(5, this.status);
    os.writeString(6, this.prePayId);
    os.writeString(7, this.contractId);
};

novel.GenPayOrderRsp = function() {
    this.newOrder = new novel.PayOrder();
    this.waitingPayOrder = new novel.PayOrder();
};
novel.GenPayOrderRsp.prototype._clone = function () { return new novel.GenPayOrderRsp(); };
novel.GenPayOrderRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GenPayOrderRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GenPayOrderRsp.prototype.readFrom = function (is) {
    this.newOrder = is.readStruct(0, false, this.newOrder);
    this.waitingPayOrder = is.readStruct(1, false, this.waitingPayOrder);
};
novel.GenPayOrderRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.newOrder);
    os.writeStruct(1, this.waitingPayOrder);
};

novel.GenVipOrderRsp = function() {
    this.newOrder = new novel.PayOrder();
};
novel.GenVipOrderRsp.prototype._clone = function () { return new novel.GenVipOrderRsp(); };
novel.GenVipOrderRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GenVipOrderRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GenVipOrderRsp.prototype.readFrom = function (is) {
    this.newOrder = is.readStruct(0, false, this.newOrder);
};
novel.GenVipOrderRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.newOrder);
};

novel.QueryPayOrderReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.mcOrderId = "";
};
novel.QueryPayOrderReq.prototype._clone = function () { return new novel.QueryPayOrderReq(); };
novel.QueryPayOrderReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryPayOrderReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryPayOrderReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.mcOrderId = is.readString(2, false, this.mcOrderId);
};
novel.QueryPayOrderReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeString(2, this.mcOrderId);
};

novel.QueryPayOrderRsp = function() {
    this.order = new novel.PayOrder();
    this.memberOrder = new novel.MemberOrder();
};
novel.QueryPayOrderRsp.prototype._clone = function () { return new novel.QueryPayOrderRsp(); };
novel.QueryPayOrderRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryPayOrderRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryPayOrderRsp.prototype.readFrom = function (is) {
    this.order = is.readStruct(0, false, this.order);
    this.memberOrder = is.readStruct(1, false, this.memberOrder);
};
novel.QueryPayOrderRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.order);
    os.writeStruct(1, this.memberOrder);
};

novel.CancelPayOrderReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.mcOrderId = "";
};
novel.CancelPayOrderReq.prototype._clone = function () { return new novel.CancelPayOrderReq(); };
novel.CancelPayOrderReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CancelPayOrderReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CancelPayOrderReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.mcOrderId = is.readString(2, false, this.mcOrderId);
};
novel.CancelPayOrderReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeString(2, this.mcOrderId);
};

novel.IAPReceiptVerifyReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.mcOrderId = "";
    this.receipt = "";
    this.transactionId = "";
};
novel.IAPReceiptVerifyReq.prototype._clone = function () { return new novel.IAPReceiptVerifyReq(); };
novel.IAPReceiptVerifyReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.IAPReceiptVerifyReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.IAPReceiptVerifyReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.mcOrderId = is.readString(2, false, this.mcOrderId);
    this.receipt = is.readString(3, false, this.receipt);
    this.transactionId = is.readString(4, false, this.transactionId);
};
novel.IAPReceiptVerifyReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeString(2, this.mcOrderId);
    os.writeString(3, this.receipt);
    os.writeString(4, this.transactionId);
};

novel.GooglePayPurchasesVerifyReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.mcOrderId = "";
    this.token = "";
};
novel.GooglePayPurchasesVerifyReq.prototype._clone = function () { return new novel.GooglePayPurchasesVerifyReq(); };
novel.GooglePayPurchasesVerifyReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GooglePayPurchasesVerifyReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GooglePayPurchasesVerifyReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.mcOrderId = is.readString(2, false, this.mcOrderId);
    this.token = is.readString(3, false, this.token);
};
novel.GooglePayPurchasesVerifyReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeString(2, this.mcOrderId);
    os.writeString(3, this.token);
};

novel.BalanceRsp = function() {
    this.balance = new Taf.Map(new Taf.INT32(), new Taf.INT64());
};
novel.BalanceRsp.prototype._clone = function () { return new novel.BalanceRsp(); };
novel.BalanceRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BalanceRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BalanceRsp.prototype.readFrom = function (is) {
    this.balance = is.readMap(0, false, this.balance);
};
novel.BalanceRsp.prototype.writeTo = function (os) {
    os.writeMap(0, this.balance);
};

novel.BalanceReq = function() {
    this.tId = new novel.UserId();
};
novel.BalanceReq.prototype._clone = function () { return new novel.BalanceReq(); };
novel.BalanceReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BalanceReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BalanceReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.BalanceReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.GenMemberPayOrderReq = function() {
    this.tId = new novel.UserId();
    this.sign = "";
    this.payChannel = 0;
    this.memberProductId = "";
};
novel.GenMemberPayOrderReq.prototype._clone = function () { return new novel.GenMemberPayOrderReq(); };
novel.GenMemberPayOrderReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GenMemberPayOrderReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GenMemberPayOrderReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.sign = is.readString(1, false, this.sign);
    this.payChannel = is.readInt32(2, false, this.payChannel);
    this.memberProductId = is.readString(3, false, this.memberProductId);
};
novel.GenMemberPayOrderReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.sign);
    os.writeInt32(2, this.payChannel);
    os.writeString(3, this.memberProductId);
};

novel.GenMemberPayOrderRsp = function() {
    this.newOrder = new novel.MemberOrder();
    this.waitingOrder = new novel.MemberOrder();
};
novel.GenMemberPayOrderRsp.prototype._clone = function () { return new novel.GenMemberPayOrderRsp(); };
novel.GenMemberPayOrderRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GenMemberPayOrderRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GenMemberPayOrderRsp.prototype.readFrom = function (is) {
    this.newOrder = is.readStruct(0, false, this.newOrder);
    this.waitingOrder = is.readStruct(1, false, this.waitingOrder);
};
novel.GenMemberPayOrderRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.newOrder);
    os.writeStruct(1, this.waitingOrder);
};

novel.GetMemberProductsReq = function() {
    this.tId = new novel.UserId();
};
novel.GetMemberProductsReq.prototype._clone = function () { return new novel.GetMemberProductsReq(); };
novel.GetMemberProductsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetMemberProductsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetMemberProductsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetMemberProductsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.MemberProductList = function() {
    this.products = new Taf.Vector(new novel.MemberProduct());
};
novel.MemberProductList.prototype._clone = function () { return new novel.MemberProductList(); };
novel.MemberProductList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MemberProductList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MemberProductList.prototype.readFrom = function (is) {
    this.products = is.readVector(0, false, this.products);
};
novel.MemberProductList.prototype.writeTo = function (os) {
    os.writeVector(0, this.products);
};

novel.PayWithPayerMaxReq = function() {
    this.tId = new novel.UserId();
    this.mcOrderId = "";
    this.payMethod = "";
};
novel.PayWithPayerMaxReq.prototype._clone = function () { return new novel.PayWithPayerMaxReq(); };
novel.PayWithPayerMaxReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PayWithPayerMaxReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PayWithPayerMaxReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.mcOrderId = is.readString(1, false, this.mcOrderId);
    this.payMethod = is.readString(2, false, this.payMethod);
};
novel.PayWithPayerMaxReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.mcOrderId);
    os.writeString(2, this.payMethod);
};

novel.PayWithPayerMaxRsp = function() {
    this.mcOrderId = "";
    this.redirectUrl = "";
    this.status = "";
};
novel.PayWithPayerMaxRsp.prototype._clone = function () { return new novel.PayWithPayerMaxRsp(); };
novel.PayWithPayerMaxRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PayWithPayerMaxRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PayWithPayerMaxRsp.prototype.readFrom = function (is) {
    this.mcOrderId = is.readString(0, false, this.mcOrderId);
    this.redirectUrl = is.readString(1, false, this.redirectUrl);
    this.status = is.readString(2, false, this.status);
};
novel.PayWithPayerMaxRsp.prototype.writeTo = function (os) {
    os.writeString(0, this.mcOrderId);
    os.writeString(1, this.redirectUrl);
    os.writeString(2, this.status);
};

novel.ShowPayerMaxReq = function() {
    this.tId = new novel.UserId();
    this.withoutRecharge = 0;
};
novel.ShowPayerMaxReq.prototype._clone = function () { return new novel.ShowPayerMaxReq(); };
novel.ShowPayerMaxReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ShowPayerMaxReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ShowPayerMaxReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.withoutRecharge = is.readInt32(1, false, this.withoutRecharge);
};
novel.ShowPayerMaxReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.withoutRecharge);
};

novel.ShowPayerMaxRsp = function() {
    this.show = true;
};
novel.ShowPayerMaxRsp.prototype._clone = function () { return new novel.ShowPayerMaxRsp(); };
novel.ShowPayerMaxRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ShowPayerMaxRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ShowPayerMaxRsp.prototype.readFrom = function (is) {
    this.show = is.readBoolean(0, false, this.show);
};
novel.ShowPayerMaxRsp.prototype.writeTo = function (os) {
    os.writeBoolean(0, this.show);
};

novel.ListUserGemBillReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserGemBillReq.prototype._clone = function () { return new novel.ListUserGemBillReq(); };
novel.ListUserGemBillReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserGemBillReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserGemBillReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserGemBillReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.GetLast24HoursExpireGemsReq = function() {
    this.tId = new novel.UserId();
};
novel.GetLast24HoursExpireGemsReq.prototype._clone = function () { return new novel.GetLast24HoursExpireGemsReq(); };
novel.GetLast24HoursExpireGemsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetLast24HoursExpireGemsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetLast24HoursExpireGemsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetLast24HoursExpireGemsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.Last24HoursExpireGemsRsp = function() {
    this.amount = 0;
};
novel.Last24HoursExpireGemsRsp.prototype._clone = function () { return new novel.Last24HoursExpireGemsRsp(); };
novel.Last24HoursExpireGemsRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Last24HoursExpireGemsRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Last24HoursExpireGemsRsp.prototype.readFrom = function (is) {
    this.amount = is.readInt32(0, false, this.amount);
};
novel.Last24HoursExpireGemsRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.amount);
};

novel.ListCoinRechargeFlowReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.coinType = 0;
};
novel.ListCoinRechargeFlowReq.prototype._clone = function () { return new novel.ListCoinRechargeFlowReq(); };
novel.ListCoinRechargeFlowReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListCoinRechargeFlowReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListCoinRechargeFlowReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.coinType = is.readInt32(3, false, this.coinType);
};
novel.ListCoinRechargeFlowReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeInt32(3, this.coinType);
};

novel.QueryUserMaxRechargePayOrderReq = function() {
    this.tId = new novel.UserId();
};
novel.QueryUserMaxRechargePayOrderReq.prototype._clone = function () { return new novel.QueryUserMaxRechargePayOrderReq(); };
novel.QueryUserMaxRechargePayOrderReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryUserMaxRechargePayOrderReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryUserMaxRechargePayOrderReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.QueryUserMaxRechargePayOrderReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListUserAipBillReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserAipBillReq.prototype._clone = function () { return new novel.ListUserAipBillReq(); };
novel.ListUserAipBillReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserAipBillReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserAipBillReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserAipBillReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.GetLast24HoursExpireAipsReq = function() {
    this.tId = new novel.UserId();
};
novel.GetLast24HoursExpireAipsReq.prototype._clone = function () { return new novel.GetLast24HoursExpireAipsReq(); };
novel.GetLast24HoursExpireAipsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetLast24HoursExpireAipsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetLast24HoursExpireAipsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetLast24HoursExpireAipsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.Last24HoursExpireAipsRsp = function() {
    this.amount = 0;
};
novel.Last24HoursExpireAipsRsp.prototype._clone = function () { return new novel.Last24HoursExpireAipsRsp(); };
novel.Last24HoursExpireAipsRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Last24HoursExpireAipsRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Last24HoursExpireAipsRsp.prototype.readFrom = function (is) {
    this.amount = is.readInt32(0, false, this.amount);
};
novel.Last24HoursExpireAipsRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.amount);
};

novel.CountPersonalUnreadMessageReq = function() {
    this.tId = new novel.UserId();
};
novel.CountPersonalUnreadMessageReq.prototype._clone = function () { return new novel.CountPersonalUnreadMessageReq(); };
novel.CountPersonalUnreadMessageReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CountPersonalUnreadMessageReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CountPersonalUnreadMessageReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.CountPersonalUnreadMessageReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.PersonalUnreadMessageCount = function() {
    this.countMap = new Taf.Map(new Taf.INT32(), new Taf.INT64());
};
novel.PersonalUnreadMessageCount.prototype._clone = function () { return new novel.PersonalUnreadMessageCount(); };
novel.PersonalUnreadMessageCount.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PersonalUnreadMessageCount.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PersonalUnreadMessageCount.prototype.readFrom = function (is) {
    this.countMap = is.readMap(0, false, this.countMap);
};
novel.PersonalUnreadMessageCount.prototype.writeTo = function (os) {
    os.writeMap(0, this.countMap);
};

novel.GetUnreadOfficialMessageCountReq = function() {
    this.tId = new novel.UserId();
};
novel.GetUnreadOfficialMessageCountReq.prototype._clone = function () { return new novel.GetUnreadOfficialMessageCountReq(); };
novel.GetUnreadOfficialMessageCountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUnreadOfficialMessageCountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUnreadOfficialMessageCountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetUnreadOfficialMessageCountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.UnreadMessageCount = function() {
    this.unreadCount = new Taf.Map(new Taf.INT32(), new Taf.INT32());
};
novel.UnreadMessageCount.prototype._clone = function () { return new novel.UnreadMessageCount(); };
novel.UnreadMessageCount.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UnreadMessageCount.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UnreadMessageCount.prototype.readFrom = function (is) {
    this.unreadCount = is.readMap(0, false, this.unreadCount);
};
novel.UnreadMessageCount.prototype.writeTo = function (os) {
    os.writeMap(0, this.unreadCount);
};

novel.OfficialMessage = function() {
    this.id = "";
    this.OfficialUser = new novel.UserBase();
    this.type = 0;
    this.subType = 0;
    this.title = "";
    this.content = "";
    this.redirectType = novel.ERedirectType.REDIRECT;
    this.redirectUrl = "";
    this.iconUrl = "";
    this.buttonText = "";
    this.publishTime = 0;
    this.expireTime = 0;
    this.hasRead = true;
    this.extra = "";
};
novel.OfficialMessage.prototype._clone = function () { return new novel.OfficialMessage(); };
novel.OfficialMessage.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OfficialMessage.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OfficialMessage.prototype.readFrom = function (is) {
    this.id = is.readString(0, false, this.id);
    this.OfficialUser = is.readStruct(1, false, this.OfficialUser);
    this.type = is.readInt32(2, false, this.type);
    this.subType = is.readInt32(3, false, this.subType);
    this.title = is.readString(4, false, this.title);
    this.content = is.readString(5, false, this.content);
    this.redirectType = is.readInt32(6, false, this.redirectType);
    this.redirectUrl = is.readString(7, false, this.redirectUrl);
    this.iconUrl = is.readString(8, false, this.iconUrl);
    this.buttonText = is.readString(9, false, this.buttonText);
    this.publishTime = is.readInt64(10, false, this.publishTime);
    this.expireTime = is.readInt64(11, false, this.expireTime);
    this.hasRead = is.readBoolean(12, false, this.hasRead);
    this.extra = is.readString(13, false, this.extra);
};
novel.OfficialMessage.prototype.writeTo = function (os) {
    os.writeString(0, this.id);
    os.writeStruct(1, this.OfficialUser);
    os.writeInt32(2, this.type);
    os.writeInt32(3, this.subType);
    os.writeString(4, this.title);
    os.writeString(5, this.content);
    os.writeInt32(6, this.redirectType);
    os.writeString(7, this.redirectUrl);
    os.writeString(8, this.iconUrl);
    os.writeString(9, this.buttonText);
    os.writeInt64(10, this.publishTime);
    os.writeInt64(11, this.expireTime);
    os.writeBoolean(12, this.hasRead);
    os.writeString(13, this.extra);
};

novel.GetOfficialMessageReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.GetOfficialMessageReq.prototype._clone = function () { return new novel.GetOfficialMessageReq(); };
novel.GetOfficialMessageReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetOfficialMessageReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetOfficialMessageReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.GetOfficialMessageReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.OfficialMessageList = function() {
    this.messages = new Taf.Vector(new novel.OfficialMessage());
    this.hasMore = true;
};
novel.OfficialMessageList.prototype._clone = function () { return new novel.OfficialMessageList(); };
novel.OfficialMessageList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OfficialMessageList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OfficialMessageList.prototype.readFrom = function (is) {
    this.messages = is.readVector(0, false, this.messages);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.OfficialMessageList.prototype.writeTo = function (os) {
    os.writeVector(0, this.messages);
    os.writeBoolean(1, this.hasMore);
};

novel.MarkMessageHasReadReq = function() {
    this.tId = new novel.UserId();
    this.msgIds = new Taf.Vector(new Taf.INT64());
};
novel.MarkMessageHasReadReq.prototype._clone = function () { return new novel.MarkMessageHasReadReq(); };
novel.MarkMessageHasReadReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MarkMessageHasReadReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MarkMessageHasReadReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.msgIds = is.readVector(1, false, this.msgIds);
};
novel.MarkMessageHasReadReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.msgIds);
};

novel.CountUnreadMessageReq = function() {
    this.tId = new novel.UserId();
    this.types = new Taf.Vector(new Taf.INT32());
};
novel.CountUnreadMessageReq.prototype._clone = function () { return new novel.CountUnreadMessageReq(); };
novel.CountUnreadMessageReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CountUnreadMessageReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CountUnreadMessageReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.types = is.readVector(1, false, this.types);
};
novel.CountUnreadMessageReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.types);
};

novel.CountUnreadPersonalMessageReq = function() {
    this.tId = new novel.UserId();
    this.types = new Taf.Vector(new Taf.INT32());
};
novel.CountUnreadPersonalMessageReq.prototype._clone = function () { return new novel.CountUnreadPersonalMessageReq(); };
novel.CountUnreadPersonalMessageReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CountUnreadPersonalMessageReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CountUnreadPersonalMessageReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.types = is.readVector(1, false, this.types);
};
novel.CountUnreadPersonalMessageReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.types);
};

novel.GetPersonalMessageByTypeReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.GetPersonalMessageByTypeReq.prototype._clone = function () { return new novel.GetPersonalMessageByTypeReq(); };
novel.GetPersonalMessageByTypeReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetPersonalMessageByTypeReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetPersonalMessageByTypeReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.GetPersonalMessageByTypeReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.CommentMessage = function() {
    this.id = 0;
    this.subType = 0;
    this.comment = new novel.Comment();
    this.toComment = new novel.Comment();
    this.createTime = 0;
};
novel.CommentMessage.prototype._clone = function () { return new novel.CommentMessage(); };
novel.CommentMessage.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CommentMessage.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CommentMessage.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.subType = is.readInt32(1, false, this.subType);
    this.comment = is.readStruct(2, false, this.comment);
    this.toComment = is.readStruct(3, false, this.toComment);
    this.createTime = is.readInt64(4, false, this.createTime);
};
novel.CommentMessage.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt32(1, this.subType);
    os.writeStruct(2, this.comment);
    os.writeStruct(3, this.toComment);
    os.writeInt64(4, this.createTime);
};

novel.CommentMessageList = function() {
    this.messages = new Taf.Vector(new novel.CommentMessage());
    this.hasMore = true;
    this.lastPullTime = 0;
};
novel.CommentMessageList.prototype._clone = function () { return new novel.CommentMessageList(); };
novel.CommentMessageList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CommentMessageList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CommentMessageList.prototype.readFrom = function (is) {
    this.messages = is.readVector(0, false, this.messages);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.lastPullTime = is.readInt64(2, false, this.lastPullTime);
};
novel.CommentMessageList.prototype.writeTo = function (os) {
    os.writeVector(0, this.messages);
    os.writeBoolean(1, this.hasMore);
    os.writeInt64(2, this.lastPullTime);
};

novel.AtMessage = function() {
    this.id = 0;
};
novel.AtMessage.prototype._clone = function () { return new novel.AtMessage(); };
novel.AtMessage.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AtMessage.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AtMessage.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
};
novel.AtMessage.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
};

novel.AtMessageList = function() {
    this.messages = new Taf.Vector(new novel.AtMessage());
    this.hasMore = true;
    this.lastPullTime = 0;
};
novel.AtMessageList.prototype._clone = function () { return new novel.AtMessageList(); };
novel.AtMessageList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AtMessageList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AtMessageList.prototype.readFrom = function (is) {
    this.messages = is.readVector(0, false, this.messages);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.lastPullTime = is.readInt64(2, false, this.lastPullTime);
};
novel.AtMessageList.prototype.writeTo = function (os) {
    os.writeVector(0, this.messages);
    os.writeBoolean(1, this.hasMore);
    os.writeInt64(2, this.lastPullTime);
};

novel.LikeMessage = function() {
    this.id = 0;
    this.subType = novel.ELikeType.BOOK_COMMENT;
    this.userBase = new novel.UserBase();
    this.createTime = 0;
    this.comment = new novel.Comment();
};
novel.LikeMessage.prototype._clone = function () { return new novel.LikeMessage(); };
novel.LikeMessage.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LikeMessage.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LikeMessage.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.subType = is.readInt32(1, false, this.subType);
    this.userBase = is.readStruct(2, false, this.userBase);
    this.createTime = is.readInt64(3, false, this.createTime);
    this.comment = is.readStruct(4, false, this.comment);
};
novel.LikeMessage.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt32(1, this.subType);
    os.writeStruct(2, this.userBase);
    os.writeInt64(3, this.createTime);
    os.writeStruct(4, this.comment);
};

novel.LikeMessageList = function() {
    this.messages = new Taf.Vector(new novel.LikeMessage());
    this.hasMore = true;
    this.lastPullTime = 0;
};
novel.LikeMessageList.prototype._clone = function () { return new novel.LikeMessageList(); };
novel.LikeMessageList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LikeMessageList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LikeMessageList.prototype.readFrom = function (is) {
    this.messages = is.readVector(0, false, this.messages);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.lastPullTime = is.readInt64(2, false, this.lastPullTime);
};
novel.LikeMessageList.prototype.writeTo = function (os) {
    os.writeVector(0, this.messages);
    os.writeBoolean(1, this.hasMore);
    os.writeInt64(2, this.lastPullTime);
};

novel.QueryFriendApplyMessageByFromUidReq = function() {
    this.tId = new novel.UserId();
    this.toUid = 0;
};
novel.QueryFriendApplyMessageByFromUidReq.prototype._clone = function () { return new novel.QueryFriendApplyMessageByFromUidReq(); };
novel.QueryFriendApplyMessageByFromUidReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryFriendApplyMessageByFromUidReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryFriendApplyMessageByFromUidReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.toUid = is.readInt64(1, false, this.toUid);
};
novel.QueryFriendApplyMessageByFromUidReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.toUid);
};

novel.RelationMessage = function() {
    this.id = 0;
};
novel.RelationMessage.prototype._clone = function () { return new novel.RelationMessage(); };
novel.RelationMessage.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RelationMessage.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RelationMessage.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
};
novel.RelationMessage.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
};

novel.RelationMessageList = function() {
    this.messages = new Taf.Vector(new novel.RelationMessage());
    this.hasMore = true;
    this.lastPullTime = 0;
};
novel.RelationMessageList.prototype._clone = function () { return new novel.RelationMessageList(); };
novel.RelationMessageList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RelationMessageList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RelationMessageList.prototype.readFrom = function (is) {
    this.messages = is.readVector(0, false, this.messages);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.lastPullTime = is.readInt64(2, false, this.lastPullTime);
};
novel.RelationMessageList.prototype.writeTo = function (os) {
    os.writeVector(0, this.messages);
    os.writeBoolean(1, this.hasMore);
    os.writeInt64(2, this.lastPullTime);
};

novel.BagGoods = function() {
    this.id = 0;
    this.uid = 0;
    this.goods = new novel.GoodsSpu();
    this.quantity = 0;
};
novel.BagGoods.prototype._clone = function () { return new novel.BagGoods(); };
novel.BagGoods.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BagGoods.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BagGoods.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.uid = is.readInt64(1, false, this.uid);
    this.goods = is.readStruct(2, false, this.goods);
    this.quantity = is.readInt32(3, false, this.quantity);
};
novel.BagGoods.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt64(1, this.uid);
    os.writeStruct(2, this.goods);
    os.writeInt32(3, this.quantity);
};

novel.MallOrderItem = function() {
    this.sku = new novel.GoodsSku();
    this.quantity = 0;
};
novel.MallOrderItem.prototype._clone = function () { return new novel.MallOrderItem(); };
novel.MallOrderItem.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MallOrderItem.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MallOrderItem.prototype.readFrom = function (is) {
    this.sku = is.readStruct(0, false, this.sku);
    this.quantity = is.readInt32(1, false, this.quantity);
};
novel.MallOrderItem.prototype.writeTo = function (os) {
    os.writeStruct(0, this.sku);
    os.writeInt32(1, this.quantity);
};

novel.MallOrder = function() {
    this.orderId = "";
    this.state = 0;
    this.amount = 0;
    this.currency = 0;
    this.createTime = 0;
    this.description = "";
    this.sortType = 0;
    this.items = new Taf.Vector(new novel.MallOrderItem());
};
novel.MallOrder.prototype._clone = function () { return new novel.MallOrder(); };
novel.MallOrder.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MallOrder.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MallOrder.prototype.readFrom = function (is) {
    this.orderId = is.readString(0, false, this.orderId);
    this.state = is.readInt32(1, false, this.state);
    this.amount = is.readInt32(2, false, this.amount);
    this.currency = is.readInt32(3, false, this.currency);
    this.createTime = is.readInt64(4, false, this.createTime);
    this.description = is.readString(5, false, this.description);
    this.sortType = is.readInt32(6, false, this.sortType);
    this.items = is.readVector(7, false, this.items);
};
novel.MallOrder.prototype.writeTo = function (os) {
    os.writeString(0, this.orderId);
    os.writeInt32(1, this.state);
    os.writeInt32(2, this.amount);
    os.writeInt32(3, this.currency);
    os.writeInt64(4, this.createTime);
    os.writeString(5, this.description);
    os.writeInt32(6, this.sortType);
    os.writeVector(7, this.items);
};

novel.ListSkuByCategoryReq = function() {
    this.tId = new novel.UserId();
    this.category = 0;
    this.subCategory = 0;
    this.offset = 0;
    this.size = 0;
};
novel.ListSkuByCategoryReq.prototype._clone = function () { return new novel.ListSkuByCategoryReq(); };
novel.ListSkuByCategoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListSkuByCategoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListSkuByCategoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.category = is.readInt32(1, false, this.category);
    this.subCategory = is.readInt32(2, false, this.subCategory);
    this.offset = is.readInt32(3, false, this.offset);
    this.size = is.readInt32(4, false, this.size);
};
novel.ListSkuByCategoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.category);
    os.writeInt32(2, this.subCategory);
    os.writeInt32(3, this.offset);
    os.writeInt32(4, this.size);
};

novel.GoodsSkuList = function() {
    this.skus = new Taf.Vector(new novel.GoodsSku());
    this.hasMore = true;
};
novel.GoodsSkuList.prototype._clone = function () { return new novel.GoodsSkuList(); };
novel.GoodsSkuList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GoodsSkuList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GoodsSkuList.prototype.readFrom = function (is) {
    this.skus = is.readVector(0, false, this.skus);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.GoodsSkuList.prototype.writeTo = function (os) {
    os.writeVector(0, this.skus);
    os.writeBoolean(1, this.hasMore);
};

novel.GoodsSkuMap = function() {
    this.skuMap = new Taf.Map(new Taf.INT64(), new novel.GoodsSku());
};
novel.GoodsSkuMap.prototype._clone = function () { return new novel.GoodsSkuMap(); };
novel.GoodsSkuMap.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GoodsSkuMap.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GoodsSkuMap.prototype.readFrom = function (is) {
    this.skuMap = is.readMap(0, false, this.skuMap);
};
novel.GoodsSkuMap.prototype.writeTo = function (os) {
    os.writeMap(0, this.skuMap);
};

novel.GetGoodsByIdReq = function() {
    this.tId = new novel.UserId();
    this.skuId = 0;
};
novel.GetGoodsByIdReq.prototype._clone = function () { return new novel.GetGoodsByIdReq(); };
novel.GetGoodsByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetGoodsByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetGoodsByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.skuId = is.readInt64(1, false, this.skuId);
};
novel.GetGoodsByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.skuId);
};

novel.OrderGoodsItem = function() {
    this.skuId = 0;
    this.quantity = 1;
};
novel.OrderGoodsItem.prototype._clone = function () { return new novel.OrderGoodsItem(); };
novel.OrderGoodsItem.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OrderGoodsItem.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OrderGoodsItem.prototype.readFrom = function (is) {
    this.skuId = is.readInt64(0, false, this.skuId);
    this.quantity = is.readInt32(1, false, this.quantity);
};
novel.OrderGoodsItem.prototype.writeTo = function (os) {
    os.writeInt64(0, this.skuId);
    os.writeInt32(1, this.quantity);
};

novel.OrderGoodsReq = function() {
    this.tId = new novel.UserId();
    this.items = new Taf.Vector(new novel.OrderGoodsItem());
    this.sign = "";
    this.orderType = 0;
};
novel.OrderGoodsReq.prototype._clone = function () { return new novel.OrderGoodsReq(); };
novel.OrderGoodsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OrderGoodsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OrderGoodsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.items = is.readVector(1, false, this.items);
    this.sign = is.readString(2, false, this.sign);
    this.orderType = is.readInt32(3, false, this.orderType);
};
novel.OrderGoodsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.items);
    os.writeString(2, this.sign);
    os.writeInt32(3, this.orderType);
};

novel.OrderGoodsRsp = function() {
    this.orderId = "";
    this.amount = 0;
    this.orderState = 0;
    this.ownItems = new Taf.Vector(new novel.BagGoods());
};
novel.OrderGoodsRsp.prototype._clone = function () { return new novel.OrderGoodsRsp(); };
novel.OrderGoodsRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OrderGoodsRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OrderGoodsRsp.prototype.readFrom = function (is) {
    this.orderId = is.readString(0, false, this.orderId);
    this.amount = is.readInt32(1, false, this.amount);
    this.orderState = is.readInt32(2, false, this.orderState);
    this.ownItems = is.readVector(3, false, this.ownItems);
};
novel.OrderGoodsRsp.prototype.writeTo = function (os) {
    os.writeString(0, this.orderId);
    os.writeInt32(1, this.amount);
    os.writeInt32(2, this.orderState);
    os.writeVector(3, this.ownItems);
};

novel.MallOrderListReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.MallOrderListReq.prototype._clone = function () { return new novel.MallOrderListReq(); };
novel.MallOrderListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MallOrderListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MallOrderListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.MallOrderListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.MallOrderList = function() {
    this.orders = new Taf.Vector(new novel.MallOrder());
    this.hasMore = true;
};
novel.MallOrderList.prototype._clone = function () { return new novel.MallOrderList(); };
novel.MallOrderList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.MallOrderList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.MallOrderList.prototype.readFrom = function (is) {
    this.orders = is.readVector(0, false, this.orders);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.MallOrderList.prototype.writeTo = function (os) {
    os.writeVector(0, this.orders);
    os.writeBoolean(1, this.hasMore);
};

novel.GetSkuByIdReq = function() {
    this.tId = new novel.UserId();
    this.skuId = 0;
};
novel.GetSkuByIdReq.prototype._clone = function () { return new novel.GetSkuByIdReq(); };
novel.GetSkuByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetSkuByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetSkuByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.skuId = is.readInt64(1, false, this.skuId);
};
novel.GetSkuByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.skuId);
};

novel.GetSpuByIdReq = function() {
    this.tId = new novel.UserId();
    this.spuId = 0;
};
novel.GetSpuByIdReq.prototype._clone = function () { return new novel.GetSpuByIdReq(); };
novel.GetSpuByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetSpuByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetSpuByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.spuId = is.readInt64(1, false, this.spuId);
};
novel.GetSpuByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.spuId);
};

novel.ListBagGoodsByCategoryReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.category = 0;
};
novel.ListBagGoodsByCategoryReq.prototype._clone = function () { return new novel.ListBagGoodsByCategoryReq(); };
novel.ListBagGoodsByCategoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBagGoodsByCategoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBagGoodsByCategoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.category = is.readInt32(3, false, this.category);
};
novel.ListBagGoodsByCategoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeInt32(3, this.category);
};

novel.BagGoodsList = function() {
    this.goods = new Taf.Vector(new novel.BagGoods());
    this.hasMore = true;
};
novel.BagGoodsList.prototype._clone = function () { return new novel.BagGoodsList(); };
novel.BagGoodsList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BagGoodsList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BagGoodsList.prototype.readFrom = function (is) {
    this.goods = is.readVector(0, false, this.goods);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.BagGoodsList.prototype.writeTo = function (os) {
    os.writeVector(0, this.goods);
    os.writeBoolean(1, this.hasMore);
};

novel.ConsumeBagGoodsReq = function() {
    this.tId = new novel.UserId();
    this.goodsId = 0;
    this.quantity = 0;
    this.toUids = new Taf.Vector(new Taf.INT64());
    this.toRoomId = 0;
};
novel.ConsumeBagGoodsReq.prototype._clone = function () { return new novel.ConsumeBagGoodsReq(); };
novel.ConsumeBagGoodsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ConsumeBagGoodsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ConsumeBagGoodsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.goodsId = is.readInt64(1, false, this.goodsId);
    this.quantity = is.readInt32(2, false, this.quantity);
    this.toUids = is.readVector(3, false, this.toUids);
    this.toRoomId = is.readInt64(4, false, this.toRoomId);
};
novel.ConsumeBagGoodsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.goodsId);
    os.writeInt32(2, this.quantity);
    os.writeVector(3, this.toUids);
    os.writeInt64(4, this.toRoomId);
};

novel.ListSkuByShelf = function() {
    this.tId = new novel.UserId();
    this.shelfType = 0;
    this.offset = 0;
    this.size = 0;
};
novel.ListSkuByShelf.prototype._clone = function () { return new novel.ListSkuByShelf(); };
novel.ListSkuByShelf.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListSkuByShelf.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListSkuByShelf.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.shelfType = is.readInt32(1, false, this.shelfType);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.ListSkuByShelf.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.shelfType);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.ListSkuByIdsReq = function() {
    this.tId = new novel.UserId();
    this.ids = new Taf.Vector(new Taf.INT64());
};
novel.ListSkuByIdsReq.prototype._clone = function () { return new novel.ListSkuByIdsReq(); };
novel.ListSkuByIdsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListSkuByIdsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListSkuByIdsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.ids = is.readVector(1, false, this.ids);
};
novel.ListSkuByIdsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.ids);
};

novel.GetUserNoAdStatusReq = function() {
    this.tId = new novel.UserId();
};
novel.GetUserNoAdStatusReq.prototype._clone = function () { return new novel.GetUserNoAdStatusReq(); };
novel.GetUserNoAdStatusReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUserNoAdStatusReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUserNoAdStatusReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetUserNoAdStatusReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.UserNoAdStatus = function() {
    this.uid = 0;
    this.expireAt = 0;
};
novel.UserNoAdStatus.prototype._clone = function () { return new novel.UserNoAdStatus(); };
novel.UserNoAdStatus.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserNoAdStatus.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserNoAdStatus.prototype.readFrom = function (is) {
    this.uid = is.readInt64(0, false, this.uid);
    this.expireAt = is.readInt64(1, false, this.expireAt);
};
novel.UserNoAdStatus.prototype.writeTo = function (os) {
    os.writeInt64(0, this.uid);
    os.writeInt64(1, this.expireAt);
};

novel.BookSpecialSubject = function() {
    this.id = "";
    this.title = "";
    this.subjectType = 0;
    this.style = 0;
    this.forDiscount = 0;
    this.expireAt = 0;
    this.forAudio = 0;
};
novel.BookSpecialSubject.prototype._clone = function () { return new novel.BookSpecialSubject(); };
novel.BookSpecialSubject.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookSpecialSubject.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookSpecialSubject.prototype.readFrom = function (is) {
    this.id = is.readString(0, false, this.id);
    this.title = is.readString(1, false, this.title);
    this.subjectType = is.readInt32(2, false, this.subjectType);
    this.style = is.readInt32(3, false, this.style);
    this.forDiscount = is.readInt32(4, false, this.forDiscount);
    this.expireAt = is.readInt64(5, false, this.expireAt);
    this.forAudio = is.readInt32(6, false, this.forAudio);
};
novel.BookSpecialSubject.prototype.writeTo = function (os) {
    os.writeString(0, this.id);
    os.writeString(1, this.title);
    os.writeInt32(2, this.subjectType);
    os.writeInt32(3, this.style);
    os.writeInt32(4, this.forDiscount);
    os.writeInt64(5, this.expireAt);
    os.writeInt32(6, this.forAudio);
};

novel.BookCategoryList = function() {
    this.categories = new Taf.Vector(new novel.BookCategory());
    this.hasMore = true;
    this.subject = new novel.BookSpecialSubject();
};
novel.BookCategoryList.prototype._clone = function () { return new novel.BookCategoryList(); };
novel.BookCategoryList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookCategoryList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookCategoryList.prototype.readFrom = function (is) {
    this.categories = is.readVector(0, false, this.categories);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.subject = is.readStruct(2, false, this.subject);
};
novel.BookCategoryList.prototype.writeTo = function (os) {
    os.writeVector(0, this.categories);
    os.writeBoolean(1, this.hasMore);
    os.writeStruct(2, this.subject);
};

novel.BookList = function() {
    this.books = new Taf.Vector(new novel.Book());
    this.hasMore = true;
    this.subject = new novel.BookSpecialSubject();
};
novel.BookList.prototype._clone = function () { return new novel.BookList(); };
novel.BookList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookList.prototype.readFrom = function (is) {
    this.books = is.readVector(0, false, this.books);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
    this.subject = is.readStruct(2, false, this.subject);
};
novel.BookList.prototype.writeTo = function (os) {
    os.writeVector(0, this.books);
    os.writeBoolean(1, this.hasMore);
    os.writeStruct(2, this.subject);
};

novel.BookListReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.sortType = 0;
    this.excludeBooks = new Taf.Vector(new Taf.INT64());
    this.complateState = 0;
};
novel.BookListReq.prototype._clone = function () { return new novel.BookListReq(); };
novel.BookListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.sortType = is.readInt32(3, false, this.sortType);
    this.excludeBooks = is.readVector(4, false, this.excludeBooks);
    this.complateState = is.readInt32(5, false, this.complateState);
};
novel.BookListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeInt32(3, this.sortType);
    os.writeVector(4, this.excludeBooks);
    os.writeInt32(5, this.complateState);
};

novel.GetBookCategoriesReq = function() {
    this.tId = new novel.UserId();
    this.categoryIds = new Taf.Vector(new Taf.INT64());
};
novel.GetBookCategoriesReq.prototype._clone = function () { return new novel.GetBookCategoriesReq(); };
novel.GetBookCategoriesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetBookCategoriesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetBookCategoriesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.categoryIds = is.readVector(1, false, this.categoryIds);
};
novel.GetBookCategoriesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.categoryIds);
};

novel.GetBookByIdReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
};
novel.GetBookByIdReq.prototype._clone = function () { return new novel.GetBookByIdReq(); };
novel.GetBookByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetBookByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetBookByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
};
novel.GetBookByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
};

novel.ListBookByIdsReq = function() {
    this.tId = new novel.UserId();
    this.ids = new Taf.Vector(new Taf.INT64());
};
novel.ListBookByIdsReq.prototype._clone = function () { return new novel.ListBookByIdsReq(); };
novel.ListBookByIdsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBookByIdsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBookByIdsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.ids = is.readVector(1, false, this.ids);
};
novel.ListBookByIdsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.ids);
};

novel.ListBookCategoriesBySubjectReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.subjectId = "";
};
novel.ListBookCategoriesBySubjectReq.prototype._clone = function () { return new novel.ListBookCategoriesBySubjectReq(); };
novel.ListBookCategoriesBySubjectReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBookCategoriesBySubjectReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBookCategoriesBySubjectReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.subjectId = is.readString(3, false, this.subjectId);
};
novel.ListBookCategoriesBySubjectReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeString(3, this.subjectId);
};

novel.ListBooksBySubjectReq = function() {
    this.tId = new novel.UserId();
    this.subjectId = "";
    this.offset = 0;
    this.size = 0;
    this.sortType = 0;
    this.excludeBooks = new Taf.Vector(new Taf.INT64());
};
novel.ListBooksBySubjectReq.prototype._clone = function () { return new novel.ListBooksBySubjectReq(); };
novel.ListBooksBySubjectReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBooksBySubjectReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBooksBySubjectReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectId = is.readString(1, false, this.subjectId);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
    this.sortType = is.readInt32(4, false, this.sortType);
    this.excludeBooks = is.readVector(5, false, this.excludeBooks);
};
novel.ListBooksBySubjectReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.subjectId);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
    os.writeInt32(4, this.sortType);
    os.writeVector(5, this.excludeBooks);
};

novel.ListBooksByCategoryReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.categoryIds = new Taf.Vector(new Taf.INT64());
    this.sortType = 0;
    this.completeState = 0;
    this.excludeBooks = new Taf.Vector(new Taf.INT64());
};
novel.ListBooksByCategoryReq.prototype._clone = function () { return new novel.ListBooksByCategoryReq(); };
novel.ListBooksByCategoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBooksByCategoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBooksByCategoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.categoryIds = is.readVector(3, false, this.categoryIds);
    this.sortType = is.readInt32(4, false, this.sortType);
    this.completeState = is.readInt32(5, false, this.completeState);
    this.excludeBooks = is.readVector(6, false, this.excludeBooks);
};
novel.ListBooksByCategoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeVector(3, this.categoryIds);
    os.writeInt32(4, this.sortType);
    os.writeInt32(5, this.completeState);
    os.writeVector(6, this.excludeBooks);
};

novel.ListBooksByTagsReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.tagIds = new Taf.Vector(new Taf.INT64());
    this.sortType = 0;
    this.completeState = 0;
};
novel.ListBooksByTagsReq.prototype._clone = function () { return new novel.ListBooksByTagsReq(); };
novel.ListBooksByTagsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBooksByTagsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBooksByTagsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.tagIds = is.readVector(3, false, this.tagIds);
    this.sortType = is.readInt32(4, false, this.sortType);
    this.completeState = is.readInt32(5, false, this.completeState);
};
novel.ListBooksByTagsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeVector(3, this.tagIds);
    os.writeInt32(4, this.sortType);
    os.writeInt32(5, this.completeState);
};

novel.GetBookChapterListReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.offset = 0;
    this.size = 0;
    this.reverse = 0;
};
novel.GetBookChapterListReq.prototype._clone = function () { return new novel.GetBookChapterListReq(); };
novel.GetBookChapterListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetBookChapterListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetBookChapterListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
    this.reverse = is.readInt32(4, false, this.reverse);
};
novel.GetBookChapterListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
    os.writeInt32(4, this.reverse);
};

novel.GetBookChapterLinesReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.chapterId = 0;
    this.offset = 0;
    this.size = 0;
    this.sign = "";
};
novel.GetBookChapterLinesReq.prototype._clone = function () { return new novel.GetBookChapterLinesReq(); };
novel.GetBookChapterLinesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetBookChapterLinesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetBookChapterLinesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.offset = is.readInt32(3, false, this.offset);
    this.size = is.readInt32(4, false, this.size);
    this.sign = is.readString(5, false, this.sign);
};
novel.GetBookChapterLinesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt64(2, this.chapterId);
    os.writeInt32(3, this.offset);
    os.writeInt32(4, this.size);
    os.writeString(5, this.sign);
};

novel.ListMyBooksFromShelfReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListMyBooksFromShelfReq.prototype._clone = function () { return new novel.ListMyBooksFromShelfReq(); };
novel.ListMyBooksFromShelfReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListMyBooksFromShelfReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListMyBooksFromShelfReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListMyBooksFromShelfReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.AddBookToShelfReq = function() {
    this.tId = new novel.UserId();
    this.bookIds = new Taf.Vector(new Taf.INT64());
};
novel.AddBookToShelfReq.prototype._clone = function () { return new novel.AddBookToShelfReq(); };
novel.AddBookToShelfReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AddBookToShelfReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AddBookToShelfReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookIds = is.readVector(1, false, this.bookIds);
};
novel.AddBookToShelfReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.bookIds);
};

novel.RemoveBookToShelfReq = function() {
    this.tId = new novel.UserId();
    this.bookIds = new Taf.Vector(new Taf.INT64());
};
novel.RemoveBookToShelfReq.prototype._clone = function () { return new novel.RemoveBookToShelfReq(); };
novel.RemoveBookToShelfReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RemoveBookToShelfReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RemoveBookToShelfReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookIds = is.readVector(1, false, this.bookIds);
};
novel.RemoveBookToShelfReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.bookIds);
};

novel.ReportBookInteractiveDataReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.type = 0;
};
novel.ReportBookInteractiveDataReq.prototype._clone = function () { return new novel.ReportBookInteractiveDataReq(); };
novel.ReportBookInteractiveDataReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReportBookInteractiveDataReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReportBookInteractiveDataReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.type = is.readInt32(2, false, this.type);
};
novel.ReportBookInteractiveDataReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt32(2, this.type);
};

novel.SyncBookReadProgressReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.chapterId = 0;
    this.paragraph = 0;
    this.offset = 0;
};
novel.SyncBookReadProgressReq.prototype._clone = function () { return new novel.SyncBookReadProgressReq(); };
novel.SyncBookReadProgressReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SyncBookReadProgressReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SyncBookReadProgressReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.paragraph = is.readInt32(3, false, this.paragraph);
    this.offset = is.readInt32(4, false, this.offset);
};
novel.SyncBookReadProgressReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt64(2, this.chapterId);
    os.writeInt32(3, this.paragraph);
    os.writeInt32(4, this.offset);
};

novel.SyncBookAudioProgressReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.chapterId = 0;
    this.paragraph = 0;
    this.audioOffset = 0;
    this.voice = 0;
};
novel.SyncBookAudioProgressReq.prototype._clone = function () { return new novel.SyncBookAudioProgressReq(); };
novel.SyncBookAudioProgressReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SyncBookAudioProgressReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SyncBookAudioProgressReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.paragraph = is.readInt32(3, false, this.paragraph);
    this.audioOffset = is.readInt64(4, false, this.audioOffset);
    this.voice = is.readInt32(5, false, this.voice);
};
novel.SyncBookAudioProgressReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt64(2, this.chapterId);
    os.writeInt32(3, this.paragraph);
    os.writeInt64(4, this.audioOffset);
    os.writeInt32(5, this.voice);
};

novel.ListUserBookHistoryReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserBookHistoryReq.prototype._clone = function () { return new novel.ListUserBookHistoryReq(); };
novel.ListUserBookHistoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserBookHistoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserBookHistoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserBookHistoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.RemoveBookReadHistoryReq = function() {
    this.tId = new novel.UserId();
    this.books = new Taf.Vector(new Taf.INT64());
};
novel.RemoveBookReadHistoryReq.prototype._clone = function () { return new novel.RemoveBookReadHistoryReq(); };
novel.RemoveBookReadHistoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RemoveBookReadHistoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RemoveBookReadHistoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.books = is.readVector(1, false, this.books);
};
novel.RemoveBookReadHistoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.books);
};

novel.ListRankCategoriesReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListRankCategoriesReq.prototype._clone = function () { return new novel.ListRankCategoriesReq(); };
novel.ListRankCategoriesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListRankCategoriesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListRankCategoriesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListRankCategoriesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.ListBooksRankByCategoryReq = function() {
    this.tId = new novel.UserId();
    this.categoryId = 0;
    this.rankType = 0;
    this.offset = 0;
    this.size = 0;
    this.excludeBooks = new Taf.Vector(new Taf.INT64());
};
novel.ListBooksRankByCategoryReq.prototype._clone = function () { return new novel.ListBooksRankByCategoryReq(); };
novel.ListBooksRankByCategoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBooksRankByCategoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBooksRankByCategoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.categoryId = is.readInt64(1, false, this.categoryId);
    this.rankType = is.readInt32(2, false, this.rankType);
    this.offset = is.readInt32(3, false, this.offset);
    this.size = is.readInt32(4, false, this.size);
    this.excludeBooks = is.readVector(5, false, this.excludeBooks);
};
novel.ListBooksRankByCategoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.categoryId);
    os.writeInt32(2, this.rankType);
    os.writeInt32(3, this.offset);
    os.writeInt32(4, this.size);
    os.writeVector(5, this.excludeBooks);
};

novel.ListAllBookCategoriesReq = function() {
    this.tId = new novel.UserId();
    this.type = 0;
    this.offset = 0;
    this.size = 0;
};
novel.ListAllBookCategoriesReq.prototype._clone = function () { return new novel.ListAllBookCategoriesReq(); };
novel.ListAllBookCategoriesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListAllBookCategoriesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListAllBookCategoriesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.type = is.readInt32(1, false, this.type);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.ListAllBookCategoriesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.type);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.ListSimilarBooksReq = function() {
    this.tId = new novel.UserId();
    this.book = 0;
    this.offset = 0;
    this.size = 0;
};
novel.ListSimilarBooksReq.prototype._clone = function () { return new novel.ListSimilarBooksReq(); };
novel.ListSimilarBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListSimilarBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListSimilarBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.book = is.readInt64(1, false, this.book);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.ListSimilarBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.book);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.ListBooksByQualityReq = function() {
    this.tId = new novel.UserId();
    this.scoreType = 0;
    this.offset = 0;
    this.size = 0;
    this.excludeBooks = new Taf.Vector(new Taf.INT64());
};
novel.ListBooksByQualityReq.prototype._clone = function () { return new novel.ListBooksByQualityReq(); };
novel.ListBooksByQualityReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBooksByQualityReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBooksByQualityReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.scoreType = is.readInt32(1, false, this.scoreType);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
    this.excludeBooks = is.readVector(4, false, this.excludeBooks);
};
novel.ListBooksByQualityReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.scoreType);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
    os.writeVector(4, this.excludeBooks);
};

novel.SearchBooksCategoriesTagsRsp = function() {
    this.bookList = new novel.BookList();
    this.categories = new novel.BookCategoryList();
    this.tags = new novel.BookCategoryList();
};
novel.SearchBooksCategoriesTagsRsp.prototype._clone = function () { return new novel.SearchBooksCategoriesTagsRsp(); };
novel.SearchBooksCategoriesTagsRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SearchBooksCategoriesTagsRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SearchBooksCategoriesTagsRsp.prototype.readFrom = function (is) {
    this.bookList = is.readStruct(0, false, this.bookList);
    this.categories = is.readStruct(1, false, this.categories);
    this.tags = is.readStruct(2, false, this.tags);
};
novel.SearchBooksCategoriesTagsRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.bookList);
    os.writeStruct(1, this.categories);
    os.writeStruct(2, this.tags);
};

novel.ListAppCategoryTabListReq = function() {
    this.tId = new novel.UserId();
};
novel.ListAppCategoryTabListReq.prototype._clone = function () { return new novel.ListAppCategoryTabListReq(); };
novel.ListAppCategoryTabListReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListAppCategoryTabListReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListAppCategoryTabListReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListAppCategoryTabListReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.AppSection = function() {
    this.type = 0;
    this.title = "";
    this.style = 0;
    this.firstLimit = 0;
    this.showReads = 0;
    this.rankType = 0;
    this.categoryIdForRank = 0;
    this.subjectId = "";
    this.sortType = 0;
    this.completedState = 0;
};
novel.AppSection.prototype._clone = function () { return new novel.AppSection(); };
novel.AppSection.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppSection.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppSection.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.title = is.readString(1, false, this.title);
    this.style = is.readInt32(2, false, this.style);
    this.firstLimit = is.readInt32(3, false, this.firstLimit);
    this.showReads = is.readInt32(4, false, this.showReads);
    this.rankType = is.readInt32(5, false, this.rankType);
    this.categoryIdForRank = is.readInt64(6, false, this.categoryIdForRank);
    this.subjectId = is.readString(7, false, this.subjectId);
    this.sortType = is.readInt32(8, false, this.sortType);
    this.completedState = is.readInt32(9, false, this.completedState);
};
novel.AppSection.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.title);
    os.writeInt32(2, this.style);
    os.writeInt32(3, this.firstLimit);
    os.writeInt32(4, this.showReads);
    os.writeInt32(5, this.rankType);
    os.writeInt64(6, this.categoryIdForRank);
    os.writeString(7, this.subjectId);
    os.writeInt32(8, this.sortType);
    os.writeInt32(9, this.completedState);
};

novel.AppSectionList = function() {
    this.sections = new Taf.Vector(new novel.AppSection());
};
novel.AppSectionList.prototype._clone = function () { return new novel.AppSectionList(); };
novel.AppSectionList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppSectionList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppSectionList.prototype.readFrom = function (is) {
    this.sections = is.readVector(0, false, this.sections);
};
novel.AppSectionList.prototype.writeTo = function (os) {
    os.writeVector(0, this.sections);
};

novel.AppCategoryTab = function() {
    this.id = 0;
    this.category = 0;
    this.name = "";
    this.type = 0;
    this.sections = new Taf.Vector(new novel.AppSection());
};
novel.AppCategoryTab.prototype._clone = function () { return new novel.AppCategoryTab(); };
novel.AppCategoryTab.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppCategoryTab.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppCategoryTab.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.category = is.readInt64(1, false, this.category);
    this.name = is.readString(2, false, this.name);
    this.type = is.readInt32(3, false, this.type);
    this.sections = is.readVector(4, false, this.sections);
};
novel.AppCategoryTab.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt64(1, this.category);
    os.writeString(2, this.name);
    os.writeInt32(3, this.type);
    os.writeVector(4, this.sections);
};

novel.AppCategoryTabList = function() {
    this.tabs = new Taf.Vector(new novel.AppCategoryTab());
};
novel.AppCategoryTabList.prototype._clone = function () { return new novel.AppCategoryTabList(); };
novel.AppCategoryTabList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AppCategoryTabList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AppCategoryTabList.prototype.readFrom = function (is) {
    this.tabs = is.readVector(0, false, this.tabs);
};
novel.AppCategoryTabList.prototype.writeTo = function (os) {
    os.writeVector(0, this.tabs);
};

novel.ListTopicSubjectsWithBooksReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListTopicSubjectsWithBooksReq.prototype._clone = function () { return new novel.ListTopicSubjectsWithBooksReq(); };
novel.ListTopicSubjectsWithBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListTopicSubjectsWithBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListTopicSubjectsWithBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListTopicSubjectsWithBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.ListCategoryTabSubjectsWithBooksReq = function() {
    this.tId = new novel.UserId();
    this.categoryId = 0;
    this.offset = 0;
    this.size = 0;
};
novel.ListCategoryTabSubjectsWithBooksReq.prototype._clone = function () { return new novel.ListCategoryTabSubjectsWithBooksReq(); };
novel.ListCategoryTabSubjectsWithBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListCategoryTabSubjectsWithBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListCategoryTabSubjectsWithBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.categoryId = is.readInt64(1, false, this.categoryId);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.ListCategoryTabSubjectsWithBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.categoryId);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.BookSpecialSubjectWithBooksList = function() {
    this.list = new Taf.Vector(new novel.BookList());
    this.hasMore = true;
};
novel.BookSpecialSubjectWithBooksList.prototype._clone = function () { return new novel.BookSpecialSubjectWithBooksList(); };
novel.BookSpecialSubjectWithBooksList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BookSpecialSubjectWithBooksList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BookSpecialSubjectWithBooksList.prototype.readFrom = function (is) {
    this.list = is.readVector(0, false, this.list);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.BookSpecialSubjectWithBooksList.prototype.writeTo = function (os) {
    os.writeVector(0, this.list);
    os.writeBoolean(1, this.hasMore);
};

novel.GetRandomSubjectBooksReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.GetRandomSubjectBooksReq.prototype._clone = function () { return new novel.GetRandomSubjectBooksReq(); };
novel.GetRandomSubjectBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetRandomSubjectBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetRandomSubjectBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.GetRandomSubjectBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.ListOperatorConfigHotBooksReq = function() {
    this.tId = new novel.UserId();
};
novel.ListOperatorConfigHotBooksReq.prototype._clone = function () { return new novel.ListOperatorConfigHotBooksReq(); };
novel.ListOperatorConfigHotBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListOperatorConfigHotBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListOperatorConfigHotBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListOperatorConfigHotBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListOperatorConfigPotentialBooksReq = function() {
    this.tId = new novel.UserId();
};
novel.ListOperatorConfigPotentialBooksReq.prototype._clone = function () { return new novel.ListOperatorConfigPotentialBooksReq(); };
novel.ListOperatorConfigPotentialBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListOperatorConfigPotentialBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListOperatorConfigPotentialBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListOperatorConfigPotentialBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListBookPublishChapterIdsReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
};
novel.ListBookPublishChapterIdsReq.prototype._clone = function () { return new novel.ListBookPublishChapterIdsReq(); };
novel.ListBookPublishChapterIdsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBookPublishChapterIdsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBookPublishChapterIdsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
};
novel.ListBookPublishChapterIdsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
};

novel.ChapterIdList = function() {
    this.ids = new Taf.Vector(new Taf.INT64());
};
novel.ChapterIdList.prototype._clone = function () { return new novel.ChapterIdList(); };
novel.ChapterIdList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ChapterIdList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ChapterIdList.prototype.readFrom = function (is) {
    this.ids = is.readVector(0, false, this.ids);
};
novel.ChapterIdList.prototype.writeTo = function (os) {
    os.writeVector(0, this.ids);
};

novel.ListBookChaptersByIdReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.chapterIds = new Taf.Vector(new Taf.INT64());
};
novel.ListBookChaptersByIdReq.prototype._clone = function () { return new novel.ListBookChaptersByIdReq(); };
novel.ListBookChaptersByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListBookChaptersByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListBookChaptersByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.chapterIds = is.readVector(2, false, this.chapterIds);
};
novel.ListBookChaptersByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeVector(2, this.chapterIds);
};

novel.GetRandomDiscountSubjectBooksReq = function() {
    this.tId = new novel.UserId();
    this.subjectId = "";
    this.offset = 0;
    this.size = 0;
};
novel.GetRandomDiscountSubjectBooksReq.prototype._clone = function () { return new novel.GetRandomDiscountSubjectBooksReq(); };
novel.GetRandomDiscountSubjectBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetRandomDiscountSubjectBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetRandomDiscountSubjectBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.subjectId = is.readString(1, false, this.subjectId);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.GetRandomDiscountSubjectBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.subjectId);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.GetChapterAudioPlayInfoReq = function() {
    this.tId = new novel.UserId();
    this.bookId = 0;
    this.chapterId = 0;
    this.voiceType = 0;
};
novel.GetChapterAudioPlayInfoReq.prototype._clone = function () { return new novel.GetChapterAudioPlayInfoReq(); };
novel.GetChapterAudioPlayInfoReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetChapterAudioPlayInfoReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetChapterAudioPlayInfoReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.bookId = is.readInt64(1, false, this.bookId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.voiceType = is.readInt32(3, false, this.voiceType);
};
novel.GetChapterAudioPlayInfoReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.bookId);
    os.writeInt64(2, this.chapterId);
    os.writeInt32(3, this.voiceType);
};

novel.AudioTimeline = function() {
    this.pid = 0;
    this.start = 0;
    this.end = 0;
};
novel.AudioTimeline.prototype._clone = function () { return new novel.AudioTimeline(); };
novel.AudioTimeline.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.AudioTimeline.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.AudioTimeline.prototype.readFrom = function (is) {
    this.pid = is.readInt32(0, false, this.pid);
    this.start = is.readInt64(1, false, this.start);
    this.end = is.readInt64(2, false, this.end);
};
novel.AudioTimeline.prototype.writeTo = function (os) {
    os.writeInt32(0, this.pid);
    os.writeInt64(1, this.start);
    os.writeInt64(2, this.end);
};

novel.ChapterAudioPlayInfo = function() {
    this.millis = 0;
    this.size = 0;
    this.timelines = new Taf.Vector(new novel.AudioTimeline());
    this.playAuth = "";
    this.aid = "";
};
novel.ChapterAudioPlayInfo.prototype._clone = function () { return new novel.ChapterAudioPlayInfo(); };
novel.ChapterAudioPlayInfo.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ChapterAudioPlayInfo.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ChapterAudioPlayInfo.prototype.readFrom = function (is) {
    this.millis = is.readInt64(0, false, this.millis);
    this.size = is.readInt64(1, false, this.size);
    this.timelines = is.readVector(2, false, this.timelines);
    this.playAuth = is.readString(3, false, this.playAuth);
    this.aid = is.readString(4, false, this.aid);
};
novel.ChapterAudioPlayInfo.prototype.writeTo = function (os) {
    os.writeInt64(0, this.millis);
    os.writeInt64(1, this.size);
    os.writeVector(2, this.timelines);
    os.writeString(3, this.playAuth);
    os.writeString(4, this.aid);
};

novel.StoryTag = function() {
    this.id = 0;
    this.type = 0;
    this.name = "";
};
novel.StoryTag.prototype._clone = function () { return new novel.StoryTag(); };
novel.StoryTag.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryTag.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryTag.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.type = is.readInt32(1, false, this.type);
    this.name = is.readString(2, false, this.name);
};
novel.StoryTag.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt32(1, this.type);
    os.writeString(2, this.name);
};

novel.StoryTags = function() {
    this.tags = new Taf.Vector(new novel.StoryTag());
};
novel.StoryTags.prototype._clone = function () { return new novel.StoryTags(); };
novel.StoryTags.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryTags.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryTags.prototype.readFrom = function (is) {
    this.tags = is.readVector(0, false, this.tags);
};
novel.StoryTags.prototype.writeTo = function (os) {
    os.writeVector(0, this.tags);
};

novel.StoryIdea = function() {
    this.id = 0;
    this.name = "";
    this.image = "";
    this.description = "";
    this.genre = new novel.StoryTag();
    this.plots = new novel.StoryTags();
    this.emotions = new novel.StoryTags();
    this.spacetimes = new novel.StoryTags();
    this.locations = new novel.StoryTags();
};
novel.StoryIdea.prototype._clone = function () { return new novel.StoryIdea(); };
novel.StoryIdea.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryIdea.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryIdea.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.name = is.readString(1, false, this.name);
    this.image = is.readString(2, false, this.image);
    this.description = is.readString(3, false, this.description);
    this.genre = is.readStruct(4, false, this.genre);
    this.plots = is.readStruct(5, false, this.plots);
    this.emotions = is.readStruct(6, false, this.emotions);
    this.spacetimes = is.readStruct(7, false, this.spacetimes);
    this.locations = is.readStruct(8, false, this.locations);
};
novel.StoryIdea.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.name);
    os.writeString(2, this.image);
    os.writeString(3, this.description);
    os.writeStruct(4, this.genre);
    os.writeStruct(5, this.plots);
    os.writeStruct(6, this.emotions);
    os.writeStruct(7, this.spacetimes);
    os.writeStruct(8, this.locations);
};

novel.StoryIdeaList = function() {
    this.ideas = new Taf.Vector(new novel.StoryIdea());
    this.hasMore = true;
};
novel.StoryIdeaList.prototype._clone = function () { return new novel.StoryIdeaList(); };
novel.StoryIdeaList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryIdeaList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryIdeaList.prototype.readFrom = function (is) {
    this.ideas = is.readVector(0, false, this.ideas);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.StoryIdeaList.prototype.writeTo = function (os) {
    os.writeVector(0, this.ideas);
    os.writeBoolean(1, this.hasMore);
};

novel.StoryCharacter = function() {
    this.id = 0;
    this.image = "";
    this.name = "";
    this.gender = "";
    this.age = "";
    this.identity = "";
    this.personality = "";
    this.appearance = "";
    this.dialogue_style = "";
    this.setting = "";
    this.motivation_goals = "";
    this.growth = "";
    this.plot_affect = "";
};
novel.StoryCharacter.prototype._clone = function () { return new novel.StoryCharacter(); };
novel.StoryCharacter.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryCharacter.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryCharacter.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.image = is.readString(1, false, this.image);
    this.name = is.readString(2, false, this.name);
    this.gender = is.readString(3, false, this.gender);
    this.age = is.readString(4, false, this.age);
    this.identity = is.readString(5, false, this.identity);
    this.personality = is.readString(6, false, this.personality);
    this.appearance = is.readString(7, false, this.appearance);
    this.dialogue_style = is.readString(8, false, this.dialogue_style);
    this.setting = is.readString(9, false, this.setting);
    this.motivation_goals = is.readString(10, false, this.motivation_goals);
    this.growth = is.readString(11, false, this.growth);
    this.plot_affect = is.readString(12, false, this.plot_affect);
};
novel.StoryCharacter.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeString(1, this.image);
    os.writeString(2, this.name);
    os.writeString(3, this.gender);
    os.writeString(4, this.age);
    os.writeString(5, this.identity);
    os.writeString(6, this.personality);
    os.writeString(7, this.appearance);
    os.writeString(8, this.dialogue_style);
    os.writeString(9, this.setting);
    os.writeString(10, this.motivation_goals);
    os.writeString(11, this.growth);
    os.writeString(12, this.plot_affect);
};

novel.StoryCharacterList = function() {
    this.characters = new Taf.Vector(new novel.StoryCharacter());
    this.hasMore = true;
};
novel.StoryCharacterList.prototype._clone = function () { return new novel.StoryCharacterList(); };
novel.StoryCharacterList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryCharacterList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryCharacterList.prototype.readFrom = function (is) {
    this.characters = is.readVector(0, false, this.characters);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.StoryCharacterList.prototype.writeTo = function (os) {
    os.writeVector(0, this.characters);
    os.writeBoolean(1, this.hasMore);
};

novel.StoryInteractiveData = function() {
    this.reads = 0;
    this.likes = 0;
    this.parentComments = 0;
    this.stars = 0;
};
novel.StoryInteractiveData.prototype._clone = function () { return new novel.StoryInteractiveData(); };
novel.StoryInteractiveData.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryInteractiveData.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryInteractiveData.prototype.readFrom = function (is) {
    this.reads = is.readInt32(0, false, this.reads);
    this.likes = is.readInt32(1, false, this.likes);
    this.parentComments = is.readInt32(2, false, this.parentComments);
    this.stars = is.readInt32(3, false, this.stars);
};
novel.StoryInteractiveData.prototype.writeTo = function (os) {
    os.writeInt32(0, this.reads);
    os.writeInt32(1, this.likes);
    os.writeInt32(2, this.parentComments);
    os.writeInt32(3, this.stars);
};

novel.StoryReadProgress = function() {
    this.chapterId = 0;
};
novel.StoryReadProgress.prototype._clone = function () { return new novel.StoryReadProgress(); };
novel.StoryReadProgress.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryReadProgress.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryReadProgress.prototype.readFrom = function (is) {
    this.chapterId = is.readInt64(0, false, this.chapterId);
};
novel.StoryReadProgress.prototype.writeTo = function (os) {
    os.writeInt64(0, this.chapterId);
};

novel.Story = function() {
    this.id = 0;
    this.uid = 0;
    this.author = new novel.UserBase();
    this.name = "";
    this.description = "";
    this.cover = "";
    this.storyIdea = new novel.StoryIdea();
    this.plots = new novel.StoryTags();
    this.emotions = new novel.StoryTags();
    this.spaceTimes = new novel.StoryTags();
    this.locations = new novel.StoryTags();
    this.others = "";
    this.characters = new Taf.Vector(new novel.StoryCharacter());
    this.words = 0;
    this.chapters = 0;
    this.lastUpdateTime = 0;
    this.storyIdata = new novel.StoryInteractiveData();
    this.readProgress = new novel.StoryReadProgress();
    this.completedState = 0;
    this.synopsis = "";
};
novel.Story.prototype._clone = function () { return new novel.Story(); };
novel.Story.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Story.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Story.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.uid = is.readInt64(1, false, this.uid);
    this.author = is.readStruct(2, false, this.author);
    this.name = is.readString(3, false, this.name);
    this.description = is.readString(4, false, this.description);
    this.cover = is.readString(5, false, this.cover);
    this.storyIdea = is.readStruct(6, false, this.storyIdea);
    this.plots = is.readStruct(7, false, this.plots);
    this.emotions = is.readStruct(8, false, this.emotions);
    this.spaceTimes = is.readStruct(9, false, this.spaceTimes);
    this.locations = is.readStruct(10, false, this.locations);
    this.others = is.readString(11, false, this.others);
    this.characters = is.readVector(12, false, this.characters);
    this.words = is.readInt32(13, false, this.words);
    this.chapters = is.readInt32(14, false, this.chapters);
    this.lastUpdateTime = is.readInt64(15, false, this.lastUpdateTime);
    this.storyIdata = is.readStruct(16, false, this.storyIdata);
    this.readProgress = is.readStruct(17, false, this.readProgress);
    this.completedState = is.readInt32(18, false, this.completedState);
    this.synopsis = is.readString(19, false, this.synopsis);
};
novel.Story.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt64(1, this.uid);
    os.writeStruct(2, this.author);
    os.writeString(3, this.name);
    os.writeString(4, this.description);
    os.writeString(5, this.cover);
    os.writeStruct(6, this.storyIdea);
    os.writeStruct(7, this.plots);
    os.writeStruct(8, this.emotions);
    os.writeStruct(9, this.spaceTimes);
    os.writeStruct(10, this.locations);
    os.writeString(11, this.others);
    os.writeVector(12, this.characters);
    os.writeInt32(13, this.words);
    os.writeInt32(14, this.chapters);
    os.writeInt64(15, this.lastUpdateTime);
    os.writeStruct(16, this.storyIdata);
    os.writeStruct(17, this.readProgress);
    os.writeInt32(18, this.completedState);
    os.writeString(19, this.synopsis);
};

novel.StoryList = function() {
    this.list = new Taf.Vector(new novel.Story());
    this.hasMore = true;
};
novel.StoryList.prototype._clone = function () { return new novel.StoryList(); };
novel.StoryList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryList.prototype.readFrom = function (is) {
    this.list = is.readVector(0, false, this.list);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.StoryList.prototype.writeTo = function (os) {
    os.writeVector(0, this.list);
    os.writeBoolean(1, this.hasMore);
};

novel.StoryChapter = function() {
    this.id = 0;
    this.bid = 0;
    this.name = "";
    this.idx = 0;
    this.words = 0;
    this.aiCompleted = 0;
    this.likes = 0;
    this.comments = 0;
    this.reads = 0;
};
novel.StoryChapter.prototype._clone = function () { return new novel.StoryChapter(); };
novel.StoryChapter.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryChapter.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryChapter.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.bid = is.readInt64(1, false, this.bid);
    this.name = is.readString(2, false, this.name);
    this.idx = is.readInt32(3, false, this.idx);
    this.words = is.readInt32(4, false, this.words);
    this.aiCompleted = is.readInt32(5, false, this.aiCompleted);
    this.likes = is.readInt32(6, false, this.likes);
    this.comments = is.readInt32(7, false, this.comments);
    this.reads = is.readInt32(8, false, this.reads);
};
novel.StoryChapter.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt64(1, this.bid);
    os.writeString(2, this.name);
    os.writeInt32(3, this.idx);
    os.writeInt32(4, this.words);
    os.writeInt32(5, this.aiCompleted);
    os.writeInt32(6, this.likes);
    os.writeInt32(7, this.comments);
    os.writeInt32(8, this.reads);
};

novel.StoryChapterList = function() {
    this.chapters = new Taf.Vector(new novel.StoryChapter());
    this.hasMore = true;
};
novel.StoryChapterList.prototype._clone = function () { return new novel.StoryChapterList(); };
novel.StoryChapterList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryChapterList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryChapterList.prototype.readFrom = function (is) {
    this.chapters = is.readVector(0, false, this.chapters);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.StoryChapterList.prototype.writeTo = function (os) {
    os.writeVector(0, this.chapters);
    os.writeBoolean(1, this.hasMore);
};

novel.StoryChapterLines = function() {
    this.sid = 0;
    this.cid = 0;
    this.lines = new Taf.Vector(new Taf.STRING());
};
novel.StoryChapterLines.prototype._clone = function () { return new novel.StoryChapterLines(); };
novel.StoryChapterLines.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryChapterLines.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryChapterLines.prototype.readFrom = function (is) {
    this.sid = is.readInt64(0, false, this.sid);
    this.cid = is.readInt64(1, false, this.cid);
    this.lines = is.readVector(2, false, this.lines);
};
novel.StoryChapterLines.prototype.writeTo = function (os) {
    os.writeInt64(0, this.sid);
    os.writeInt64(1, this.cid);
    os.writeVector(2, this.lines);
};

novel.CreateStoryReq = function() {
    this.tId = new novel.UserId();
    this.name = "";
    this.storyIdea = new novel.StoryIdea();
    this.plots = new novel.StoryTags();
    this.emotions = new novel.StoryTags();
    this.spaceTimes = new novel.StoryTags();
    this.locations = new novel.StoryTags();
    this.others = "";
    this.characters = new Taf.Vector(new novel.StoryCharacter());
};
novel.CreateStoryReq.prototype._clone = function () { return new novel.CreateStoryReq(); };
novel.CreateStoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CreateStoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CreateStoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.name = is.readString(1, false, this.name);
    this.storyIdea = is.readStruct(2, false, this.storyIdea);
    this.plots = is.readStruct(3, false, this.plots);
    this.emotions = is.readStruct(4, false, this.emotions);
    this.spaceTimes = is.readStruct(5, false, this.spaceTimes);
    this.locations = is.readStruct(6, false, this.locations);
    this.others = is.readString(7, false, this.others);
    this.characters = is.readVector(8, false, this.characters);
};
novel.CreateStoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.name);
    os.writeStruct(2, this.storyIdea);
    os.writeStruct(3, this.plots);
    os.writeStruct(4, this.emotions);
    os.writeStruct(5, this.spaceTimes);
    os.writeStruct(6, this.locations);
    os.writeString(7, this.others);
    os.writeVector(8, this.characters);
};

novel.CreateStoryRsp = function() {
    this.story = new novel.Story();
};
novel.CreateStoryRsp.prototype._clone = function () { return new novel.CreateStoryRsp(); };
novel.CreateStoryRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CreateStoryRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CreateStoryRsp.prototype.readFrom = function (is) {
    this.story = is.readStruct(0, false, this.story);
};
novel.CreateStoryRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.story);
};

novel.ListUserStoriesReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserStoriesReq.prototype._clone = function () { return new novel.ListUserStoriesReq(); };
novel.ListUserStoriesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserStoriesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserStoriesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserStoriesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.SseGenerateStoryReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
};
novel.SseGenerateStoryReq.prototype._clone = function () { return new novel.SseGenerateStoryReq(); };
novel.SseGenerateStoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
};
novel.SseGenerateStoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
};

novel.SseGenerateStoryChapterLinesReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chapterId = 0;
    this.nextOption = "";
};
novel.SseGenerateStoryChapterLinesReq.prototype._clone = function () { return new novel.SseGenerateStoryChapterLinesReq(); };
novel.SseGenerateStoryChapterLinesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryChapterLinesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryChapterLinesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.nextOption = is.readString(3, false, this.nextOption);
};
novel.SseGenerateStoryChapterLinesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt64(2, this.chapterId);
    os.writeString(3, this.nextOption);
};

novel.SseGenerateWtoryReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
};
novel.SseGenerateWtoryReq.prototype._clone = function () { return new novel.SseGenerateWtoryReq(); };
novel.SseGenerateWtoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateWtoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateWtoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
};
novel.SseGenerateWtoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
};

novel.SseGenerateCoverByAiReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
};
novel.SseGenerateCoverByAiReq.prototype._clone = function () { return new novel.SseGenerateCoverByAiReq(); };
novel.SseGenerateCoverByAiReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateCoverByAiReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateCoverByAiReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
};
novel.SseGenerateCoverByAiReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
};

novel.SseGenerateCoverByAiRsp = function() {
    this.type = 0;
    this.cover = "";
};
novel.SseGenerateCoverByAiRsp.prototype._clone = function () { return new novel.SseGenerateCoverByAiRsp(); };
novel.SseGenerateCoverByAiRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateCoverByAiRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateCoverByAiRsp.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.cover = is.readString(1, false, this.cover);
};
novel.SseGenerateCoverByAiRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.cover);
};

novel.StoryChapterNextOptions = function() {
    this.title = "";
    this.options = new Taf.Vector(new Taf.STRING());
};
novel.StoryChapterNextOptions.prototype._clone = function () { return new novel.StoryChapterNextOptions(); };
novel.StoryChapterNextOptions.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.StoryChapterNextOptions.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.StoryChapterNextOptions.prototype.readFrom = function (is) {
    this.title = is.readString(0, false, this.title);
    this.options = is.readVector(1, false, this.options);
};
novel.StoryChapterNextOptions.prototype.writeTo = function (os) {
    os.writeString(0, this.title);
    os.writeVector(1, this.options);
};

novel.SseGenerateStoryRsp = function() {
    this.type = 0;
    this.title = "";
};
novel.SseGenerateStoryRsp.prototype._clone = function () { return new novel.SseGenerateStoryRsp(); };
novel.SseGenerateStoryRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryRsp.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.title = is.readString(1, false, this.title);
};
novel.SseGenerateStoryRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.title);
};

novel.SseGenerateStoryChapterLinesRsp = function() {
    this.type = 0;
    this.chapterId = 0;
    this.line = "";
    this.options = new novel.StoryChapterNextOptions();
    this.title = "";
};
novel.SseGenerateStoryChapterLinesRsp.prototype._clone = function () { return new novel.SseGenerateStoryChapterLinesRsp(); };
novel.SseGenerateStoryChapterLinesRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryChapterLinesRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryChapterLinesRsp.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.chapterId = is.readInt64(1, false, this.chapterId);
    this.line = is.readString(2, false, this.line);
    this.options = is.readStruct(3, false, this.options);
    this.title = is.readString(4, false, this.title);
};
novel.SseGenerateStoryChapterLinesRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeInt64(1, this.chapterId);
    os.writeString(2, this.line);
    os.writeStruct(3, this.options);
    os.writeString(4, this.title);
};

novel.SseGenerateWtoryRsp = function() {
    this.type = 0;
    this.ideaList = new novel.StoryIdeaList();
};
novel.SseGenerateWtoryRsp.prototype._clone = function () { return new novel.SseGenerateWtoryRsp(); };
novel.SseGenerateWtoryRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateWtoryRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateWtoryRsp.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.ideaList = is.readStruct(1, false, this.ideaList);
};
novel.SseGenerateWtoryRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeStruct(1, this.ideaList);
};

novel.ListStoryIdeaReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.genre = 0;
};
novel.ListStoryIdeaReq.prototype._clone = function () { return new novel.ListStoryIdeaReq(); };
novel.ListStoryIdeaReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListStoryIdeaReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListStoryIdeaReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.genre = is.readInt64(3, false, this.genre);
};
novel.ListStoryIdeaReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeInt64(3, this.genre);
};

novel.ListDefaultStoryCharacterReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.gender = "";
    this.excludes = new Taf.Vector(new Taf.INT64());
};
novel.ListDefaultStoryCharacterReq.prototype._clone = function () { return new novel.ListDefaultStoryCharacterReq(); };
novel.ListDefaultStoryCharacterReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListDefaultStoryCharacterReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListDefaultStoryCharacterReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.gender = is.readString(3, false, this.gender);
    this.excludes = is.readVector(4, false, this.excludes);
};
novel.ListDefaultStoryCharacterReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeString(3, this.gender);
    os.writeVector(4, this.excludes);
};

novel.ListUserStoryReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserStoryReq.prototype._clone = function () { return new novel.ListUserStoryReq(); };
novel.ListUserStoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserStoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserStoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserStoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.ListAvailableIdeaGenreReq = function() {
    this.tId = new novel.UserId();
};
novel.ListAvailableIdeaGenreReq.prototype._clone = function () { return new novel.ListAvailableIdeaGenreReq(); };
novel.ListAvailableIdeaGenreReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListAvailableIdeaGenreReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListAvailableIdeaGenreReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListAvailableIdeaGenreReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListDefaultTagsReq = function() {
    this.tId = new novel.UserId();
};
novel.ListDefaultTagsReq.prototype._clone = function () { return new novel.ListDefaultTagsReq(); };
novel.ListDefaultTagsReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListDefaultTagsReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListDefaultTagsReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListDefaultTagsReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.DefaultTagsList = function() {
    this.tagsList = new Taf.Vector(new novel.StoryTags());
};
novel.DefaultTagsList.prototype._clone = function () { return new novel.DefaultTagsList(); };
novel.DefaultTagsList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DefaultTagsList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DefaultTagsList.prototype.readFrom = function (is) {
    this.tagsList = is.readVector(0, false, this.tagsList);
};
novel.DefaultTagsList.prototype.writeTo = function (os) {
    os.writeVector(0, this.tagsList);
};

novel.ListStoryChaptersReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.offset = 0;
    this.size = 0;
    this.direction = 0;
};
novel.ListStoryChaptersReq.prototype._clone = function () { return new novel.ListStoryChaptersReq(); };
novel.ListStoryChaptersReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListStoryChaptersReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListStoryChaptersReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
    this.direction = is.readInt32(4, false, this.direction);
};
novel.ListStoryChaptersReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
    os.writeInt32(4, this.direction);
};

novel.ListStoryChapterLinesReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chpaterId = 0;
};
novel.ListStoryChapterLinesReq.prototype._clone = function () { return new novel.ListStoryChapterLinesReq(); };
novel.ListStoryChapterLinesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListStoryChapterLinesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListStoryChapterLinesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chpaterId = is.readInt32(2, false, this.chpaterId);
};
novel.ListStoryChapterLinesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt32(2, this.chpaterId);
};

novel.EditUserStoryReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.title = "";
    this.cover = "";
    this.storyIdea = new novel.StoryIdea();
    this.plots = new novel.StoryTags();
    this.emotions = new novel.StoryTags();
    this.spaceTimes = new novel.StoryTags();
    this.locations = new novel.StoryTags();
    this.others = "";
    this.synopsis = "";
    this.completedState = 0;
};
novel.EditUserStoryReq.prototype._clone = function () { return new novel.EditUserStoryReq(); };
novel.EditUserStoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.EditUserStoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.EditUserStoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.title = is.readString(2, false, this.title);
    this.cover = is.readString(3, false, this.cover);
    this.storyIdea = is.readStruct(4, false, this.storyIdea);
    this.plots = is.readStruct(5, false, this.plots);
    this.emotions = is.readStruct(6, false, this.emotions);
    this.spaceTimes = is.readStruct(7, false, this.spaceTimes);
    this.locations = is.readStruct(8, false, this.locations);
    this.others = is.readString(9, false, this.others);
    this.synopsis = is.readString(10, false, this.synopsis);
    this.completedState = is.readInt32(11, false, this.completedState);
};
novel.EditUserStoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeString(2, this.title);
    os.writeString(3, this.cover);
    os.writeStruct(4, this.storyIdea);
    os.writeStruct(5, this.plots);
    os.writeStruct(6, this.emotions);
    os.writeStruct(7, this.spaceTimes);
    os.writeStruct(8, this.locations);
    os.writeString(9, this.others);
    os.writeString(10, this.synopsis);
    os.writeInt32(11, this.completedState);
};

novel.EditStoryChapterReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chapterId = 0;
    this.title = "";
};
novel.EditStoryChapterReq.prototype._clone = function () { return new novel.EditStoryChapterReq(); };
novel.EditStoryChapterReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.EditStoryChapterReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.EditStoryChapterReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.title = is.readString(3, false, this.title);
};
novel.EditStoryChapterReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt64(2, this.chapterId);
    os.writeString(3, this.title);
};

novel.DeleteStoryChapterReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chapterId = 0;
};
novel.DeleteStoryChapterReq.prototype._clone = function () { return new novel.DeleteStoryChapterReq(); };
novel.DeleteStoryChapterReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DeleteStoryChapterReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DeleteStoryChapterReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
};
novel.DeleteStoryChapterReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt64(2, this.chapterId);
};

novel.EditStoryChapterLinesReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chapterId = 0;
    this.lines = new Taf.Vector(new Taf.STRING());
};
novel.EditStoryChapterLinesReq.prototype._clone = function () { return new novel.EditStoryChapterLinesReq(); };
novel.EditStoryChapterLinesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.EditStoryChapterLinesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.EditStoryChapterLinesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chapterId = is.readInt32(2, false, this.chapterId);
    this.lines = is.readVector(3, false, this.lines);
};
novel.EditStoryChapterLinesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt32(2, this.chapterId);
    os.writeVector(3, this.lines);
};

novel.ListStoryReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
    this.sort = 0;
};
novel.ListStoryReq.prototype._clone = function () { return new novel.ListStoryReq(); };
novel.ListStoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListStoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListStoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
    this.sort = is.readInt32(3, false, this.sort);
};
novel.ListStoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
    os.writeInt32(3, this.sort);
};

novel.GetStoryByIdReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
};
novel.GetStoryByIdReq.prototype._clone = function () { return new novel.GetStoryByIdReq(); };
novel.GetStoryByIdReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetStoryByIdReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetStoryByIdReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
};
novel.GetStoryByIdReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
};

novel.ImportWtoryChapter = function() {
    this.chapterTitle = "";
    this.content = "";
};
novel.ImportWtoryChapter.prototype._clone = function () { return new novel.ImportWtoryChapter(); };
novel.ImportWtoryChapter.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ImportWtoryChapter.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ImportWtoryChapter.prototype.readFrom = function (is) {
    this.chapterTitle = is.readString(0, false, this.chapterTitle);
    this.content = is.readString(1, false, this.content);
};
novel.ImportWtoryChapter.prototype.writeTo = function (os) {
    os.writeString(0, this.chapterTitle);
    os.writeString(1, this.content);
};

novel.ImportWtoryReq = function() {
    this.tId = new novel.UserId();
    this.title = "";
    this.chapters = new Taf.Vector(new novel.ImportWtoryChapter());
};
novel.ImportWtoryReq.prototype._clone = function () { return new novel.ImportWtoryReq(); };
novel.ImportWtoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ImportWtoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ImportWtoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.title = is.readString(1, false, this.title);
    this.chapters = is.readVector(2, false, this.chapters);
};
novel.ImportWtoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.title);
    os.writeVector(2, this.chapters);
};

novel.ImportWtoryRsp = function() {
    this.story = new novel.Story();
};
novel.ImportWtoryRsp.prototype._clone = function () { return new novel.ImportWtoryRsp(); };
novel.ImportWtoryRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ImportWtoryRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ImportWtoryRsp.prototype.readFrom = function (is) {
    this.story = is.readStruct(0, false, this.story);
};
novel.ImportWtoryRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.story);
};

novel.SyncStoryReadProgressReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chapterId = 0;
};
novel.SyncStoryReadProgressReq.prototype._clone = function () { return new novel.SyncStoryReadProgressReq(); };
novel.SyncStoryReadProgressReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SyncStoryReadProgressReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SyncStoryReadProgressReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
};
novel.SyncStoryReadProgressReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt64(2, this.chapterId);
};

novel.ListUserStoryHistoryReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserStoryHistoryReq.prototype._clone = function () { return new novel.ListUserStoryHistoryReq(); };
novel.ListUserStoryHistoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserStoryHistoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserStoryHistoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserStoryHistoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.RemoveStoryReadHistoryReq = function() {
    this.tId = new novel.UserId();
    this.storyIds = new Taf.Vector(new Taf.INT64());
};
novel.RemoveStoryReadHistoryReq.prototype._clone = function () { return new novel.RemoveStoryReadHistoryReq(); };
novel.RemoveStoryReadHistoryReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.RemoveStoryReadHistoryReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.RemoveStoryReadHistoryReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyIds = is.readVector(1, false, this.storyIds);
};
novel.RemoveStoryReadHistoryReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeVector(1, this.storyIds);
};

novel.SseGenerateStoryPropertyByAiReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.property = 0;
};
novel.SseGenerateStoryPropertyByAiReq.prototype._clone = function () { return new novel.SseGenerateStoryPropertyByAiReq(); };
novel.SseGenerateStoryPropertyByAiReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryPropertyByAiReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryPropertyByAiReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.property = is.readInt32(2, false, this.property);
};
novel.SseGenerateStoryPropertyByAiReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt32(2, this.property);
};

novel.SseGenerateStoryPropertyByAiRsp = function() {
    this.type = 0;
    this.words = "";
};
novel.SseGenerateStoryPropertyByAiRsp.prototype._clone = function () { return new novel.SseGenerateStoryPropertyByAiRsp(); };
novel.SseGenerateStoryPropertyByAiRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryPropertyByAiRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryPropertyByAiRsp.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.words = is.readString(1, false, this.words);
};
novel.SseGenerateStoryPropertyByAiRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.words);
};

novel.SseGenerateStoryWordsByAiReq = function() {
    this.tId = new novel.UserId();
    this.storyId = 0;
    this.chapterId = 0;
    this.words = "";
    this.before = "";
    this.after = "";
    this.custom = "";
    this.function = 0;
};
novel.SseGenerateStoryWordsByAiReq.prototype._clone = function () { return new novel.SseGenerateStoryWordsByAiReq(); };
novel.SseGenerateStoryWordsByAiReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryWordsByAiReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryWordsByAiReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.storyId = is.readInt64(1, false, this.storyId);
    this.chapterId = is.readInt64(2, false, this.chapterId);
    this.words = is.readString(3, false, this.words);
    this.before = is.readString(4, false, this.before);
    this.after = is.readString(5, false, this.after);
    this.custom = is.readString(6, false, this.custom);
    this.function = is.readInt32(7, false, this.function);
};
novel.SseGenerateStoryWordsByAiReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt64(1, this.storyId);
    os.writeInt64(2, this.chapterId);
    os.writeString(3, this.words);
    os.writeString(4, this.before);
    os.writeString(5, this.after);
    os.writeString(6, this.custom);
    os.writeInt32(7, this.function);
};

novel.SseGenerateStoryWordsByAiRsp = function() {
    this.type = 0;
    this.words = "";
};
novel.SseGenerateStoryWordsByAiRsp.prototype._clone = function () { return new novel.SseGenerateStoryWordsByAiRsp(); };
novel.SseGenerateStoryWordsByAiRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SseGenerateStoryWordsByAiRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SseGenerateStoryWordsByAiRsp.prototype.readFrom = function (is) {
    this.type = is.readInt32(0, false, this.type);
    this.words = is.readString(1, false, this.words);
};
novel.SseGenerateStoryWordsByAiRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.type);
    os.writeString(1, this.words);
};

novel.OnlineUserInfo = function() {
    this.sIp = "";
    this.iPort = 0;
    this.sApp = "";
    this.uid = 0;
};
novel.OnlineUserInfo.prototype._clone = function () { return new novel.OnlineUserInfo(); };
novel.OnlineUserInfo.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.OnlineUserInfo.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.OnlineUserInfo.prototype.readFrom = function (is) {
    this.sIp = is.readString(0, false, this.sIp);
    this.iPort = is.readInt32(1, false, this.iPort);
    this.sApp = is.readString(2, false, this.sApp);
    this.uid = is.readInt64(3, false, this.uid);
};
novel.OnlineUserInfo.prototype.writeTo = function (os) {
    os.writeString(0, this.sIp);
    os.writeInt32(1, this.iPort);
    os.writeString(2, this.sApp);
    os.writeInt64(3, this.uid);
};

novel.UserTaskReward = function() {
    this.spu = new novel.GoodsSpu();
    this.quantity = 0;
    this.insteadSpu = new novel.GoodsSpu();
    this.insteadQuantity = 0;
    this.useInstead = false;
    this.rewardFor = 0;
};
novel.UserTaskReward.prototype._clone = function () { return new novel.UserTaskReward(); };
novel.UserTaskReward.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserTaskReward.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserTaskReward.prototype.readFrom = function (is) {
    this.spu = is.readStruct(0, false, this.spu);
    this.quantity = is.readInt32(1, false, this.quantity);
    this.insteadSpu = is.readStruct(2, false, this.insteadSpu);
    this.insteadQuantity = is.readInt32(3, false, this.insteadQuantity);
    this.useInstead = is.readBoolean(4, false, this.useInstead);
    this.rewardFor = is.readInt32(5, false, this.rewardFor);
};
novel.UserTaskReward.prototype.writeTo = function (os) {
    os.writeStruct(0, this.spu);
    os.writeInt32(1, this.quantity);
    os.writeStruct(2, this.insteadSpu);
    os.writeInt32(3, this.insteadQuantity);
    os.writeBoolean(4, this.useInstead);
    os.writeInt32(5, this.rewardFor);
};

novel.UserTask = function() {
    this.id = 0;
    this.type = 0;
    this.drawState = 0;
    this.rewards = new Taf.Vector(new novel.UserTaskReward());
    this.finishCount = 0;
    this.currentProcess = 0;
    this.compensateable = 0;
    this.getDay = 0;
    this.drawWay = 0;
    this.readingMinutes = 0;
    this.pkg = new novel.RechargePackage();
    this.postAdTask = 0;
    this.adRewards = new Taf.Vector(new novel.UserTaskReward());
    this.adTaskDrawState = 0;
    this.postAdConfig = "";
};
novel.UserTask.prototype._clone = function () { return new novel.UserTask(); };
novel.UserTask.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserTask.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserTask.prototype.readFrom = function (is) {
    this.id = is.readInt32(0, false, this.id);
    this.type = is.readInt32(1, false, this.type);
    this.drawState = is.readInt32(2, false, this.drawState);
    this.rewards = is.readVector(3, false, this.rewards);
    this.finishCount = is.readInt32(4, false, this.finishCount);
    this.currentProcess = is.readInt32(5, false, this.currentProcess);
    this.compensateable = is.readInt32(6, false, this.compensateable);
    this.getDay = is.readInt32(7, false, this.getDay);
    this.drawWay = is.readInt32(8, false, this.drawWay);
    this.readingMinutes = is.readInt32(9, false, this.readingMinutes);
    this.pkg = is.readStruct(10, false, this.pkg);
    this.postAdTask = is.readInt32(11, false, this.postAdTask);
    this.adRewards = is.readVector(12, false, this.adRewards);
    this.adTaskDrawState = is.readInt32(13, false, this.adTaskDrawState);
    this.postAdConfig = is.readString(14, false, this.postAdConfig);
};
novel.UserTask.prototype.writeTo = function (os) {
    os.writeInt32(0, this.id);
    os.writeInt32(1, this.type);
    os.writeInt32(2, this.drawState);
    os.writeVector(3, this.rewards);
    os.writeInt32(4, this.finishCount);
    os.writeInt32(5, this.currentProcess);
    os.writeInt32(6, this.compensateable);
    os.writeInt32(7, this.getDay);
    os.writeInt32(8, this.drawWay);
    os.writeInt32(9, this.readingMinutes);
    os.writeStruct(10, this.pkg);
    os.writeInt32(11, this.postAdTask);
    os.writeVector(12, this.adRewards);
    os.writeInt32(13, this.adTaskDrawState);
    os.writeString(14, this.postAdConfig);
};

novel.UserTaskList = function() {
    this.list = new Taf.Vector(new novel.UserTask());
    this.hasMore = true;
};
novel.UserTaskList.prototype._clone = function () { return new novel.UserTaskList(); };
novel.UserTaskList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserTaskList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserTaskList.prototype.readFrom = function (is) {
    this.list = is.readVector(0, false, this.list);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserTaskList.prototype.writeTo = function (os) {
    os.writeVector(0, this.list);
    os.writeBoolean(1, this.hasMore);
};

novel.ListContinuousLogin7DaysTaskReq = function() {
    this.tId = new novel.UserId();
};
novel.ListContinuousLogin7DaysTaskReq.prototype._clone = function () { return new novel.ListContinuousLogin7DaysTaskReq(); };
novel.ListContinuousLogin7DaysTaskReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListContinuousLogin7DaysTaskReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListContinuousLogin7DaysTaskReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListContinuousLogin7DaysTaskReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListContinuousLogin7DaysTaskRsp = function() {
    this.list = new novel.UserTaskList();
    this.continuousDay = 0;
};
novel.ListContinuousLogin7DaysTaskRsp.prototype._clone = function () { return new novel.ListContinuousLogin7DaysTaskRsp(); };
novel.ListContinuousLogin7DaysTaskRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListContinuousLogin7DaysTaskRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListContinuousLogin7DaysTaskRsp.prototype.readFrom = function (is) {
    this.list = is.readStruct(0, false, this.list);
    this.continuousDay = is.readInt32(1, false, this.continuousDay);
};
novel.ListContinuousLogin7DaysTaskRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.list);
    os.writeInt32(1, this.continuousDay);
};

novel.GetUndrawTaskRewardCountReq = function() {
    this.tId = new novel.UserId();
};
novel.GetUndrawTaskRewardCountReq.prototype._clone = function () { return new novel.GetUndrawTaskRewardCountReq(); };
novel.GetUndrawTaskRewardCountReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetUndrawTaskRewardCountReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetUndrawTaskRewardCountReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetUndrawTaskRewardCountReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.UndrawRewardCountRsp = function() {
    this.count = 0;
};
novel.UndrawRewardCountRsp.prototype._clone = function () { return new novel.UndrawRewardCountRsp(); };
novel.UndrawRewardCountRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UndrawRewardCountRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UndrawRewardCountRsp.prototype.readFrom = function (is) {
    this.count = is.readInt32(0, false, this.count);
};
novel.UndrawRewardCountRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.count);
};

novel.DrawRewardByTaskReq = function() {
    this.tId = new novel.UserId();
    this.taskId = 0;
};
novel.DrawRewardByTaskReq.prototype._clone = function () { return new novel.DrawRewardByTaskReq(); };
novel.DrawRewardByTaskReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DrawRewardByTaskReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DrawRewardByTaskReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.taskId = is.readInt32(1, false, this.taskId);
};
novel.DrawRewardByTaskReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.taskId);
};

novel.DrawAnyVipLoginTaskRewardReq = function() {
    this.tId = new novel.UserId();
};
novel.DrawAnyVipLoginTaskRewardReq.prototype._clone = function () { return new novel.DrawAnyVipLoginTaskRewardReq(); };
novel.DrawAnyVipLoginTaskRewardReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DrawAnyVipLoginTaskRewardReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DrawAnyVipLoginTaskRewardReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.DrawAnyVipLoginTaskRewardReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.DrawRewardByTaskRsp = function() {
    this.taskId = 0;
    this.rewards = new Taf.Vector(new novel.UserTaskReward());
    this.retCode = 0;
    this.taskType = 0;
    this.postAdTaskRewards = new Taf.Vector(new novel.UserTaskReward());
};
novel.DrawRewardByTaskRsp.prototype._clone = function () { return new novel.DrawRewardByTaskRsp(); };
novel.DrawRewardByTaskRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DrawRewardByTaskRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DrawRewardByTaskRsp.prototype.readFrom = function (is) {
    this.taskId = is.readInt32(0, false, this.taskId);
    this.rewards = is.readVector(1, false, this.rewards);
    this.retCode = is.readInt32(2, false, this.retCode);
    this.taskType = is.readInt32(3, false, this.taskType);
    this.postAdTaskRewards = is.readVector(4, false, this.postAdTaskRewards);
};
novel.DrawRewardByTaskRsp.prototype.writeTo = function (os) {
    os.writeInt32(0, this.taskId);
    os.writeVector(1, this.rewards);
    os.writeInt32(2, this.retCode);
    os.writeInt32(3, this.taskType);
    os.writeVector(4, this.postAdTaskRewards);
};

novel.DrawRewardByTaskRspList = function() {
    this.rsps = new Taf.Vector(new novel.DrawRewardByTaskRsp());
};
novel.DrawRewardByTaskRspList.prototype._clone = function () { return new novel.DrawRewardByTaskRspList(); };
novel.DrawRewardByTaskRspList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DrawRewardByTaskRspList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DrawRewardByTaskRspList.prototype.readFrom = function (is) {
    this.rsps = is.readVector(0, false, this.rsps);
};
novel.DrawRewardByTaskRspList.prototype.writeTo = function (os) {
    os.writeVector(0, this.rsps);
};

novel.ListDailyTaskReq = function() {
    this.tId = new novel.UserId();
};
novel.ListDailyTaskReq.prototype._clone = function () { return new novel.ListDailyTaskReq(); };
novel.ListDailyTaskReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListDailyTaskReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListDailyTaskReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ListDailyTaskReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListDailyTaskRsp = function() {
    this.list = new novel.UserTaskList();
};
novel.ListDailyTaskRsp.prototype._clone = function () { return new novel.ListDailyTaskRsp(); };
novel.ListDailyTaskRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListDailyTaskRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListDailyTaskRsp.prototype.readFrom = function (is) {
    this.list = is.readStruct(0, false, this.list);
};
novel.ListDailyTaskRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.list);
};

novel.ReportReadingDurationReq = function() {
    this.tId = new novel.UserId();
    this.readingMinutes = 0;
};
novel.ReportReadingDurationReq.prototype._clone = function () { return new novel.ReportReadingDurationReq(); };
novel.ReportReadingDurationReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReportReadingDurationReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReportReadingDurationReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.readingMinutes = is.readInt32(1, false, this.readingMinutes);
};
novel.ReportReadingDurationReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.readingMinutes);
};

novel.GetInviteCodeBeActivatedTaskReq = function() {
    this.tId = new novel.UserId();
};
novel.GetInviteCodeBeActivatedTaskReq.prototype._clone = function () { return new novel.GetInviteCodeBeActivatedTaskReq(); };
novel.GetInviteCodeBeActivatedTaskReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetInviteCodeBeActivatedTaskReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetInviteCodeBeActivatedTaskReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetInviteCodeBeActivatedTaskReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.InviteCodeBeActivatedTask = function() {
    this.task = new novel.UserTask();
    this.inviteCode = "";
};
novel.InviteCodeBeActivatedTask.prototype._clone = function () { return new novel.InviteCodeBeActivatedTask(); };
novel.InviteCodeBeActivatedTask.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.InviteCodeBeActivatedTask.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.InviteCodeBeActivatedTask.prototype.readFrom = function (is) {
    this.task = is.readStruct(0, false, this.task);
    this.inviteCode = is.readString(1, false, this.inviteCode);
};
novel.InviteCodeBeActivatedTask.prototype.writeTo = function (os) {
    os.writeStruct(0, this.task);
    os.writeString(1, this.inviteCode);
};

novel.GetActiveInviteCodeTaskReq = function() {
    this.tId = new novel.UserId();
};
novel.GetActiveInviteCodeTaskReq.prototype._clone = function () { return new novel.GetActiveInviteCodeTaskReq(); };
novel.GetActiveInviteCodeTaskReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetActiveInviteCodeTaskReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetActiveInviteCodeTaskReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetActiveInviteCodeTaskReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ActiveInviteCodeTaskRsp = function() {
    this.task = new novel.UserTask();
};
novel.ActiveInviteCodeTaskRsp.prototype._clone = function () { return new novel.ActiveInviteCodeTaskRsp(); };
novel.ActiveInviteCodeTaskRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ActiveInviteCodeTaskRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ActiveInviteCodeTaskRsp.prototype.readFrom = function (is) {
    this.task = is.readStruct(0, false, this.task);
};
novel.ActiveInviteCodeTaskRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.task);
};

novel.ReportShareTaskProcessReq = function() {
    this.tId = new novel.UserId();
};
novel.ReportShareTaskProcessReq.prototype._clone = function () { return new novel.ReportShareTaskProcessReq(); };
novel.ReportShareTaskProcessReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReportShareTaskProcessReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReportShareTaskProcessReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ReportShareTaskProcessReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ReportWatchAdTimesReq = function() {
    this.tId = new novel.UserId();
    this.times = 0;
};
novel.ReportWatchAdTimesReq.prototype._clone = function () { return new novel.ReportWatchAdTimesReq(); };
novel.ReportWatchAdTimesReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReportWatchAdTimesReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReportWatchAdTimesReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.times = is.readInt32(1, false, this.times);
};
novel.ReportWatchAdTimesReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.times);
};

novel.ReportPostAdTaskProcessReq = function() {
    this.tId = new novel.UserId();
    this.taskId = 0;
};
novel.ReportPostAdTaskProcessReq.prototype._clone = function () { return new novel.ReportPostAdTaskProcessReq(); };
novel.ReportPostAdTaskProcessReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReportPostAdTaskProcessReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReportPostAdTaskProcessReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.taskId = is.readInt32(1, false, this.taskId);
};
novel.ReportPostAdTaskProcessReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.taskId);
};

novel.QueryUserInviteTaskOverviewReq = function() {
    this.tId = new novel.UserId();
};
novel.QueryUserInviteTaskOverviewReq.prototype._clone = function () { return new novel.QueryUserInviteTaskOverviewReq(); };
novel.QueryUserInviteTaskOverviewReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryUserInviteTaskOverviewReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryUserInviteTaskOverviewReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.QueryUserInviteTaskOverviewReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.UserInviteTaskOverview = function() {
    this.inviteUsers = 0;
    this.buyChapterGems = 0;
    this.rechargeGems = 0;
    this.inviteSuccessGems = 0;
};
novel.UserInviteTaskOverview.prototype._clone = function () { return new novel.UserInviteTaskOverview(); };
novel.UserInviteTaskOverview.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserInviteTaskOverview.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserInviteTaskOverview.prototype.readFrom = function (is) {
    this.inviteUsers = is.readInt32(0, false, this.inviteUsers);
    this.buyChapterGems = is.readInt32(1, false, this.buyChapterGems);
    this.rechargeGems = is.readInt32(2, false, this.rechargeGems);
    this.inviteSuccessGems = is.readInt32(3, false, this.inviteSuccessGems);
};
novel.UserInviteTaskOverview.prototype.writeTo = function (os) {
    os.writeInt32(0, this.inviteUsers);
    os.writeInt32(1, this.buyChapterGems);
    os.writeInt32(2, this.rechargeGems);
    os.writeInt32(3, this.inviteSuccessGems);
};

novel.ReportTurnOnPushReq = function() {
    this.tId = new novel.UserId();
};
novel.ReportTurnOnPushReq.prototype._clone = function () { return new novel.ReportTurnOnPushReq(); };
novel.ReportTurnOnPushReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ReportTurnOnPushReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ReportTurnOnPushReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.ReportTurnOnPushReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.DrawAiCreatorLoginTaskRewardReq = function() {
    this.tId = new novel.UserId();
};
novel.DrawAiCreatorLoginTaskRewardReq.prototype._clone = function () { return new novel.DrawAiCreatorLoginTaskRewardReq(); };
novel.DrawAiCreatorLoginTaskRewardReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.DrawAiCreatorLoginTaskRewardReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.DrawAiCreatorLoginTaskRewardReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.DrawAiCreatorLoginTaskRewardReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.SearchBooksReq = function() {
    this.tId = new novel.UserId();
    this.word = "";
    this.offset = 0;
    this.size = 0;
};
novel.SearchBooksReq.prototype._clone = function () { return new novel.SearchBooksReq(); };
novel.SearchBooksReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SearchBooksReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SearchBooksReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.word = is.readString(1, false, this.word);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.SearchBooksReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.word);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.SearchReq = function() {
    this.tId = new novel.UserId();
    this.word = "";
    this.offset = 0;
    this.size = 0;
};
novel.SearchReq.prototype._clone = function () { return new novel.SearchReq(); };
novel.SearchReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.SearchReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.SearchReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.word = is.readString(1, false, this.word);
    this.offset = is.readInt32(2, false, this.offset);
    this.size = is.readInt32(3, false, this.size);
};
novel.SearchReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeString(1, this.word);
    os.writeInt32(2, this.offset);
    os.writeInt32(3, this.size);
};

novel.GetLatestGreedyCatGameReq = function() {
    this.tId = new novel.UserId();
};
novel.GetLatestGreedyCatGameReq.prototype._clone = function () { return new novel.GetLatestGreedyCatGameReq(); };
novel.GetLatestGreedyCatGameReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetLatestGreedyCatGameReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetLatestGreedyCatGameReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetLatestGreedyCatGameReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.GreedyCatBettingItem = function() {
    this.idx = 0;
    this.odds = 0;
    this.customConfig = "";
};
novel.GreedyCatBettingItem.prototype._clone = function () { return new novel.GreedyCatBettingItem(); };
novel.GreedyCatBettingItem.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GreedyCatBettingItem.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GreedyCatBettingItem.prototype.readFrom = function (is) {
    this.idx = is.readInt32(0, false, this.idx);
    this.odds = is.readInt32(1, false, this.odds);
    this.customConfig = is.readString(2, false, this.customConfig);
};
novel.GreedyCatBettingItem.prototype.writeTo = function (os) {
    os.writeInt32(0, this.idx);
    os.writeInt32(1, this.odds);
    os.writeString(2, this.customConfig);
};

novel.GreedyCatGameConfig = function() {
    this.gid = 0;
    this.gameStatus = 0;
    this.items = new Taf.Vector(new novel.GreedyCatBettingItem());
    this.bettingAmounts = new Taf.Vector(new Taf.INT32());
    this.bettingStageSeconds = 0;
    this.waitingDrawingSeconds = 0;
    this.attachActivityStart = 0;
    this.attachActivityEnd = 0;
    this.attachActivityPeriod = 0;
    this.officialMessageThreshold = 0;
};
novel.GreedyCatGameConfig.prototype._clone = function () { return new novel.GreedyCatGameConfig(); };
novel.GreedyCatGameConfig.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GreedyCatGameConfig.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GreedyCatGameConfig.prototype.readFrom = function (is) {
    this.gid = is.readInt32(0, false, this.gid);
    this.gameStatus = is.readInt32(1, false, this.gameStatus);
    this.items = is.readVector(2, false, this.items);
    this.bettingAmounts = is.readVector(3, false, this.bettingAmounts);
    this.bettingStageSeconds = is.readInt32(4, false, this.bettingStageSeconds);
    this.waitingDrawingSeconds = is.readInt32(5, false, this.waitingDrawingSeconds);
    this.attachActivityStart = is.readInt64(6, false, this.attachActivityStart);
    this.attachActivityEnd = is.readInt64(7, false, this.attachActivityEnd);
    this.attachActivityPeriod = is.readInt32(8, false, this.attachActivityPeriod);
    this.officialMessageThreshold = is.readInt32(9, false, this.officialMessageThreshold);
};
novel.GreedyCatGameConfig.prototype.writeTo = function (os) {
    os.writeInt32(0, this.gid);
    os.writeInt32(1, this.gameStatus);
    os.writeVector(2, this.items);
    os.writeVector(3, this.bettingAmounts);
    os.writeInt32(4, this.bettingStageSeconds);
    os.writeInt32(5, this.waitingDrawingSeconds);
    os.writeInt64(6, this.attachActivityStart);
    os.writeInt64(7, this.attachActivityEnd);
    os.writeInt32(8, this.attachActivityPeriod);
    os.writeInt32(9, this.officialMessageThreshold);
};

novel.GreedyCatGameRound = function() {
    this.gid = 0;
    this.day = "";
    this.dayRound = 0;
    this.totalRound = 0;
    this.runStatus = 0;
    this.hitOdds = 0;
    this.hitIdx = 0;
};
novel.GreedyCatGameRound.prototype._clone = function () { return new novel.GreedyCatGameRound(); };
novel.GreedyCatGameRound.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GreedyCatGameRound.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GreedyCatGameRound.prototype.readFrom = function (is) {
    this.gid = is.readInt32(0, false, this.gid);
    this.day = is.readString(1, false, this.day);
    this.dayRound = is.readInt32(2, false, this.dayRound);
    this.totalRound = is.readInt32(3, false, this.totalRound);
    this.runStatus = is.readInt32(4, false, this.runStatus);
    this.hitOdds = is.readInt32(5, false, this.hitOdds);
    this.hitIdx = is.readInt32(6, false, this.hitIdx);
};
novel.GreedyCatGameRound.prototype.writeTo = function (os) {
    os.writeInt32(0, this.gid);
    os.writeString(1, this.day);
    os.writeInt32(2, this.dayRound);
    os.writeInt32(3, this.totalRound);
    os.writeInt32(4, this.runStatus);
    os.writeInt32(5, this.hitOdds);
    os.writeInt32(6, this.hitIdx);
};

novel.LatestGreedyCatGameRsp = function() {
    this.config = new novel.GreedyCatGameConfig();
    this.latestRound = new novel.GreedyCatGameRound();
};
novel.LatestGreedyCatGameRsp.prototype._clone = function () { return new novel.LatestGreedyCatGameRsp(); };
novel.LatestGreedyCatGameRsp.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LatestGreedyCatGameRsp.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LatestGreedyCatGameRsp.prototype.readFrom = function (is) {
    this.config = is.readStruct(0, false, this.config);
    this.latestRound = is.readStruct(1, false, this.latestRound);
};
novel.LatestGreedyCatGameRsp.prototype.writeTo = function (os) {
    os.writeStruct(0, this.config);
    os.writeStruct(1, this.latestRound);
};

novel.getGreedyCatGameConfigReq = function() {
    this.tId = new novel.UserId();
};
novel.getGreedyCatGameConfigReq.prototype._clone = function () { return new novel.getGreedyCatGameConfigReq(); };
novel.getGreedyCatGameConfigReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.getGreedyCatGameConfigReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.getGreedyCatGameConfigReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.getGreedyCatGameConfigReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.Bet4GreedyCatReq = function() {
    this.tId = new novel.UserId();
    this.betAmount = 0;
    this.betIdx = 0;
};
novel.Bet4GreedyCatReq.prototype._clone = function () { return new novel.Bet4GreedyCatReq(); };
novel.Bet4GreedyCatReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.Bet4GreedyCatReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.Bet4GreedyCatReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.betAmount = is.readInt32(1, false, this.betAmount);
    this.betIdx = is.readInt32(2, false, this.betIdx);
};
novel.Bet4GreedyCatReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.betAmount);
    os.writeInt32(2, this.betIdx);
};

novel.GameRoundOfGreedyCatList = function() {
    this.rounds = new Taf.Vector(new novel.GreedyCatGameRound());
    this.hasMore = true;
};
novel.GameRoundOfGreedyCatList.prototype._clone = function () { return new novel.GameRoundOfGreedyCatList(); };
novel.GameRoundOfGreedyCatList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GameRoundOfGreedyCatList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GameRoundOfGreedyCatList.prototype.readFrom = function (is) {
    this.rounds = is.readVector(0, false, this.rounds);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.GameRoundOfGreedyCatList.prototype.writeTo = function (os) {
    os.writeVector(0, this.rounds);
    os.writeBoolean(1, this.hasMore);
};

novel.ListLatestRoundOfGreedyCatReq = function() {
    this.tId = new novel.UserId();
    this.size = 0;
};
novel.ListLatestRoundOfGreedyCatReq.prototype._clone = function () { return new novel.ListLatestRoundOfGreedyCatReq(); };
novel.ListLatestRoundOfGreedyCatReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListLatestRoundOfGreedyCatReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListLatestRoundOfGreedyCatReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.size = is.readInt32(1, false, this.size);
};
novel.ListLatestRoundOfGreedyCatReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.size);
};

novel.ListUserBetFlowOfGreedyCatReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserBetFlowOfGreedyCatReq.prototype._clone = function () { return new novel.ListUserBetFlowOfGreedyCatReq(); };
novel.ListUserBetFlowOfGreedyCatReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserBetFlowOfGreedyCatReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserBetFlowOfGreedyCatReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserBetFlowOfGreedyCatReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.UserBetFlowOfGreedyCat = function() {
    this.totalRound = 0;
    this.dayRound = 0;
    this.day = "";
    this.flowType = 0;
    this.betAmount = 0;
    this.betIdx = 0;
    this.betOdds = 0;
    this.hitAmount = 0;
    this.createTime = 0;
};
novel.UserBetFlowOfGreedyCat.prototype._clone = function () { return new novel.UserBetFlowOfGreedyCat(); };
novel.UserBetFlowOfGreedyCat.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserBetFlowOfGreedyCat.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserBetFlowOfGreedyCat.prototype.readFrom = function (is) {
    this.totalRound = is.readInt32(0, false, this.totalRound);
    this.dayRound = is.readInt32(1, false, this.dayRound);
    this.day = is.readString(2, false, this.day);
    this.flowType = is.readInt32(3, false, this.flowType);
    this.betAmount = is.readInt32(4, false, this.betAmount);
    this.betIdx = is.readInt32(5, false, this.betIdx);
    this.betOdds = is.readInt32(6, false, this.betOdds);
    this.hitAmount = is.readInt32(7, false, this.hitAmount);
    this.createTime = is.readInt64(8, false, this.createTime);
};
novel.UserBetFlowOfGreedyCat.prototype.writeTo = function (os) {
    os.writeInt32(0, this.totalRound);
    os.writeInt32(1, this.dayRound);
    os.writeString(2, this.day);
    os.writeInt32(3, this.flowType);
    os.writeInt32(4, this.betAmount);
    os.writeInt32(5, this.betIdx);
    os.writeInt32(6, this.betOdds);
    os.writeInt32(7, this.hitAmount);
    os.writeInt64(8, this.createTime);
};

novel.UserBetFlowOfGreedyCatList = function() {
    this.flows = new Taf.Vector(new novel.UserBetFlowOfGreedyCat());
    this.hasMore = true;
};
novel.UserBetFlowOfGreedyCatList.prototype._clone = function () { return new novel.UserBetFlowOfGreedyCatList(); };
novel.UserBetFlowOfGreedyCatList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserBetFlowOfGreedyCatList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserBetFlowOfGreedyCatList.prototype.readFrom = function (is) {
    this.flows = is.readVector(0, false, this.flows);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserBetFlowOfGreedyCatList.prototype.writeTo = function (os) {
    os.writeVector(0, this.flows);
    os.writeBoolean(1, this.hasMore);
};

novel.PrizeWheelOption = function() {
    this.amount = 0;
    this.times = 0;
};
novel.PrizeWheelOption.prototype._clone = function () { return new novel.PrizeWheelOption(); };
novel.PrizeWheelOption.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PrizeWheelOption.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PrizeWheelOption.prototype.readFrom = function (is) {
    this.amount = is.readInt32(0, false, this.amount);
    this.times = is.readInt32(1, false, this.times);
};
novel.PrizeWheelOption.prototype.writeTo = function (os) {
    os.writeInt32(0, this.amount);
    os.writeInt32(1, this.times);
};

novel.PrizeWheelItem = function() {
    this.idx = 0;
    this.spu = new novel.GoodsSpu();
    this.value = 0;
    this.labelValue = 0;
};
novel.PrizeWheelItem.prototype._clone = function () { return new novel.PrizeWheelItem(); };
novel.PrizeWheelItem.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PrizeWheelItem.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PrizeWheelItem.prototype.readFrom = function (is) {
    this.idx = is.readInt32(0, false, this.idx);
    this.spu = is.readStruct(1, false, this.spu);
    this.value = is.readInt32(2, false, this.value);
    this.labelValue = is.readInt32(3, false, this.labelValue);
};
novel.PrizeWheelItem.prototype.writeTo = function (os) {
    os.writeInt32(0, this.idx);
    os.writeStruct(1, this.spu);
    os.writeInt32(2, this.value);
    os.writeInt32(3, this.labelValue);
};

novel.PrizeWheelConfig = function() {
    this.gid = 0;
    this.gameStatus = 0;
    this.items = new Taf.Vector(new novel.PrizeWheelItem());
    this.options = new Taf.Vector(new novel.PrizeWheelOption());
    this.officialMessageThreshold = 0;
};
novel.PrizeWheelConfig.prototype._clone = function () { return new novel.PrizeWheelConfig(); };
novel.PrizeWheelConfig.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PrizeWheelConfig.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PrizeWheelConfig.prototype.readFrom = function (is) {
    this.gid = is.readInt32(0, false, this.gid);
    this.gameStatus = is.readInt32(1, false, this.gameStatus);
    this.items = is.readVector(2, false, this.items);
    this.options = is.readVector(3, false, this.options);
    this.officialMessageThreshold = is.readInt32(4, false, this.officialMessageThreshold);
};
novel.PrizeWheelConfig.prototype.writeTo = function (os) {
    os.writeInt32(0, this.gid);
    os.writeInt32(1, this.gameStatus);
    os.writeVector(2, this.items);
    os.writeVector(3, this.options);
    os.writeInt32(4, this.officialMessageThreshold);
};

novel.GetPrizeWheelReq = function() {
    this.tId = new novel.UserId();
};
novel.GetPrizeWheelReq.prototype._clone = function () { return new novel.GetPrizeWheelReq(); };
novel.GetPrizeWheelReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.GetPrizeWheelReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.GetPrizeWheelReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
};
novel.GetPrizeWheelReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
};

novel.ListLatestPrizeWheelBigBonusReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListLatestPrizeWheelBigBonusReq.prototype._clone = function () { return new novel.ListLatestPrizeWheelBigBonusReq(); };
novel.ListLatestPrizeWheelBigBonusReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListLatestPrizeWheelBigBonusReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListLatestPrizeWheelBigBonusReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListLatestPrizeWheelBigBonusReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.LatestPrizeWheelBigBonus = function() {
    this.hitUser = new novel.UserBase();
    this.hitItem = new novel.PrizeWheelItem();
    this.quantity = 0;
};
novel.LatestPrizeWheelBigBonus.prototype._clone = function () { return new novel.LatestPrizeWheelBigBonus(); };
novel.LatestPrizeWheelBigBonus.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LatestPrizeWheelBigBonus.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LatestPrizeWheelBigBonus.prototype.readFrom = function (is) {
    this.hitUser = is.readStruct(0, false, this.hitUser);
    this.hitItem = is.readStruct(1, false, this.hitItem);
    this.quantity = is.readInt32(2, false, this.quantity);
};
novel.LatestPrizeWheelBigBonus.prototype.writeTo = function (os) {
    os.writeStruct(0, this.hitUser);
    os.writeStruct(1, this.hitItem);
    os.writeInt32(2, this.quantity);
};

novel.LatestPrizeWheelBigBonusList = function() {
    this.list = new Taf.Vector(new novel.LatestPrizeWheelBigBonus());
};
novel.LatestPrizeWheelBigBonusList.prototype._clone = function () { return new novel.LatestPrizeWheelBigBonusList(); };
novel.LatestPrizeWheelBigBonusList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.LatestPrizeWheelBigBonusList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.LatestPrizeWheelBigBonusList.prototype.readFrom = function (is) {
    this.list = is.readVector(0, false, this.list);
};
novel.LatestPrizeWheelBigBonusList.prototype.writeTo = function (os) {
    os.writeVector(0, this.list);
};

novel.ListUserLatestPrizeWheelHitFlowReq = function() {
    this.tId = new novel.UserId();
    this.offset = 0;
    this.size = 0;
};
novel.ListUserLatestPrizeWheelHitFlowReq.prototype._clone = function () { return new novel.ListUserLatestPrizeWheelHitFlowReq(); };
novel.ListUserLatestPrizeWheelHitFlowReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserLatestPrizeWheelHitFlowReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserLatestPrizeWheelHitFlowReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.offset = is.readInt32(1, false, this.offset);
    this.size = is.readInt32(2, false, this.size);
};
novel.ListUserLatestPrizeWheelHitFlowReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.offset);
    os.writeInt32(2, this.size);
};

novel.PrizeWheelFlow = function() {
    this.totalRound = 0;
    this.dayRound = 0;
    this.day = "";
    this.flowType = 0;
    this.betAmount = 0;
    this.betTimes = 0;
    this.hitItem = new novel.PrizeWheelItem();
    this.quantity = 0;
    this.createTime = 0;
};
novel.PrizeWheelFlow.prototype._clone = function () { return new novel.PrizeWheelFlow(); };
novel.PrizeWheelFlow.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.PrizeWheelFlow.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.PrizeWheelFlow.prototype.readFrom = function (is) {
    this.totalRound = is.readInt32(0, false, this.totalRound);
    this.dayRound = is.readInt32(1, false, this.dayRound);
    this.day = is.readString(2, false, this.day);
    this.flowType = is.readInt32(3, false, this.flowType);
    this.betAmount = is.readInt32(4, false, this.betAmount);
    this.betTimes = is.readInt32(5, false, this.betTimes);
    this.hitItem = is.readStruct(6, false, this.hitItem);
    this.quantity = is.readInt32(7, false, this.quantity);
    this.createTime = is.readInt64(8, false, this.createTime);
};
novel.PrizeWheelFlow.prototype.writeTo = function (os) {
    os.writeInt32(0, this.totalRound);
    os.writeInt32(1, this.dayRound);
    os.writeString(2, this.day);
    os.writeInt32(3, this.flowType);
    os.writeInt32(4, this.betAmount);
    os.writeInt32(5, this.betTimes);
    os.writeStruct(6, this.hitItem);
    os.writeInt32(7, this.quantity);
    os.writeInt64(8, this.createTime);
};

novel.UserPrizeWheelHitFlowList = function() {
    this.list = new Taf.Vector(new novel.PrizeWheelFlow());
    this.hasMore = true;
};
novel.UserPrizeWheelHitFlowList.prototype._clone = function () { return new novel.UserPrizeWheelHitFlowList(); };
novel.UserPrizeWheelHitFlowList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserPrizeWheelHitFlowList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserPrizeWheelHitFlowList.prototype.readFrom = function (is) {
    this.list = is.readVector(0, false, this.list);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserPrizeWheelHitFlowList.prototype.writeTo = function (os) {
    os.writeVector(0, this.list);
    os.writeBoolean(1, this.hasMore);
};

novel.BetPrizeWheelReq = function() {
    this.tId = new novel.UserId();
    this.betAmount = 0;
};
novel.BetPrizeWheelReq.prototype._clone = function () { return new novel.BetPrizeWheelReq(); };
novel.BetPrizeWheelReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.BetPrizeWheelReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.BetPrizeWheelReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.betAmount = is.readInt32(1, false, this.betAmount);
};
novel.BetPrizeWheelReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.betAmount);
};

novel.UserGemBill = function() {
    this.id = 0;
    this.uid = 0;
    this.amount = 0;
    this.retain = 0;
    this.getTime = 0;
    this.expireTime = 0;
    this.bizId = 0;
    this.taskBase = new novel.UserTask();
};
novel.UserGemBill.prototype._clone = function () { return new novel.UserGemBill(); };
novel.UserGemBill.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserGemBill.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserGemBill.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.uid = is.readInt64(1, false, this.uid);
    this.amount = is.readInt32(2, false, this.amount);
    this.retain = is.readInt32(3, false, this.retain);
    this.getTime = is.readInt64(4, false, this.getTime);
    this.expireTime = is.readInt64(5, false, this.expireTime);
    this.bizId = is.readInt32(6, false, this.bizId);
    this.taskBase = is.readStruct(7, false, this.taskBase);
};
novel.UserGemBill.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt64(1, this.uid);
    os.writeInt32(2, this.amount);
    os.writeInt32(3, this.retain);
    os.writeInt64(4, this.getTime);
    os.writeInt64(5, this.expireTime);
    os.writeInt32(6, this.bizId);
    os.writeStruct(7, this.taskBase);
};

novel.UserGemBillList = function() {
    this.bills = new Taf.Vector(new novel.UserGemBill());
    this.hasMore = true;
};
novel.UserGemBillList.prototype._clone = function () { return new novel.UserGemBillList(); };
novel.UserGemBillList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserGemBillList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserGemBillList.prototype.readFrom = function (is) {
    this.bills = is.readVector(0, false, this.bills);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserGemBillList.prototype.writeTo = function (os) {
    os.writeVector(0, this.bills);
    os.writeBoolean(1, this.hasMore);
};

novel.CoinRechargeFlow = function() {
    this.id = "";
    this.amount = 0;
    this.billType = 0;
    this.bizId = 0;
    this.creatTime = 0;
    this.gameId = 0;
    this.taskBase = new novel.UserTask();
};
novel.CoinRechargeFlow.prototype._clone = function () { return new novel.CoinRechargeFlow(); };
novel.CoinRechargeFlow.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CoinRechargeFlow.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CoinRechargeFlow.prototype.readFrom = function (is) {
    this.id = is.readString(0, false, this.id);
    this.amount = is.readInt32(1, false, this.amount);
    this.billType = is.readInt32(2, false, this.billType);
    this.bizId = is.readInt32(3, false, this.bizId);
    this.creatTime = is.readInt64(4, false, this.creatTime);
    this.gameId = is.readInt32(5, false, this.gameId);
    this.taskBase = is.readStruct(6, false, this.taskBase);
};
novel.CoinRechargeFlow.prototype.writeTo = function (os) {
    os.writeString(0, this.id);
    os.writeInt32(1, this.amount);
    os.writeInt32(2, this.billType);
    os.writeInt32(3, this.bizId);
    os.writeInt64(4, this.creatTime);
    os.writeInt32(5, this.gameId);
    os.writeStruct(6, this.taskBase);
};

novel.CoinRechargeFlowList = function() {
    this.flows = new Taf.Vector(new novel.CoinRechargeFlow());
    this.hasMore = true;
};
novel.CoinRechargeFlowList.prototype._clone = function () { return new novel.CoinRechargeFlowList(); };
novel.CoinRechargeFlowList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.CoinRechargeFlowList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.CoinRechargeFlowList.prototype.readFrom = function (is) {
    this.flows = is.readVector(0, false, this.flows);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.CoinRechargeFlowList.prototype.writeTo = function (os) {
    os.writeVector(0, this.flows);
    os.writeBoolean(1, this.hasMore);
};

novel.UserAipBill = function() {
    this.id = 0;
    this.uid = 0;
    this.amount = 0;
    this.retain = 0;
    this.getTime = 0;
    this.expireTime = 0;
    this.bizId = 0;
    this.taskBase = new novel.UserTask();
};
novel.UserAipBill.prototype._clone = function () { return new novel.UserAipBill(); };
novel.UserAipBill.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserAipBill.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserAipBill.prototype.readFrom = function (is) {
    this.id = is.readInt64(0, false, this.id);
    this.uid = is.readInt64(1, false, this.uid);
    this.amount = is.readInt32(2, false, this.amount);
    this.retain = is.readInt32(3, false, this.retain);
    this.getTime = is.readInt64(4, false, this.getTime);
    this.expireTime = is.readInt64(5, false, this.expireTime);
    this.bizId = is.readInt32(6, false, this.bizId);
    this.taskBase = is.readStruct(7, false, this.taskBase);
};
novel.UserAipBill.prototype.writeTo = function (os) {
    os.writeInt64(0, this.id);
    os.writeInt64(1, this.uid);
    os.writeInt32(2, this.amount);
    os.writeInt32(3, this.retain);
    os.writeInt64(4, this.getTime);
    os.writeInt64(5, this.expireTime);
    os.writeInt32(6, this.bizId);
    os.writeStruct(7, this.taskBase);
};

novel.UserAipBillList = function() {
    this.bills = new Taf.Vector(new novel.UserAipBill());
    this.hasMore = true;
};
novel.UserAipBillList.prototype._clone = function () { return new novel.UserAipBillList(); };
novel.UserAipBillList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserAipBillList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserAipBillList.prototype.readFrom = function (is) {
    this.bills = is.readVector(0, false, this.bills);
    this.hasMore = is.readBoolean(1, false, this.hasMore);
};
novel.UserAipBillList.prototype.writeTo = function (os) {
    os.writeVector(0, this.bills);
    os.writeBoolean(1, this.hasMore);
};

novel.QueryCurrentActivityInfoReq = function() {
    this.tId = new novel.UserId();
    this.activity = 0;
};
novel.QueryCurrentActivityInfoReq.prototype._clone = function () { return new novel.QueryCurrentActivityInfoReq(); };
novel.QueryCurrentActivityInfoReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QueryCurrentActivityInfoReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QueryCurrentActivityInfoReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.activity = is.readInt32(1, false, this.activity);
};
novel.QueryCurrentActivityInfoReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.activity);
};

novel.ActivityExtra4InviteUser = function() {
    this.initGem = 0;
    this.gemPerUser = 0;
    this.maxGem = 0;
};
novel.ActivityExtra4InviteUser.prototype._clone = function () { return new novel.ActivityExtra4InviteUser(); };
novel.ActivityExtra4InviteUser.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ActivityExtra4InviteUser.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ActivityExtra4InviteUser.prototype.readFrom = function (is) {
    this.initGem = is.readInt32(0, false, this.initGem);
    this.gemPerUser = is.readInt32(1, false, this.gemPerUser);
    this.maxGem = is.readInt32(2, false, this.maxGem);
};
novel.ActivityExtra4InviteUser.prototype.writeTo = function (os) {
    os.writeInt32(0, this.initGem);
    os.writeInt32(1, this.gemPerUser);
    os.writeInt32(2, this.maxGem);
};

novel.ActivityInfo = function() {
    this.id = 0;
    this.extra = "";
    this.currentPeriod = 0;
    this.currentPeriodStart = 0;
    this.currentPeriodEnd = 0;
    this.poolAmount = 0;
};
novel.ActivityInfo.prototype._clone = function () { return new novel.ActivityInfo(); };
novel.ActivityInfo.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ActivityInfo.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ActivityInfo.prototype.readFrom = function (is) {
    this.id = is.readInt32(0, false, this.id);
    this.extra = is.readString(1, false, this.extra);
    this.currentPeriod = is.readInt32(2, false, this.currentPeriod);
    this.currentPeriodStart = is.readInt64(3, false, this.currentPeriodStart);
    this.currentPeriodEnd = is.readInt64(4, false, this.currentPeriodEnd);
    this.poolAmount = is.readInt64(5, false, this.poolAmount);
};
novel.ActivityInfo.prototype.writeTo = function (os) {
    os.writeInt32(0, this.id);
    os.writeString(1, this.extra);
    os.writeInt32(2, this.currentPeriod);
    os.writeInt64(3, this.currentPeriodStart);
    os.writeInt64(4, this.currentPeriodEnd);
    os.writeInt64(5, this.poolAmount);
};

novel.ListUserGrowableRankReq = function() {
    this.tId = new novel.UserId();
    this.rankType = 0;
    this.period = 0;
    this.listSize = 0;
    this.maxSeq = 0;
};
novel.ListUserGrowableRankReq.prototype._clone = function () { return new novel.ListUserGrowableRankReq(); };
novel.ListUserGrowableRankReq.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.ListUserGrowableRankReq.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.ListUserGrowableRankReq.prototype.readFrom = function (is) {
    this.tId = is.readStruct(0, false, this.tId);
    this.rankType = is.readInt32(1, false, this.rankType);
    this.period = is.readInt32(2, false, this.period);
    this.listSize = is.readInt32(3, false, this.listSize);
    this.maxSeq = is.readInt32(4, false, this.maxSeq);
};
novel.ListUserGrowableRankReq.prototype.writeTo = function (os) {
    os.writeStruct(0, this.tId);
    os.writeInt32(1, this.rankType);
    os.writeInt32(2, this.period);
    os.writeInt32(3, this.listSize);
    os.writeInt32(4, this.maxSeq);
};

novel.UserGrowableRankItem = function() {
    this.user = new novel.UserBase();
    this.increment = 0;
    this.sequence = 0;
};
novel.UserGrowableRankItem.prototype._clone = function () { return new novel.UserGrowableRankItem(); };
novel.UserGrowableRankItem.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserGrowableRankItem.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserGrowableRankItem.prototype.readFrom = function (is) {
    this.user = is.readStruct(0, false, this.user);
    this.increment = is.readInt32(1, false, this.increment);
    this.sequence = is.readInt32(2, false, this.sequence);
};
novel.UserGrowableRankItem.prototype.writeTo = function (os) {
    os.writeStruct(0, this.user);
    os.writeInt32(1, this.increment);
    os.writeInt32(2, this.sequence);
};

novel.UserGrowableRankList = function() {
    this.rank = new Taf.Vector(new novel.UserGrowableRankItem());
    this.myRank = new novel.UserGrowableRankItem();
};
novel.UserGrowableRankList.prototype._clone = function () { return new novel.UserGrowableRankList(); };
novel.UserGrowableRankList.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.UserGrowableRankList.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.UserGrowableRankList.prototype.readFrom = function (is) {
    this.rank = is.readVector(0, false, this.rank);
    this.myRank = is.readStruct(1, false, this.myRank);
};
novel.UserGrowableRankList.prototype.writeTo = function (os) {
    os.writeVector(0, this.rank);
    os.writeStruct(1, this.myRank);
};

novel.QuerySort = function() {
    this.field = "";
    this.direction = 0;
};
novel.QuerySort.prototype._clone = function () { return new novel.QuerySort(); };
novel.QuerySort.prototype._write = function (os, tag, value) { os.writeStruct(tag, value); };
novel.QuerySort.prototype._read  = function (is, tag, def) { return is.readStruct(tag, true, def); };
novel.QuerySort.prototype.readFrom = function (is) {
    this.field = is.readString(0, false, this.field);
    this.direction = is.readInt32(1, false, this.direction);
};
novel.QuerySort.prototype.writeTo = function (os) {
    os.writeString(0, this.field);
    os.writeInt32(1, this.direction);
};

export default novel;
