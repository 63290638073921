export default {
    "next": "Далее",
    "login": "Войти",
    "login_tips": "Мы надеемся, что вы сможете насладиться романтическим путешествием здесь",
    "set_password": "Установить пароль",
    "enter_password": "Пожалуйста, введите ваш пароль",
    "verify_code": "Код подтверждения",
    "enter_code": "Пожалуйста, введите 6-значный код подтверждения из электронной почты",
    "let_start": "Давайте начнем",
    "let_start_tips": "Мы надеемся, что вы сможете насладиться романтическим путешествием здесь",
    "sign_facebook": "Войти через FaceBook",
    "sign_google": "Войти через Google",
    "sign_email": "Войти через Email",
    "language_title": "Установить предпочтения языка чтения",
    "current_lang": "Текущий язык",
    "en": "EN",
    "indonesian": "Индонезийский",
    "personal_data": "Личные данные",
    "save": "Сохранить",
    "nickname": "Никнейм",
    "sign_out": "Выйти",
    "loading": "Загрузка",
    "success": "Успешно",
    "reading_record": "Запись чтения",
    "empty": "Пусто",
    "last_read": "Последнее чтение",
    "chapter": "Глава",
    "remove": "Удалить",
    "search": "Поиск",
    "search_empty": "Ни один из романов, связанных с \"{{var}}\", не найден",
    "login_tips_2": "Войдите, чтобы получить доступ к большему количеству функций.",
    "register_or_login": "Регистрация/Вход",
    "more": "Больше",
    "reads": "Читает",
    "read": "Читать",
    "last_chapter": "Последняя глава",
    "all_chapter": "Все главы",
    "contents": "Содержание",
    "all": "Все",
    "completed": "Завершено",
    "ongoing": "В процессе",
    "most_popular": "Самые популярные",
    "new_arrival": "Новинки",
    "invite_friends": "Поделитесь этим кодом приглашения со своими друзьями. Как только они активируют его на этой странице, вы оба получите {{coins}} самоцветы. Каждый код приглашения можно использовать до 50 раз.",
    "invite_friends_nums": "<span class=\"blue\">Я пригласил {{n}} друзей и заработал </span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}} </span>",
    "guide_title": "Проект автора StoryGo",
    "guide_content": 0,
    "active_code": "Активировать пригласительный код",
    "active_tips": "Получите пригласительный код от своих друзей, и после успешной активации вы получите {{coins}} самоцветы. Совет: Вы не можете активировать свой собственный пригласительный код; вы должны получить его от других!",
    "login_now": "Войти сейчас",
    "task_1_title": "Подарок на приветствие",
    "task_1_desc": "Приветственный подарок для новичков, который автоматически зачислен на ваш кошелек",
    "view_detail": "Посмотреть детали",
    "claim": "Получить",
    "invite_friend": "Пригласить друзей",
    "my_invite_code": "Мой пригласительный код",
    "copy": "Копировать",
    "task_2_title": "Ежедневная проверка",
    "task_2_desc": "Проверяйте каждый день и получайте бонус.",
    "task_3_title": "Читайте более {{min}} минут",
    "task_3_desc": "Прочитайте всего {{min}} минут сегодня, чтобы заработать награды.",
    "task_page_label": "Награды",
    "label_wallet_purchase_coins": "Покупка монет",
    "go": "Вперед",
    "done": "Готово",
    "copy_text": "Мой код приглашения StoryGo - [ {{code}} ], активируйте его и получите 50 монет:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "Код приглашения скопирован.",
    "active_success": "Активация прошла успешно. Награда в 50 монет отправлена на ваш кошелек.",
    "error_code_limit": "Лимит использования кода активации исчерпан.",
    "error_had_activated": "Этот аккаунт уже был активирован с кодом",
    "error_invalid_code": "Недействительный код активации",
    "download_tips": "Бесконечные истории в приложении",
    "download": "Скачать",
    "label_book_praise": "Рейтинг",
    "label_book_prologue": "Пролог",
    "login_success": "Вход выполнен успешно",
    "msg_reset_password_repeat": "Пожалуйста, повторите новый пароль",
    "msg_reset_password_email": "Пожалуйста, введите свой адрес электронной почты, чтобы сбросить пароль",
    "label_reset_password": "Сбросить пароль",
    "label_send_email": "Отправить электронное письмо",
    "msg_login_email_code_sent": "Код подтверждения успешно отправлен на вашу электронную почту",
    "msg_feedback_error": "Отправка не удалась, пожалуйста, попробуйте позже",
    "save_userinfo": "Успешно сохранено",
    "label_load_image_fail": "Не удалось загрузить изображение",
    "paid_chapters": "Платные главы",
    "unlock_price": "Разблокировка главы требует {{coin}}.",
    "download_claim_coin": "Скачайте приложение, чтобы получить {{coin}} и разблокировать более увлекательные главы.",
    "label_book_chapters": "{{num}} Главы",
    "label_cancel": "Отмена",
    "msg_common_state_empty": "Пусто здесь",
    "clear_reading_record": "Вы уверены, что хотите очистить запись о чтении?",
    "clear_successful": "Запись успешно очищена.",
    "label_search_trending": "Трендовые поиски",
    "task_login_tips": "Войдите, чтобы получить бонус в виде монет",
    "label_new_arrival": "Горячие Новинки",
    "had_activated": "Этот аккаунт уже был активирован с кодом",
    "activate": "Активировать",
    "task_5_title": "Поделиться в Facebook",
    "task_5_desc": "Поделиться любой книгой на платформе Facebook",
    "bigwheel_redeem_label": "Пополнить",
    "coin_tips_1": "Недостаточно монет, пожалуйста, пополните.",
    "coin_tips_2": "Счет: {{n}} монет (нужно {{m}} монет)",
    "bigwheel_rule_1": "1. Участвуйте в розыгрыше Колеса Удачи с гарантированным шансом выиграть призы на 100%.",
    "bigwheel_rule_2": "2. Один поворот колеса стоит 10 золотых монет, десять поворотов стоят 100 золотых монет, а пятьдесят поворотов стоят 500 золотых монет. Чем больше последовательных поворотов, тем выше шанс выиграть ценные призы!",
    "bigwheel_rule_3": "3. Призы, полученные во время игры, автоматически распределяются. Золотые монеты зачисляются непосредственно на ваш кошелек золотых монет.",
    "bigwheel_rule_4": "4. Эта игра не связана с Apple Inc. Все права на окончательную интерпретацию принадлежат StoryGo.",
    "bigwheel_balance_label": "Баланс",
    "no_data_1": "Нет данных",
    "msg_room_coin_notice": "Поздравляем! [{{who}}] выиграл {{coin}} монет!",
    "top_up": "Пополнение",
    "sign": "Регистрация",
    "sign_desc": "Приветственный подарок для новичков, который автоматически зачислен на ваш кошелек",
    "first_recharge": "Ограниченное временное предложение",
    "first_recharge_desc": "Завершите ограниченное временное предложение, чтобы получить бонус",
    "unlock_chapter": "Разблокировать {{n}} главу",
    "unlock_chapter_desc": "Разблокируйте премиум-главу, чтобы получить награду.",
    "comment_book": "Оставьте комментарий к книге",
    "comment_book_desc": "Напишите отзыв о своей любимой книге и получите шанс выиграть награды!",
    "my_coins": "Мои монеты",
    "recharge_title": "Рекомендуемый подарочный набор",
    "bonus": "Бонус",
    "buy_coins": "Купить монеты",
    "my_balance": "Мой баланс",
    "apple_pay": "Apple Pay",
    "other_pay": "Лучший выбор",
    "google_pay": "Google Pay",
    "pay_now": "Оплатить сейчас",
    "label_balance": "монеты",
    "select_payment": "Пожалуйста, выберите метод оплаты",
    "select_package": "Пожалуйста, выберите пакет пополнения",
    "wallet_title": "Кошелек",
    "coins_balance": "Баланс",
    "new_comer": "Новичок",
    "extra_coins": "Дополнительно {{n}} монет",
    "daily_task_label": "Ежедневная задача",
    "label_pay_success": "Оплата прошла успешно!",
    "app_update_title": "Пожалуйста, обновите до последней версии.",
    "restore_label": "Восстановить",
    "history_label": "История",
    "newcomer_pack": "Ограниченное временное предложение",
    "recommend_pack": "Рекомендуемый пакет",
    "collection_month": "Всего/Месяц",
    "now_get_icons": "Получите {{n}}  сразу",
    "monthly_get_icons": "Получите {{n}} драгоценных камней/месяц",
    "recharge_feedback_msg": "У вас возникли проблемы с пополнением?",
    "feedback_label": "Обратная связь",
    "coin_label": "Монеты",
    "gem_label": "Драгоценные камни",
    "restore_tips": "Нет восстанавливаемых подписок",
    "recharge_label": "Пополнить",
    "sign_days": "Зарегистрировано {{n}} день(дней)",
    "vip_daily_task": "Бонус за месячный пакет",
    "other": "Другой",
    "limited_recharge_pack": "Ограниченное временное предложение",
    "expire_tips": "Истекает {{time}}",
    "hot_pack": "Популярный пакет",
    "continuous_checkin_day": "Зарегистрировано {{n}} день(дней)",
    "svip_login_reward": "Премиальный бонус $9.9",
    "vip_login_reward": "Премиальный бонус $19.9",
    "dailysign_popup_btn": "Получите больше из Центра вознаграждений",
    "checked_in": "Проверено",
    "limit_time_recharge_title": "Ограниченное временное предложение",
    "recharge_pack_tips": "Завершите ограниченное временное предложение, чтобы получить бонус",
    "balance_nsufficient_tips": "Ваш баланс недостаточен, пожалуйста, пополните",
    "had_coins_tips": "У вас есть: {{n}} Монет | {{m}} Драгоценные камни",
    "dailysign_popup_title": "Поздравляем",
    "pay_method_label": "Метод оплаты",
    "at_once": "Сразу",
    "daily_login": "Ежедневный вход в систему",
    "card_coin_suffix": "Всего/Месяц",
    "card_gems_suffix": "Месяц",
    "vips_rule_title": "О месячном пакете",
    "vips_rule_1": "1.При успешной покупке пакета на месяц за 9,9 доллара вы сразу получите 1000 золотых монет, и вы сможете получать 20 драгоценных камней каждый день в течение 30 последовательных дней (всего 600 драгоценных камней); При успешной покупке пакета на месяц за 19,9 доллара вы сразу получите 2000 золотых монет, и вы сможете получать 40 драгоценных камней каждый день (всего 600 драгоценных камней) в течение 30 последовательных дней.",
    "vips_rule_2": "2.Если вы покупаете разные месячные пакеты, ежедневные награды в виде драгоценных камней будут складываться; если вы покупаете одинаковые месячные пакеты, ежедневные награды в виде драгоценных камней не будут складываться, но только продлят срок действия соответствующей месячной карты.",
    "vips_rule_3": "3.Полученные ежедневно драгоценные камни действительны 30 дней, пожалуйста, обратите внимание.",
    "vips_rule_4": "",
    "vips_rule_5": "Пакет на месяц будет действителен в течение 24 часов после покупки, и вы сможете насладиться соответствующими преимуществами.",
    "check_in": "Регистрация",
    "day_index": "№{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Драгоценные камни </span> скоро истекут",
    "balance_label": "Баланс",
    "wallet_rule_title": "О драгоценных камнях",
    "wallet_rule_1": "Драгоценные камни можно использовать для разблокировки глав. При разблокировке глав монеты и драгоценные камни имеют одинаковую стоимость, 1 монета = 1 драгоценный камень.",
    "wallet_rule_2": "Драгоценные камни истекают. Обратите внимание, что драгоценные камни, полученные при пополнении и приобретении месячного пакета, действительны 30 дней, а драгоценные камни, полученные за ежедневные задания, действительны 7 дней.",
    "wallet_rule_3": "При разблокировке глав используются сначала драгоценные камни.",
    "read_task_title": "Задание на чтение",
    "share_task_title": "Задание на публикацию",
    "comment_task_title": "Задание на комментарий",
    "vip_task_title": "Премиальный бонус $9.99",
    "svip_task_title": "Премиальный бонус $19.99",
    "vip_page_title": "Месячный пакет",
    "pay_bonus_label": "Бонус",
    "daily_gems": "Ежедневно {{n}} драгоценных камней",
    "history_page_title": "История",
    "recharge_title_1": "Пополнение",
    "gems_will_expire_btn": "Подробности",
    "gems_history_tips": "По сроку действия",
    "unlock_coins": "Нужно: {{n}} Монеты или драгоценности",
    "history_recharge_title": "Пополнение",
    "history_game_title": "Розыгрыш везения",
    "history_recharge_given_title": "Бонус пополнения",
    "extar_gems_btn": "Дополнительные камни",
    "watch_ad_task_title": "Смотреть видео",
    "watch_ad_task_desc": "Получить награду за каждое просмотренное видео ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Награда успешно получена.",
    "task_finish_title": "Задача выполнена.",
    "draw_width_ad_toast": "Поздравляем! Вы получили  {{n}} и дополнительно  {{m}}.",
    "draw_toast": "Поздравляем! Вы получили  {{n}}.",
    "draw_dialog_btn1": "Просто запросите  {{n}}.",
    "draw_dialog_btn2": "Запросите  {{n}} и  {{m}} дополнительно.",
    "history_ads_given_title": "Смотреть видео",
    "ad_extra_rewards_title": "Бонус после выполнения заданий.",
    "label_editor_picks": "Выбор редактора",
    "label_genre": "Жанр",
    "label_main_tab_home": "Открывать",
    "label_reader_choice": "Высоко Оценен",
    "invite_bonuses": "Пригласите нового пользователя и получите до {{n}} бонусов.",
    "invite_friend_btn": "Пригласите своего друга",
    "how_get_bonuses": "Как получить больше бонусов",
    "invite_tips_1": "Ваш друг разблокировал и прочитал 5 глав",
    "invite_tips_2": "Когда ваши друзья пополняют счет, вы получаете 10% в виде самоцветов, до 150 самоцветов за каждого друга.",
    "invite_tips_3": "Каждый раз, когда ваш друг выполняет действие, вы получаете немедленное вознаграждение.",
    "how_invite_friend": "Как пригласить друга",
    "invite_tips_5": "Нажмите кнопку ниже, чтобы создать свою уникальную ссылку-приглашение и поделиться ею с друзьями",
    "invite_tips_4": "Ваш друг загрузил StoryGo через ссылку, которую вы поделились",
    "reward_history_title": "История наград",
    "reward_history_tips_1": "Вы полностью получили {{n}} самоцветов от активности приглашения.",
    "reward_history_tips_2": "Вы успешно пригласили {{n}} друзей и {{m}} самоцветов.",
    "reward_history_tips_3": "Вы получили {{n}} самоцветов из задания разблокировки 5 глав, выполненного вашими приглашенными друзьями.",
    "reward_history_tips_4": "Вы получили {{n}} самоцветов в качестве награды за перезагрузку ваших друзей.",
    "daily_invite_friend_title": "Пригласить друзей",
    "daily_invite_friend_desc": "Пригласите своих друзей присоединиться к StoryGo и получить большие награды.",
    "recharge_panel_ad_tips": "Пополните свой пакет StoryGo, чтобы получить обильные книжные монеты и самоцветы. Легко разблокируйте премиум-главы и наслаждайтесь чтением без рекламы на 7–30 дней после покупки, с мгновенной активацией.",
    "invite_more_friend": "Пригласите больше друзей и получите больше самоцветов!",
    "history_invite_unlock_title": "Приглашенный пользователь открыл 5 глав",
    "history_invite_recharge_title": "Приглашенный пользователь сделал перезагрузку",
    "days": "Дни",
    "continue_read_app": "Продолжить чтение в приложении",
    "download_app_tips": "Чтобы получить более захватывающий контент, пожалуйста, скачайте StoryGo",
    "invite_tips_6": "Пригласите нового читателя, чтобы получить 15 самоцветов. Вы можете заработать до 300 самоцветов в качестве награды.",
    "history_invite_success_title": "Успешно пригласил пользователя",
    "evaluation_task_title": "Оцените StoryGo",
    "evaluation_task_desc": "3 шага, чтобы получить награды",
    "invite_page_title": "Пригласите новых пользователей использовать его вместе и получить награды в виде самоцветов!",
    "invite_page_title_2": "За каждого нового пользователя, которого вы пригласите, вы получите.",
    "invite_page_title_3": "Für Zahlungen von neuen Benutzern können Sie auch eine Belohnung von {{n}} Edelsteinen erhalten. Je mehr Sie einladen, desto mehr verdienen Sie.",
    "my_invite_record": "Моя история приглашений",
    "successfully_invited": "Успешное Приглашение",
    "earnings_gem": "Мой Доход (Драгоценные Камни)",
    "how_invite_title_1": "Пригласить друзей",
    "how_invite_title_2": "Друг завершает регистрацию",
    "how_invite_title_3": "Награды прибывают",
    "invite_h5_title": "Приглашаем вас использовать StoryGo вместе",
    "got_gem_label": "Преимущества для новичков",
    "accept_invitation": "Принять приглашение",
    "join_people": "Люди приняли участие в мероприятии",
    "recommended_content": "Рекомендуемый контент",
    "to_app_tip": "Больше захватывающего контента на StoryGo",
    "turn_on_notify_title": "Открыть уведомления",
    "turn_on_notify_desc": "Откройте уведомления о сообщениях, чтобы получить награды",
    "label_help_center": "Центр помощи"
}