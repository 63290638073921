import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/index.vue'
import UserView from '../views/user/index.vue'
import {removeBlankSpace} from "@storygo/utils";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        keepAlive: true
      },
      component: HomeView
    },
    {
      path: '/detail',
      name: 'detail',
      redirect(to) {
        return `/bookShare/${removeBlankSpace(to.query ? to.query.title : '')}_${to.query.id}_${to.query.inviteCode}`
      },
    },
     {
      path: '/bookShare/:path',
      name: 'bookShare',
       component: () => import('../views/bookShare/index.vue')
      // redirect(to) {
      //   return `/bookShare/${removeBlankSpace(to.query ? to.query.title : '')}_${to.query.id}`
      // },
    },

    {
      path: '/books/:path',
      name: 'book',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/detail/index.vue')
    },
    {
      path: '/user',
      name: 'user',
      meta: {
        keepAlive: true
      },
      component: UserView
    },
    {
      path: '/person',
      name: 'person',
      component: () => import('../views/person/index.vue')
    },
    {
      path: '/genre',
      name: 'genre',
       meta: {
         keepAlive: true
       },
      component: () => import('../views/subject/index.vue')
    },
    {
      path: '/genre/:genreName',
      name: 'genreList',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/subject/index.vue')
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('../views/record/index.vue')
    },
      {
      path: '/search',
      name: 'search',
        meta: {
          keepAlive: true
        },
      component: () => import('../views/search/index.vue')
    },
    {
      path: '/books/:book/:chapter',
      name: 'chapter',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/reader/index.vue')
    },
     {
      path: '/login',
      name: 'login',
      component: () => import('../views/login/index.vue')
    },
    {
      path: '/login/email',
      name: 'email',
      component: () => import('../views/login/email/index.vue')
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: () => import('../views/login/resetPassword/index.vue')
    },
    {
      path: '/verification',
      name: 'verification',
      component: () => import('../views/login/verification/index.vue')
    },
    {
      path: '/setPassword',
      name: 'setPassword',
      component: () => import('../views/login/setPassword/index.vue')
    },

    {
      path: '/uploadGuidance',
      name: 'uploadGuidance',
      component: () => import('../views/uploadGuidance/index.vue')
    },


    {
      path: '/about',
      name: 'about',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/about/index.vue')
    },
    {
      path: '/userAgreement',
      name: 'userAgreement',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/userAgreement/index.vue')
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/privacyPolicy/index.vue')
    },

    {
      path: '/landingPage',
      name: 'landingPage',
      meta: {
      },
      component: () => import('../views/landingPage/index.vue')
    },
    {
      path: '/nofound',
      name: 'nofound',
      component: () => import('../views/noFound/index.vue')
    },
    {
      path: '/app',
      children: [
        {
          path: 'dailyTask',
          name: 'dailyTask',
          meta: {
            // keepAlive: true
          },
          component: () => import('../views/dailyTask/index.vue')
        },
        {
          path: 'debug',
          name: 'debug',
          meta: {
          },
          component: () => import('../views/debug/index.vue')
        },
        {
          path: 'wallet',
          name: 'wallet',
          meta: {
          },
          component: () => import('../views/wallet/index.vue')
        },
         {
          path: 'bookCards',
          name: 'bookCard',
          meta: {
          },
          component: () => import('../views/bookCard/index.vue')
        },

         {
          path: 'walletPopup',
          name: 'walletPopup',
          meta: {
          },
          component: () => import('@/views/dialogs/walletPopup/index.vue')
        },
        {
          path: 'dialog',
          children: [

          ]
        },
         {
          path: 'paySuccess',
          name: 'paySuccess',
          meta: {
          },
          component: () => import('../views/paySuccess/index.vue')
        },

         {
          path: 'wealthDetail',
          name: 'wealthDetail',
          meta: {
          },
          component: () => import('../views/wealthDetail/index.vue')
        },

         {
          path: 'invite',
          name: 'invite',
          meta: {
          },
          component: () => import('../views/invite/index.vue')
        },

         {
          path: 'help',
          name: 'help',
          meta: {
          },
          component: () => import('../views/help/index.vue')
        },

      ]
    },


    {
      path: '/act',
      children: [
        {
          path: 'join',
          name: 'join',
          component: () => import('../views/act/join/index.vue')
        },
         {
          path: 'recommendation',
          name: 'recommendation',
          component: () => import('../views/act/recommendation/index.vue')
        },

         {
          path: 'recommenderRank',
          name: 'recommenderRank',
          component: () => import('../views/act/recommenderRank/index.vue')
        },

         {
          path: 'evaluation',
          name: 'evaluation',
          component: () => import('../views/act/evaluation/index.vue')
        },
          {
              path: 'inviteFriends',
              name: 'inviteFriends',
              component: () => import('../views/act/inviteFriends/index.vue')
          },
           {
              path: 'invitation',
              name: 'invitation',
              component: () => import('../views/act/invitation/index.vue')
          },

      ],

    },
  ]
})

export default router
