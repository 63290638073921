// @ts-nocheck

import axios from 'axios';
// import {cloneDeep} from 'lodash';
import Taf from './taf';
import Protocol from './taf/protocol';
import {codeMap, getGuid, userAgent, tokenType, sdkPromise} from '@storygo/utils'
import {eventBus} from '@storygo/utils/emitter'
// import {Response} from "../type";
import cache from '@storygo/utils/cache'
import hash from 'object-hash'

// console.log()
// utils f1 = new Protocol.ZRoleMaterialsRsp()
// // f1.list.value = 1;
// // type a = ReturnType<typeof f1>

const setVal = (tReq: any, data: any) => {
    for (const key in data) {
        if(data[key] === undefined) continue
        if (data[key].constructor === Object) {
            setVal(tReq[key], data[key]);
        } else if (data[key].constructor === Array) {
            data[key].forEach((item: any, index: number) => {
                if(tReq[key] && tReq[key].proto){
                    const result = JSON.parse(JSON.stringify(tReq[key].proto));
                    setVal(result, item);
                }
                // utils result = cloneDeep(tReq[key].proto);
                if(tReq[key]){
                    tReq[key].value = data[key];
                }

            });
        } else {
            tReq[key] = data[key];
        }
    }
};

// axios.defaults.timeout = 50000;

// http request 拦截器
axios.interceptors.request.use(
    config => config,
    error => {
        Promise.reject(error);
        throw error;
    }
);

// http response 拦截器
axios.interceptors.response.use(
    ({data, status}) => {
        return data
    },
    error => {
        Promise.reject(error);
        throw error;
    }
);

export const xhr = axios;
export default ({
                    moduleName,
                    funcName,
                    reqName,
                    resName,
                    reqData,
                    auth = {},
                    useCache = false,
                    ttl = 10 // s
                }: any): Promise<any> => {
    const web_api_performance_request = {
        response_headers: '0',
        between_request_response: '0',
        request_body: '0',
        secure_connect: '0',
        function: funcName,
        dns: '0',
        request_headers: '0',
        response_body: '0',
        total_time: '0',
        userid: '0',
        connect: '0',
        dns_name: '0'
    }
    // && process.env.NODE_ENV === 'production'
    const startTime = Date.now()
    var cacheKey = ''
    const wup = new Taf.Wup();
    wup.setServant(moduleName);
    wup.setFunc(funcName);
    const tReq = new Protocol[reqName]();
    setVal(tReq, reqData);
    if (tReq.tId) {
      auth = { ...auth };
      web_api_performance_request.userid =  tReq.tId.uid
    }
    if (tReq.tId && auth) {
        for (const key in auth) {
            if (auth.hasOwnProperty(key)) {
                tReq.tId[key] = auth[key];
            }
        }
    }
    // if (process.env.NODE_ENV !== 'production') {
        console.log(`请求===>:${funcName}`, tReq);
    // }
    wup.writeStruct('tReq', tReq);
    const buffer = wup.encode().getBuffer();
    const timestamp = +new Date();
    return new Promise(async (resolve, reject) => {
        if(useCache) {
            cacheKey = hash.MD5(JSON.stringify({moduleName,funcName,reqName,reqData}))
            const cacheData = await cache.get(cacheKey)
            if(cacheData){
                console.log(cacheData)
                return resolve(cacheData)
            }
        }
        const _httpStartTime = Date.now()
        axios({
            // url: `${process.env.REACT_APP_REQUEST_URL}/tup?timestamp=${timestamp}`,
            url: `${process.env.VUE_APP_REQUEST_URL || ''}/tup?timestamp=${timestamp}&function=${funcName}`,
            method: 'post',
            data: buffer,
            headers: {
                'content-type': 'application/multipart-formdata'
            },
            responseType: 'arraybuffer'
        })
            .then(async data => {
                const _httpEndTime = Date.now()
                web_api_performance_request.between_request_response = String(_httpEndTime - _httpStartTime)
                // if (process.env.NODE_ENV !== 'production') {
                //     if (!data) {
                //         return window.location.reload()
                //     }
                // }
                //
                data && wup.decode(data);
                const inputStream = new Taf.JceInputStream(wup.newdata.get(''));
                const returnValue = inputStream.readInt32(0, true);
                const Structure = Protocol[resName];
                console.log(returnValue, Structure)
                let result: any;

                // 需要解包的情况
                if (funcName && Structure) {
                    result = new Structure();
                    result.returnValue = returnValue;
                    const errorTexts = codeMap()
                    if(result.returnValue === 4){
                        eventBus.$emit('login')
                    }
                    if (result.returnValue !== 0 && errorTexts[result.returnValue]) {
                        console.error('异常', funcName, reqName, result.returnValue)
                        eventBus.$emit('toast', errorTexts[result.returnValue], 'fail');
                        return reject(result)
                    }
                    result.funcName = funcName;
                    const key = wup.newdata.get('tRsp') ? 'tRsp' : 'tResp';
                    wup.readStruct(key, result, Structure);
                }
                // 只有返回值,无需解包的情况
                if (funcName && !Structure) {
                    result = {returnValue};
                }
                const errorTexts = codeMap()
                console.log(2132131)
                if (result.returnValue !== 0 && errorTexts[result.returnValue]) {
                    console.error('异常', funcName, reqName, result.returnValue)
                    eventBus.$emit('toast', errorTexts[result.returnValue], 'warning');
                    eventBus.$emit('loading', false)
                    return reject(result)
                }
                // if (process.env.NODE_ENV !== 'production') {
                    console.log(`响应====>:${reqName}`, result);
                // }
                if(useCache){
                    await cache.set(cacheKey, result, ttl)
                }
                const endTime = Date.now()
                web_api_performance_request.total_time =  String(endTime - startTime)
                resolve(result);
                if(window.isApp){
                    sdkPromise('emitEvent', {
                        eid: 'web_api_performance_request',
                        edata: JSON.stringify(web_api_performance_request)
                    })
                }
            })
            .catch(err => {
                reject(err)
                console.log(err)
                const _httpEndTime = Date.now()
                const endTime = Date.now()
                web_api_performance_request.between_request_response = String(_httpEndTime - _httpStartTime)
                web_api_performance_request.total_time = String(endTime - startTime)

                if(window.isApp){
                    sdkPromise('emitEvent', {
                        eid: 'web_api_performance_request',
                        edata: JSON.stringify(web_api_performance_request)
                    })
                }
            })
    });
};
