import {sdkPromise} from './sdk'
// import { useAppSelector } from '@/hooks/redux'
import CryptoJS from "crypto-js";
import i18next from "i18next";
import {novel} from "@storygo/types/novel_baseTars.d";
import {chunk} from "lodash";

export const keyStr = () => {
    if(window.location.host.indexOf('test') > -1 || window.location.host.indexOf('192.') > -1 || window.location.host.indexOf('localhost') > -1) {
        return 'ad780dcd4c9c8e493c45f50b1975b38d'
    }else {
        return '7888365c9f4759ac1ac0f31e62fef953'
    }
}
    // process.env.NODE_ENV === 'production'
    //     ? '7888365c9f4759ac1ac0f31e62fef953'
    //     : 'ad780dcd4c9c8e493c45f50b1975b38d';
export const lockKey = 'vFoQVfKgEtP47dC7';
export const lockIv = '3Emzjzi4Cga8uN3j';
// export utils guid = '8988fcad-2d23-3b1f-0193-781c41ea9d44';
const userAgent =
    'os=web;appChannel=official/web;appVerName=1.1.0;appVerCode=1001';
const tokenType = 1;

const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const getSrc = (link: string, size = 150) => {
    if (!link) {
        return '';
    }
    // if(link.indexOf('thirdqq.qlogo.cn') > -1){
    //     return  link
    // }
    return `${link}?x-oss-process=image/resize,m_lfit,w_${size}/format,webp`;
}
const getSrcDiy = (link: string, width: number = 200) => {
    if (!link) {
        return '';
    }
    if(link.indexOf('thirdqq.qlogo.cn') > -1){
        return  link
    }
    return `${link}?x-oss-process=image/auto-orient,1/resize,m_lfit,w_${width}/format,${link.replace(
        /.*\.(.*)$/,
        '$1'
    )}`;
}

const checkOs = (): 'PC'|'iOS'|'Android' => {
    const u = navigator.userAgent;
    let os = '';
    // return 'PC';
    const isPC = () => {
        const Agents = [
            'Android',
            'iPhone',
            'SymbianOS',
            'Windows Phone',
            'iPad',
            'iPod'
        ];
        let res = true;
        for (const key of Agents) {
            if (u.indexOf(key) > 0) {
                res = false;
                break;
            }
        }
        return res;
    };

    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
        os = 'Android';
    }

    if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        os = 'iOS';
    }

    if (isPC()) {
        os = 'PC';
    }

    return os;
}

export function getURLParamValue(name: string): string | null {
    // const fragment = window.location.hash.substr(1);
    const fragment = window.location.search
    const paramPairs = fragment.split(/\?|&/);
    const params: Record<string, string> = {};

    for (const pair of paramPairs) {
        const [key, value] = pair.split('=');
        params[key] = decodeURIComponent(value);
    }

    return params[name] || null;
}

const handleDownload = (schema: string) => {
    const os = checkOs();

    if (os === 'Android') {
        //main?tab=1
        const url = `storygo:///${schema}`;
        const link = `https://storygo.onelink.me/dDXW?pid=storygo_wap&c=user&is_retargeting=true&af_dp=${encodeURIComponent(
            url
        )}`;
        console.log(link)
        window.location.href = link;
    } else if (os === 'iOS') {
        const url = `https://www.storygo.cc/storygo/${schema}`;
        const link = `https://storygo.onelink.me/dDXW?pid=storygo_wap&c=user&af_dp=${encodeURIComponent(
            url
        )}`;
        console.log(link)
        window.location.href = link;
    } else {
        window.location.href = 'https://www.storygo.cc';
    }
};



const getQueryByString = (
    str: string,
    name: string,
    sign: string = ';'
):string => {
    const reg = new RegExp(
        `(^|${sign})` + name + `=([^${sign}]*)(${sign}|$)`,
        'i'
    );
    const r = str.substr(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return '';
};

export const isApp = () => {
    if(getQueryByString(window.navigator.userAgent, 'appVerName')){
        return true
    }
    return false;
}


export const getUserInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '')
    return {

    }
}
const loadImg = (src: string) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(true);
        image.onerror = () => resolve(false);
        image.src = src;
    });



const hmEvent = (
    category: string,
    action: string,
    optLabel: string = '',
    optValue: string = ''
) => {
    try {
        window._hmt.push([
            '_trackEvent',
            category,
            action,
            optLabel,
            optValue
        ]);
    } catch (e) {
        // console.log('hmEvent error', e);
    }
};

const getRandom = (min: number, max: number) => {
    return Math.round(Math.random() * (max - min)) + min;
};

/**
 * 生成uuid的函数
 * @param {Number} len 生成uuid的长度
 * @param {Number} radix 生成uuid的格式
 * @return {String}
 */
const uuidP = (len: number = 8, radix: number = 0) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
        ''
    );
    const list = [];
    let i;

    radix = radix || chars.length;

    if (len) {
        for (i = 0; i < len; i++) {
            list[i] = chars[0 | (Math.random() * radix)];
        }
    } else {
        let r;
        list[8] = list[13] = list[18] = list[23] = '-';
        list[14] = '4';
        for (i = 0; i < 36; i++) {
            if (!list[i]) {
                r = 0 | (Math.random() * 16);
                list[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return list.join('');
};
/**
 * 判断是否为有效时间
 * @param {*} val
 * @return {Boolean}
 */
const isValidDate = (val: any) => val instanceof Date && !isNaN(+val);


/**
 * 时间格式化
 * @param {*} val
 * @param {String} fmt 自由组合(例：yyyy-MM-dd hh:mm:ss:SS)
 * @return {String}
 */
const dateFormat = (val: any, fmt = 'yyyy/MM/dd') => {
    const d = new Date(val);
    if (isValidDate(d)) {
        const o: any = {
            'M+': d.getMonth() + 1, // 月份
            'd+': d.getDate(), // 日
            'h+': d.getHours(), // 小时
            'm+': d.getMinutes(), // 分
            's+': d.getSeconds(), // 秒
            'q+': Math.floor((d.getMonth() + 3) / 3), // 季度
            S: d.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (d.getFullYear() + '').substr(4 - RegExp.$1.length)
            );
        }

        for (const k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1
                        ? o[k]
                        : ('00' + o[k]).substr(('' + o[k]).length)
                );
            }
        }
        return fmt;
    } else {
        return val;
    }
};

const timeFormat = (timestamp: any) => {
    if(!timestamp) return ''
    const mistiming = Math.round((Date.now() - timestamp) / 1000);
    const arrr = [
        i18next.t('year_ago'),
        i18next.t('month_ago'),
        i18next.t('week_ago'),
        i18next.t('day_ago'),
        i18next.t('hour_ago'),
        i18next.t('minute_ago'),
        i18next.t('just_now')
    ];
    const arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    for (let i = 0; i < arrn.length; i++) {
        const inm = Math.floor(mistiming / arrn[i]);
        if (inm !== 0) {
            return i === arrr.length - 1
                ? arrr[i]
                : Math.floor(mistiming / arrn[i]) + ' ' + arrr[i];
        }
    }
};


const codeMap = () => {
  return {
      1: i18next.t('errors.unknown_exception'),
      2: i18next.t('errors.validation_failed'),
      1010: i18next.t('errors.login_error'),
      3: i18next.t('errors.too_frequent'),
      4: i18next.t('errors.reauthorization'),
      5: i18next.t('errors.verification_failed'),
      1000: i18next.t('errors.phone_not_supported'),
      1001: i18next.t('errors.phone_too_failed'),
      1002: i18next.t('errors.sms_incorrect'),
      1003: i18next.t('errors.phone_not_available'),
      1007: i18next.t('errors.invalid_zroleid'),
      1008: i18next.t('errors.zroleid_exists'),
      5001:  i18next.t('errors.prohibited_words'),
      9000: i18next.t('errors.insufficient_balance'),
      1006: i18next.t('errors.account_deactivated'),
      7001: i18next.t('errors.already_friends'),
      7002: i18next.t('errors.friend_request'),
      4001: i18next.t('errors.task_incomplete'),
      1011: i18next.t('error_invalid_code'),
      1012: i18next.t('error_code_limit'),
      1013: i18next.t('error_had_activated'),
      4000: 'You have already claimed it',
      4002: 'Non redeemable',
      4003: 'Task has expired'
  }
};

// AES加密
const encrypt = (str: string) => {
    const key = CryptoJS.enc.Utf8.parse(lockKey);
    const iv = CryptoJS.enc.Utf8.parse(lockIv);
    const strP = CryptoJS.enc.Utf8.parse(str);
    const encrypted = CryptoJS.AES.encrypt(strP, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    const encryptedStr = encrypted.ciphertext.toString();
    return encryptedStr;
};

// AES解密
const decrypt = (str: string) => {
    const key = CryptoJS.enc.Utf8.parse(lockKey);
    const iv = CryptoJS.enc.Utf8.parse(lockIv);
    const encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    const strP = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypted = CryptoJS.AES.decrypt(strP, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
};

const getGuid = () => {
    const userInfo = getAppUserInfo()
    if(userInfo && 'guid' in userInfo){
        return userInfo.guid
    }else {
        let guid = window.localStorage.getItem('guid')
        if(guid) return guid
        guid = generateUUID()
        window.localStorage.setItem('guid', guid)
        return guid
    }
}

// 获取签名
const getSign = () => {

    const key = CryptoJS.enc.Utf8.parse(decrypt(keyStr()));
    const iv = CryptoJS.enc.Utf8.parse(uuidP(16));
    const strP = CryptoJS.enc.Utf8.parse(
        JSON.stringify({
            guid: getGuid(),
            nonce: uuidP(16),
            countryCode: window.appUserInfo?.countryCode || 'US',
            uid: window.appUserInfo ? window.appUserInfo.uid : 0
            // uid:  0
        })
    );
    const encrypted = CryptoJS.AES.encrypt(strP, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return iv.toString() + encrypted.ciphertext.toString();
};

const getAuth = (uid?: number): novel.UserId => {
    // utils userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    const userInfo = getAppUserInfo()
    const localUserInfo = JSON.parse(window.localStorage.getItem('userInfo') || `{"baseInfo": {}}`) as novel.UserInfo
    if(window.appUserInfo){
        const authInfo = {...window.appUserInfo}
        if(uid){
            authInfo.uid = uid
        }
        return {
            ...authInfo
        } as novel.UserId
    }
    if(!userInfo) {
        return {
            guid: getGuid(),
            userAgent: navigator.userAgent,
            tokenType: tokenType,
            testMode: false,
            token: window.localStorage.getItem('token') || '',
            // lcid: localUserInfo.baseInfo.lcid || 1033,
            lcid: window.localStorage.getItem('lcid') || localUserInfo.baseInfo.lcid || window.lcid || 1033,
            countryCode: localUserInfo.baseInfo.countryCode || window.countryCode,
            uid: uid || localUserInfo.baseInfo.uid || 0
        } as novel.UserId
    }

    return {
        ...userInfo
    } as novel.UserId
        // token: userInfo.token,
        // uid: userInfo.uid,
        // guid: getGuid(),
        // userAgent: userAgent,
        // tokenType: tokenType,
        // testMode: false,
        // countryCode: userInfo.countryCode,
        // lcid: userInfo.lcid


};

const getPcType = function (){
    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if(agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0){
        return 'window'
    }
    if(agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0){
        return 'window'
    }
    if(isMac){
        return 'mac'
    }
    return 'unknown'
}

export const getLang = (lcid: number) => {
    let lang = ''
    let countryCode = ''
    switch (lcid){
        case 4:
           lang = 'zh_CN'
            countryCode= 'CN'
           break
        case 1033:
            lang = 'en'
            countryCode= 'US'
            break
        case 1057:
            lang = 'id'
            countryCode= 'ID'
            break
        case 1049:
            lang = 'рус'
            countryCode= 'PY'
            break
        case 1055:
            lang = 'tr'
            countryCode= 'TR'
            break
        case 1031:
            lang = 'de'
            countryCode= 'DE'
            break
        case 1036:
            lang = 'fr'
            countryCode= 'FR'
            break
        case 1054:
            lang = 'th'
            countryCode= 'TH'
            break
        case 1124:
            lang = 'fil'
            countryCode= 'FI'
            break
        case 2070:
            lang = 'pt'
            countryCode= 'PT'
            break
        case 1034:
            lang = 'esp'
            countryCode= 'ES'
            break
        case 1066:
            lang = 'vi'
            countryCode= 'VI'
            break
        case 1025:
            lang = 'ar'
            countryCode= 'AR'
            break
        default:
            lang = getURLParamValue('lang') || 'en'
            countryCode= 'US'
    }
    window.lcid = lcid
    window.lang = lang
    window.countryCode = countryCode

    return lang
}

export const getBrowserLang = () => {
    let lang = ''
    let lcid = 0
    let countryCode = ''
    switch (navigator.language){
        // case 'zh-CN':
        // case 'zh':
        //     lang = 'zh_CN'
        //     // lang = 'esp'
        //     lcid = 4
        // countryCode = 'CN'
        //     break
        // case 'en-US':
        //     lang = 'en'
        // countryCode = 'US'
        // lcid = 1033
        //     // lang = 'esp'
        //     break
        case 'id-ID':
            lang = 'id'
            lcid = 1057
            countryCode = 'ID'
            break
        case 'ru-RU':
            lang = 'рус'
            lcid = 1049
            countryCode = 'PY'
            break
        case 'tr-TR':
            lang = 'tr'
            lcid = 1055
            countryCode = 'TR'
            break
        case 'de-DE':
            lang = 'de'
            lcid = 1031
            countryCode = 'DE'
            break
        case 'fr-FR':
            lang = 'fr'
            lcid = 1036
            countryCode = 'FR'
            break
        case 'th-TH':
            lang = 'th'
            lcid = 1054
            countryCode = 'TH'
            break
        case 'fil-PH':
            lang = 'fil'
            lcid = 1124
            countryCode = 'FI'
            break
        case 'pt-PT':
            lang = 'pt'
            lcid = 2070
            countryCode = 'PT'
            break
        case 'es-ES':
            lang = 'esp'
            lcid = 1034
            countryCode = 'ES'
            break
        case 'vi-VN':
            lang = 'vi'
            lcid = 1066
            break
        case 'ar-SA':
        case 'ar-XX':
            lang = 'ar'
            lcid = 1025
            countryCode = 'AR'
            break
        default:
            lang = getURLParamValue('lang') || 'esp'
            lcid = 1034
            countryCode = 'ES'
    }
    window.lang = lang
    window.lcid = lcid
    window.countryCode = countryCode
    return lang
}

export const getPackageName = (userAgent: string) => {
    // if(userAgent.indexOf('com.zrole.oasis') > -1){
    //     window.appType = 'oasis'
    //     return 'oasis'
    // }else if(userAgent.indexOf('com.zrolee.mangoo') > -1) {
    //     window.appType = 'mango'
    //     window.REACT_APP_REQUEST_URL = process.env.REACT_APP_OUTSIDE_REQUEST_URL
    //     return 'mango'
    // }else {
    //     if(window.location.host.indexOf('zrole.cn') > -1 || window.location.host.indexOf('zrole.com') > -1){
    //         window.appType = 'oasis'
    //         return 'oasis'
    //     }else if(window.location.host.indexOf('zrolee') > -1){
    //         window.appType = 'mango'
    //         window.REACT_APP_REQUEST_URL = process.env.REACT_APP_OUTSIDE_REQUEST_URL
    //         return 'mango'
    //     }
    //     window.appType = 'oasis'
    //     return 'oasis'
    // }
}

export const setAppUserInfo = function (userInfo: SdkResponse['getUserInfo']){
    window.localStorage.setItem('appUserInfo', JSON.stringify(userInfo));
}

export const getAppUserInfo = function ():SdkResponse['getUserInfo'] | null {
    if(window.appUserInfo) {
        return window.appUserInfo
    }
    const appUserInfoStr = window.localStorage.getItem('appUserInfo');
    if(appUserInfoStr){
        const appUserInfo: SdkResponse['getUserInfo'] = JSON.parse(appUserInfoStr)
        return appUserInfo || null
    }
    return null
}

export const dataLength = (fData: string) => {
    var intLength = 0
    for (var i = 0; i < fData.length; i++) {
        if ((fData.charCodeAt(i) < 0) || (fData.charCodeAt(i) > 255))
            intLength = intLength + 2
        else
            intLength = intLength + 1
    }
    return intLength
}

export function decryptAct(encryptedMessage: string, key: string) {
    let decryptedMessage = '';
    for (let i = 0; i < encryptedMessage.length; i++) {
        let ascii = encryptedMessage.charCodeAt(i);
        let keyAscii = key.charCodeAt(i % key.length);
        let decryptedAscii = ascii ^ keyAscii;
        decryptedMessage += String.fromCharCode(decryptedAscii);
    }
    return decryptedMessage;
}

export function getWeekRange(year: number, weekNumber: number) {
    // 创建表示指定年份和第一天的Date对象
    var firstDayOfYear = new Date(year, 0, 1);

    // 计算指定周数的起始日期
    var startOfWeek = new Date(firstDayOfYear);
    startOfWeek.setDate(firstDayOfYear.getDate() + (8 - firstDayOfYear.getDay()) + (7 * (weekNumber - 3)));

    // 计算指定周数的结束日期
    var endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return {
        start: startOfWeek,
        end: endOfWeek
    };
}
export const getAppVersion = (userAgent: string) => {
    const appVersionNameArr = userAgent.match(/appVerName\=.*?(\d+\.\d+\.\d+).*?;/)

    let versionStr = userAgent ? '1.0.0' : '9.9.9'
    if(appVersionNameArr && appVersionNameArr.length >= 2){
        versionStr = appVersionNameArr[1]
        if(versionStr === '0.0.1'){
            const version = localStorage.getItem('version')
            if(version) {
                window.mockVersion = true
                versionStr = version
            }else {
                versionStr = '9.9.9' // debug版本
            }
        }
    }
    window.appVersion = versionStr
    return versionStr
}

export const pushTrack = (args: Record<string, any>) =>{
    window.track.push({
        ...args,
        date: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
        url: window.location.href,
    })
}

export const splitArray = (arr: Array<any>, row: number, count = 2) => {
    const newArray =[]
    if(arr.length <= count){
        return [arr]
    }
    if(arr.length > count){
        return chunk(arr, Math.ceil(arr.length / row))
    }
}

export const importScript = (url: string) => {
    return new Promise((resolve, reject) => {
        const scriptEl = document.createElement('script')
        scriptEl.src = url
        scriptEl.onload = () => {
            resolve(null)
        }
        scriptEl.onerror = (e) => {
            reject(e)
        }
        document.head.append(scriptEl)
    })
}

export const parseParams = (str:string) => {

    // str.match(/([\s\S]+)_(\d+)$/)
    // return [RegExp.$1, RegExp.$2]
    return str.split('_')
}

export const getBookUrl = (book: novel.Book) => {
    return `https://www.storygo.cc/books/${removeBlankSpace(book.name)}_${book.bookId}`
}

export const removeBlankSpace = (str:string) => {
    if(!str){
        return  '-'
    }
    return str.replace(/\s/g, '-')
        .replace(/\&/g, '&amp;')
        .replace(/\'/g, '&apos;')
        .replace(/\"/g, '&quot;')
        .replace(/\>/g, '&gt;')
        .replace(/\</g, '&lt;')
        .replace(/\?/g, '&quest;')
        .replace(/#/g, '&num;')
}
const v1gtv2 = (v1:string, v2:string) => {
    console.log(v1, v2)
    // return false;
    if(v1 && v2){
        const v1Int = Number(v1.replace(/\./g, ''))
        const v2Int = Number(v2.replace(/\./g, ''))
        return v1Int >= v2Int
    }else {
        return true
    }

}
export const sleep = (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms)
    })
}
export const getCountdown = function (ms: number) {
    const currentDate = new Date().getTime();
    const timeDifference = ms - currentDate;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    if (timeDifference < 0) {
        return []
    }
    return [(0 + String(days)).slice(-2), (0 + String(hours)).slice(-2), (0 + String(minutes)).slice(-2), (0 + String(seconds)).slice(-2)]
}

export const fillZero = (val: number | string) => {
    return `0${val || 0}`.slice(-2)
}
export const toApp = function (schema: string) {
    const type = checkOs();
    if (type === 'Android') {
        window.location.href = `https://storygo.onelink.me/dDXW?pid=act&c=user&is_retargeting=true&af_dp=${encodeURIComponent(schema)}`
    } else {
        window.location.href = `https://storygo.onelink.me/dDXW?pid=act&c=user&is_retargeting=true&af_dp=${encodeURIComponent(schema)}`
    }
}

export const extendObj = (obj: Record<string, any>, target: Record<string, any>) => {
    const targetKeys = Object.keys(obj)
    for (let i = 0; i < targetKeys.length; i++) {
        const key = targetKeys[i]
        obj[key] = target[key]
    }
    return obj
}

export {
    sdkPromise,
    getSrc,
    getSrcDiy,
    checkOs,
    handleDownload,
    getQueryByString,
    loadImg,
    hmEvent,
    getRandom,
    uuidP,
    dateFormat,
    timeFormat,
    codeMap,
    getSign,
    getAuth,
    getGuid,
    tokenType,
    userAgent,
    getPcType,
    v1gtv2
}
