export default {
    "next": "Weiter",
    "login": "Anmelden",
    "login_tips": "Wir hoffen, dass Sie hier eine romantische Reise genießen können",
    "set_password": "Passwort festlegen",
    "enter_password": "Bitte geben Sie Ihr Passwort ein",
    "verify_code": "Verifizierungscode",
    "enter_code": "Bitte geben Sie den 6-stelligen E-Mail-Verifizierungscode ein",
    "let_start": "Lass uns anfangen",
    "let_start_tips": "Wir hoffen, dass Sie hier eine romantische Reise genießen können",
    "sign_facebook": "Mit FaceBook anmelden",
    "sign_google": "Mit Google anmelden",
    "sign_email": "Mit E-Mail anmelden",
    "language_title": "Lesespracheinstellungen festlegen",
    "current_lang": "aktuelle Sprache",
    "en": "EN",
    "indonesian": "Indonesisch",
    "personal_data": "Persönliche Daten",
    "save": "Speichern",
    "nickname": "Spitzname",
    "sign_out": "Abmelden",
    "loading": "Laden",
    "success": "Erfolgreich",
    "reading_record": "Leseverlauf",
    "empty": "Leer",
    "last_read": "Zuletzt gelesen",
    "chapter": "Kapitel",
    "remove": "Entfernen",
    "search": "Suche",
    "search_empty": "Keine mit \"{{var}}\" verwandten Romane können gefunden werden",
    "login_tips_2": "Melden Sie sich an, um mehr Funktionen zu nutzen.",
    "register_or_login": "Registrieren/Anmelden",
    "more": "Mehr",
    "reads": "Liest",
    "read": "Lesen",
    "last_chapter": "Letztes Kapitel",
    "all_chapter": "Alle Kapitel",
    "contents": "Inhalte",
    "all": "Alle",
    "completed": "Abgeschlossen",
    "ongoing": "Laufend",
    "most_popular": "Am beliebtesten",
    "new_arrival": "Neuankömmling",
    "invite_friends": "Teilen Sie diesen Einladungscode mit Ihren Freunden. Sobald sie ihn auf dieser Seite aktivieren, erhalten Sie beide {{coins}} Edelsteine. Jeder Einladungscode kann bis zu 50 Mal verwendet werden.",
    "invite_friends_nums": "<span class=\"blue\">Ich habe {{n}} Freunde eingeladen und </span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}} gemacht</span>",
    "guide_title": "StoryGo Autor Projekt",
    "guide_content": 0,
    "active_code": "Den Einladungscode aktivieren",
    "active_tips": "Holen Sie sich einen Einladungscode von Ihren Freunden, und nach erfolgreicher Aktivierung erhalten Sie {{coins}} Edelsteine. Tipp: Sie können Ihren eigenen Einladungscode nicht aktivieren; Sie müssen einen von anderen erhalten!",
    "login_now": "Jetzt anmelden",
    "task_1_title": "Willkommensgeschenk",
    "task_1_desc": "Ein Willkommensgeschenk für Neulinge, das automatisch in Ihre Brieftasche eingezahlt wurde",
    "view_detail": "Details anzeigen",
    "claim": "Beanspruchen",
    "invite_friend": "Freunde einladen",
    "my_invite_code": "Mein Einladungscode",
    "copy": "Kopieren",
    "task_2_title": "Täglich einchecken",
    "task_2_desc": "Checken Sie jeden Tag ein und erhalten Sie den Bonus.",
    "task_3_title": "Über {{min}} Minuten lesen",
    "task_3_desc": "Lesen Sie heute insgesamt {{min}} Minuten, um Belohnungen zu verdienen.",
    "task_page_label": "Belohnungen",
    "label_wallet_purchase_coins": "Münzen kaufen",
    "go": "Los",
    "done": "Erledigt",
    "copy_text": "",
    "copy_success": "Der Einladungscode wurde kopiert.",
    "active_success": "Aktivierung erfolgreich. Die 50-Münzen-Belohnung wurde an Ihre Brieftasche gesendet.",
    "error_code_limit": "Der Aktivierungscode hat sein Nutzungslimit erreicht.",
    "error_had_activated": "Dieses Konto wurde bereits mit einem Code aktiviert",
    "error_invalid_code": "Ungültiger Aktivierungscode",
    "download_tips": "Unbegrenzte Geschichten in der APP",
    "download": "Herunterladen",
    "label_book_praise": "Bewertung",
    "label_book_prologue": "Prolog",
    "login_success": "Anmeldung erfolgreich",
    "msg_reset_password_repeat": "Bitte wiederholen Sie das neue Passwort",
    "msg_reset_password_email": "Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
    "label_reset_password": "Passwort zurücksetzen",
    "label_send_email": "E-Mail senden",
    "msg_login_email_code_sent": "Der Bestätigungscode wurde erfolgreich an Ihre E-Mail gesendet",
    "msg_feedback_error": "Senden fehlgeschlagen, bitte versuchen Sie es später erneut",
    "save_userinfo": "Erfolgreich gespeichert",
    "label_load_image_fail": "Bildladung fehlgeschlagen",
    "paid_chapters": "Bezahlte Kapitel",
    "unlock_price": "Das Freischalten des Kapitels erfordert {{coin}}.",
    "download_claim_coin": "Laden Sie die App herunter, um {{coin}} zu beanspruchen und spannendere Kapitel freizuschalten.",
    "label_book_chapters": "{{num}} Kapitel",
    "label_cancel": "Abbrechen",
    "msg_common_state_empty": "Leer hier",
    "clear_reading_record": "Sind Sie sicher, dass Sie den Leseverlauf löschen möchten?",
    "clear_successful": "Aufzeichnung erfolgreich gelöscht.",
    "label_search_trending": "Trendsuchen",
    "task_login_tips": "Anmelden, um einen Münzbonus zu erhalten",
    "label_new_arrival": "Heiße Neuerscheinungen",
    "had_activated": "Dieses Konto wurde bereits mit einem Code aktiviert",
    "activate": "Aktivieren",
    "task_5_title": "Auf Facebook teilen",
    "task_5_desc": "Teile jedes Buch auf der Facebook-Plattform",
    "bigwheel_redeem_label": "Aufladen",
    "coin_tips_1": "Nicht genügend Münzen, bitte aufladen.",
    "coin_tips_2": "Konto: {{n}} Münzen (benötigt {{m}} Münzen)",
    "bigwheel_rule_1": "1. Nehmen Sie an der Glücksrad-Ziehung teil mit einer garantierten 100%igen Gewinnchance.",
    "bigwheel_rule_2": "2. Ein einzelner Raddreh kostet 10 Goldmünzen, zehn Drehungen kosten 100 Goldmünzen und fünfzig Drehungen kosten 500 Goldmünzen. Je mehr aufeinander folgende Drehungen, desto höher die Chance auf wertvolle Preise!",
    "bigwheel_rule_3": "3. Während des Spiels erhaltenen Preise werden automatisch verteilt. Goldmünzen werden direkt auf Ihr Goldmünzen-Konto gutgeschrieben.",
    "bigwheel_rule_4": "4. Dieses Spiel steht nicht in Verbindung mit Apple Inc. Alle Rechte zur endgültigen Interpretation liegen bei StoryGo.",
    "bigwheel_balance_label": "Guthaben",
    "no_data_1": "Keine Daten",
    "msg_room_coin_notice": "Herzlichen Glückwunsch! [{{who}}] gewinnt {{coin}} Münzen!",
    "top_up": "Aufladen",
    "sign": "Anmelden",
    "sign_desc": "Ein Willkommensgeschenk für Neulinge, das automatisch in Ihre Brieftasche eingezahlt wurde",
    "first_recharge": "Begrenztes Zeitangebot",
    "first_recharge_desc": "Beenden Sie das begrenzte Zeitangebot, um einen Bonus zu erhalten",
    "unlock_chapter": "{{n}} Kapitel freischalten",
    "unlock_chapter_desc": "Entsperren Sie ein Premium-Kapitel, um eine Belohnung zu erhalten.",
    "comment_book": "Einen Kommentar zu einem Buch senden",
    "comment_book_desc": "Schreibe eine Rezension für dein Lieblingsbuch und habe die Chance, Belohnungen zu gewinnen!",
    "my_coins": "Meine Münzen",
    "recharge_title": "Empfohlenes Geschenkpaket",
    "bonus": "Bonus",
    "buy_coins": "Münzen kaufen",
    "my_balance": "Mein Guthaben",
    "apple_pay": "Apple Pay",
    "other_pay": "Top-Wahl",
    "google_pay": "Google Pay",
    "pay_now": "Jetzt bezahlen",
    "label_balance": "Münzen",
    "select_payment": "Bitte wählen Sie eine Zahlungsmethode aus",
    "select_package": "Bitte wählen Sie ein Aufladepaket aus",
    "wallet_title": "Geldbörse",
    "coins_balance": "Guthaben",
    "new_comer": "Neuling",
    "extra_coins": "Extra {{n}} Münzen",
    "daily_task_label": "Tägliche Aufgabe",
    "label_pay_success": "Zahlung erfolgreich!",
    "app_update_title": "Bitte aktualisieren Sie auf die neueste Version.",
    "restore_label": "Wiederherstellen",
    "history_label": "Verlauf",
    "newcomer_pack": "Begrenztes Zeitangebot",
    "recommend_pack": "Empfohlenes Paket",
    "collection_month": "Insgesamt/Monat",
    "now_get_icons": "Holen Sie sich {{n}}  auf einmal",
    "monthly_get_icons": "Holen Sie sich {{n}} Edelsteine/Monat",
    "recharge_feedback_msg": "Haben Sie Probleme beim Aufladen?",
    "feedback_label": "Feedback",
    "coin_label": "Münzen",
    "gem_label": "Edelsteine",
    "restore_tips": "Keine wiederherstellbaren Abonnements",
    "recharge_label": "Aufladen",
    "sign_days": "Eingecheckt {{n}} Tag(e)",
    "vip_daily_task": "Monatspaketbonus",
    "other": "Andere",
    "limited_recharge_pack": "Begrenztes Zeitangebot",
    "expire_tips": "Läuft ab am {{time}}",
    "hot_pack": "Beliebtes Paket",
    "continuous_checkin_day": "Eingecheckt {{n}} Tag(e)",
    "svip_login_reward": "$9.9 Premium-Bonus",
    "vip_login_reward": "$19.9 Premium-Bonus",
    "dailysign_popup_btn": "Holen Sie mehr aus dem Rewards Center",
    "checked_in": "Überprüft",
    "limit_time_recharge_title": "Begrenztes Zeitangebot",
    "recharge_pack_tips": "Beenden Sie das begrenzte Zeitangebot, um einen Bonus zu erhalten",
    "balance_nsufficient_tips": "Ihr Guthaben ist unzureichend, bitte laden Sie auf",
    "had_coins_tips": "Sie haben: {{n}} Münzen | {{m}} Edelsteine",
    "dailysign_popup_title": "Glückwünsche",
    "pay_method_label": "Zahlungsmethode",
    "at_once": "Auf einmal",
    "daily_login": "Tägliche Anmeldung",
    "card_coin_suffix": "Insgesamt/Monat",
    "card_gems_suffix": "Monat",
    "vips_rule_title": "Über das Monatspaket:",
    "vips_rule_1": "1.Bei erfolgreichem Kauf des 9,9-Dollar-Monatspakets erhalten Sie sofort 1.000 Goldmünzen, und Sie können 30 aufeinanderfolgende Tage lang täglich 20 Edelsteine ​​(insgesamt 600 Edelsteine) erhalten. Bei erfolgreichem Kauf des 19,9-Dollar-Monatspakets erhalten Sie sofort 2.000 Goldmünzen, und Sie können 30 aufeinanderfolgende Tage lang täglich 40 Edelsteine ​​(insgesamt 600 Edelsteine) erhalten.",
    "vips_rule_2": "2.Wenn Sie verschiedene Monatspakete kaufen, werden die Edelsteingewinne, die Sie täglich erhalten, addiert. Wenn Sie dieselben Monatspakete kaufen, werden die Edelsteingewinne, die Sie täglich erhalten, nicht addiert, sondern verlängern nur die Laufzeit der entsprechenden Monatskarte.",
    "vips_rule_3": "3.Die täglich erhaltenen Edelsteine ​​sind 30 Tage lang gültig, bitte beachten Sie dies.",
    "vips_rule_4": "",
    "vips_rule_5": "Das Monatspaket ist innerhalb von 24 Stunden nach dem Kauf gültig und Sie können die entsprechenden Vorteile genießen.",
    "check_in": "Einchecken",
    "day_index": "Nr.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Edelsteine ​​</span> laufen bald ab",
    "balance_label": "Balance",
    "wallet_rule_title": "Über Edelsteine",
    "wallet_rule_1": "Edelsteine ​​können verwendet werden, um Kapitel freizuschalten. Beim Freischalten von Kapiteln haben Münzen und Edelsteine ​​den gleichen Wert, 1 Münze = 1 Edelstein.",
    "wallet_rule_2": "Edelsteine ​​werden ablaufen. Bitte beachten Sie, dass Edelsteine, die aus Aufladen und Monatspaket erhalten wurden, 30 Tage lang gültig sind und Edelsteine, die durch tägliche Aufgaben beansprucht wurden, 7 Tage lang gültig sind.",
    "wallet_rule_3": "Beim Freischalten von Kapiteln werden zuerst Edelsteine ​​verwendet.",
    "read_task_title": "Leseaufgabe",
    "share_task_title": "Teilaufgaben",
    "comment_task_title": "Kommentaraufgabe",
    "vip_task_title": "$9.99 Premium-Bonus",
    "svip_task_title": "$19.99 Premium-Bonus",
    "vip_page_title": "Monatspaket",
    "pay_bonus_label": "Bonus",
    "daily_gems": "Täglich {{n}} Edelsteine",
    "history_page_title": "Geschichte",
    "recharge_title_1": "Aufladen",
    "gems_will_expire_btn": "Details",
    "gems_history_tips": "Nach Ablaufdatum sortieren",
    "unlock_coins": "Benötigt: {{n}} Münzen oder Edelsteine",
    "history_recharge_title": "Aufladen",
    "history_game_title": "Glücksziehung",
    "history_recharge_given_title": "Aufladebonus",
    "extar_gems_btn": "Zusätzliche Edelsteine",
    "watch_ad_task_title": "Videos ansehen",
    "watch_ad_task_desc": "Belohnung für jedes angesehene Video erhalten ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Belohnung erfolgreich beansprucht.",
    "task_finish_title": "Die Aufgabe wurde erledigt.",
    "draw_width_ad_toast": "Herzlichen Glückwunsch! Du hast  {{n}} und  {{m}} zusätzlich erhalten.",
    "draw_toast": "Herzlichen Glückwunsch! Du hast  {{n}} erhalten.",
    "draw_dialog_btn1": "Fordern Sie einfach  {{n}} an.",
    "draw_dialog_btn2": "Fordern Sie  {{n}} und  {{m}} zusätzlich an.",
    "history_ads_given_title": "Videos ansehen",
    "ad_extra_rewards_title": "Bonus nach Aufgaben.",
    "label_editor_picks": "Redaktionstipps",
    "label_genre": "Genre",
    "label_main_tab_home": "Entdecken",
    "label_reader_choice": "Top-Bewertet",
    "invite_bonuses": "Laden Sie einen neuen Benutzer ein und erhalten Sie bis zu {{n}} Boni.",
    "invite_friend_btn": "Lade deinen Freund ein",
    "how_get_bonuses": "Wie man mehr Boni bekommt",
    "invite_tips_1": "Dein Freund hat 5 Kapitel freigeschaltet und gelesen",
    "invite_tips_2": "Wenn Ihre Freunde aufladen, erhalten Sie 10% in Edelsteinen zurück, bis zu 150 Edelsteinen pro Freund.",
    "invite_tips_3": "Jedes Mal, wenn dein Freund eine Aktion abschließt, erhältst du eine sofortige Belohnung.",
    "how_invite_friend": "Wie lade ich einen Freund ein",
    "invite_tips_5": "Klicken Sie auf die Schaltfläche unten, um Ihren einzigartigen Einladungslink zu generieren und ihn mit Freunden zu teilen",
    "invite_tips_4": "Dein Freund hat StoryGo über den von dir geteilten Link heruntergeladen",
    "reward_history_title": "Belohnungshistorie",
    "reward_history_tips_1": "Du hast insgesamt {{n}} Edelsteine aus der Einladungsaktivität erhalten.",
    "reward_history_tips_2": "Du hast erfolgreich {{n}} Freunde und {{m}} Edelsteine eingeladen.",
    "reward_history_tips_3": "Du hast {{n}} Edelsteine von der Aufgabe erhalten, dass deine eingeladenen Freunde 5 Kapitel freischalten.",
    "reward_history_tips_4": "Du hast {{n}} Edelsteine als Belohnung für die Aufladung deiner Freunde erhalten.",
    "daily_invite_friend_title": "Freunde einladen",
    "daily_invite_friend_desc": "Lade deine Freunde ein, sich StoryGo anzuschließen und große Belohnungen zu verdienen.",
    "recharge_panel_ad_tips": " Laden Sie Ihr StoryGo-Paket auf, um reichlich Buchmünzen und Edelsteine zu erhalten. Entsperrung von Premium-Kapiteln und genießen Sie werbefreies Lesen für 7 bis 30 Tage nach dem Kauf, mit sofortiger Aktivierung.",
    "invite_more_friend": "Lade mehr Freunde ein und erhalte mehr Edelsteine!",
    "history_invite_unlock_title": "Der eingeladene Benutzer hat 5 Kapitel freigeschaltet",
    "history_invite_recharge_title": "Der eingeladene Benutzer hat eine Aufladung vorgenommen",
    "days": "Tage",
    "continue_read_app": "Weiterlesen in der App",
    "download_app_tips": "Für mehr aufregende Inhalte, laden Sie StoryGo herunter",
    "invite_tips_6": "Lade einen neuen Leser ein, um 15 Edelsteine zu beanspruchen. Sie können bis zu 300 Edelsteine als Belohnung verdienen.",
    "history_invite_success_title": "Erfolgreich einen Benutzer eingeladen",
    "evaluation_task_title": "Bewerten Sie StoryGo",
    "evaluation_task_desc": "3 Schritte, um die Belohnungen zu erhalten",
    "invite_page_title": "Laden Sie neue Benutzer ein, es gemeinsam zu verwenden und Edelsteinbelohnungen zu erhalten!",
    "invite_page_title_2": "Für jeden neuen Benutzer, den Sie einladen, erhalten Sie.",
    "invite_page_title_3": "За платежи новых пользователей, вы также можете получить награду в {{n}} драгоценных камней. Чем больше приглашаете, тем больше получаете.",
    "my_invite_record": "Meine Einladungsliste",
    "successfully_invited": "Erfolgreiche Einladung",
    "earnings_gem": "Meine Einnahmen (Edelsteine)",
    "how_invite_title_1": "Freunde einladen",
    "how_invite_title_2": "Freund schließt die Registrierung ab",
    "how_invite_title_3": "Belohnungen kommen an",
    "invite_h5_title": "Wir laden Sie ein, StoryGo gemeinsam zu nutzen",
    "got_gem_label": "Vorteile für Anfänger",
    "accept_invitation": " Einladung akzeptieren",
    "join_people": "Menschen haben an der Veranstaltung teilgenommen",
    "recommended_content": "Empfohlener Inhalt",
    "to_app_tip": "Mehr aufregende Inhalte auf StoryGo",
    "turn_on_notify_title": "Benachrichtigungen öffnen",
    "turn_on_notify_desc": "Öffnen Sie die Nachrichtenbenachrichtigungen, um Belohnungen zu erhalten",
    "label_help_center": "Hilfecenter"
}