export const DEFAULT_LANG = 'ES'
export const DEFAULT_LCID = 1034
export const LCID_MAP_LANG = {
    4: 'CN',
    1033: 'EN',
    1057: 'ID',
    1049: 'PYC',
    1055: 'TR',
    1031: 'DE',
    1036: 'FR',
    1054: 'TH',
    1124: 'FI',
    2070: 'PT',
    1034: 'ESP',
    1066: 'VI',
    1025: 'AR',
}

export const LANG_MAP_LCID = {
    CN: 4,
    EN: 1033,
    ID: 1057,
    PYC: 1049,
    TR: 1055,
    DE: 1031,
    FR: 1036,
    TH: 1054,
    FIL: 1124,
    PT: 2070,
    ESP: 1034,
    VI: 1066,
    AR: 1025,
}
export const LOWER_CASE_LANG_MAP_LCID = {
    cn: 4,
    en: 1033,
    id: 1057,
    рус: 1049,
    tr: 1055,
    de: 1031,
    fr: 1036,
    th: 1054,
    fil: 1124,
    pt: 2070,
    es: 1034,
    vi: 1066,
    ar: 1025,
}